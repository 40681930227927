import { createApi } from '@reduxjs/toolkit/query/react';
import { TAuthResponse } from '@/types/auth-types';

import { baseQueryWithReauth } from './query';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMe: builder.query<TAuthResponse, void>({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetMeQuery } = userApi;
