import styled, { css } from 'styled-components';
import { Colors } from '@/environment';
import { Input } from 'antd';

export const AuthPasswordStyled = styled(Input.Password).attrs(
  (props: { isError: boolean; isVisible: boolean }) => ({
    ...props,
  }),
)`
  &.auth-password {
    border-color: transparent;
    ${({ isError }) =>
      isError &&
      css`
        border-color: ${Colors.error} !important;
      `}

    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.black};
    font-family: 'Inter', sans-serif;
    padding: 8px 16px;
    background: ${Colors.authInputBg};
    border-radius: 4px;

    &::placeholder {
      color: ${Colors.grey[100]};
    }

    &:hover {
      border-color: transparent;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${Colors.authInputBg} inset !important;
      box-shadow: 0 0 0 50px ${Colors.authInputBg} inset;
      background-color: ${Colors.authInputBg};
    }

    & .ant-input {
      padding-top: 2px;
      letter-spacing: ${({ isVisible }) => (!isVisible ? '6px' : 'normal')};
      background-color: ${Colors.authInputBg} !important;
      background: ${Colors.authInputBg} !important;
      box-shadow: none !important;

      &::placeholder {
        letter-spacing: normal;
        color: ${Colors.grey[100]};
      }
    }

    & .ant-input-suffix {
      cursor: pointer;
    }
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid ${Colors.authInputFocus};

    &:hover {
      border-color: ${Colors.authInputFocus};
    }
  }

  &.auth-password:disabled {
    background: ${Colors.input.disabled};
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none !important;
  }
`;
