import { EUserRole, IconType, MAX_COUNT_INVITED_USERS } from '@/consts';
import { Button, Icon } from '@/components/common';
import {
  PoweredByBlock,
  SidebarFootBlock,
  SidebarTopBlock,
  SidebarWrapper,
} from './Sidebar.styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux';
import { Navigation } from '../Navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoutes } from '@/routes';
import { Colors } from '@/environment';
import { useCallback } from 'react';
import { setViewAsPublisherId } from '@/redux/slice/user/slice';
import { Profile } from './components/Profile';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';

export const Sidebar = () => {
  const { user, users, viewAsPublisherId } = useSelector(
    (store: RootState) => ({
      users: store.users.data,
      user: store.user.data,
      viewAsPublisherId: store.user.viewAsPublisherId,
    }),
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUsersPage = pathname.includes(`${ERoutes.ManageUsers}`);
  const isInviteUserPage = pathname.includes(`${ERoutes.InviteUser}`);
  const isCreatePlayerPage = pathname.includes(`${ERoutes.CreatePlayer}`);
  const isPublisherPage = pathname.includes(ERoutes.ManagePublishers);
  const isEditPlayerPage = pathname.includes('/players/edit/');

  const navigateToPage = useCallback(
    (route: string) => {
      return () => {
        navigate(route);
      };
    },
    [navigate],
  );

  const setView = useCallback(() => {
    dispatch(setViewAsPublisherId(''));
    navigate(ERoutes.Root);
  }, [dispatch, navigate]);

  const isInvitedMaxUsers =
    users.filter((user) => user.active).length >= MAX_COUNT_INVITED_USERS;

  return (
    <SidebarWrapper>
      <SidebarTopBlock>
        {user?.isRoot && checkLengthIsPositive(viewAsPublisherId) && (
          <Button
            text={'Back to owner view'}
            position="center"
            onClick={setView}
            borderRadius="12px"
            backgroundColor={Colors.blue}
            textColor={Colors.white}
            border={`2px solid ${Colors.blue}`}
          />
        )}

        {user?.isRoot &&
          pathname === '/players' &&
          viewAsPublisherId.length === 0 && (
            <Button
              text={'Add new player'}
              icon={<Icon name={IconType.TYPlusIcon} />}
              onClick={navigateToPage(ERoutes.CreatePlayer)}
              borderRadius="12px"
              backgroundColor={Colors.white}
              textColor={Colors.blue}
              border={`2px solid ${Colors.blue}`}
              backgroundColorHover={Colors.authBlockBg}
            />
          )}
        {user?.role !== EUserRole.Viewer &&
          viewAsPublisherId.length === 0 &&
          !isCreatePlayerPage &&
          !isEditPlayerPage && (
            <>
              {!isUsersPage && !isPublisherPage && !user?.isRoot && (
                <Button
                  text={'Add new player'}
                  icon={<Icon name={IconType.TYPlusIcon} />}
                  onClick={navigateToPage(ERoutes.CreatePlayer)}
                  borderRadius="12px"
                  backgroundColor={Colors.white}
                  textColor={Colors.blue}
                  border={`2px solid ${Colors.blue}`}
                  backgroundColorHover={Colors.authBlockBg}
                />
              )}
              {isUsersPage && !isInviteUserPage && !isPublisherPage && (
                <Button
                  text={'Invite a new user'}
                  icon={<Icon name={IconType.TYPlusIcon} color={Colors.blue} />}
                  onClick={navigateToPage(ERoutes.InviteUser)}
                  borderRadius="12px"
                  backgroundColor={Colors.white}
                  textColor={Colors.blue}
                  border={`2px solid ${Colors.blue}`}
                  disabled={isInvitedMaxUsers}
                  backgroundColorHover={
                    !isInvitedMaxUsers ? Colors.authBlockBg : Colors.white
                  }
                />
              )}
              {user?.isRoot && pathname === '/publishers' && (
                <Button
                  text={'Invite a publisher'}
                  icon={<Icon name={IconType.TYPlusIcon} color={Colors.blue} />}
                  onClick={navigateToPage(ERoutes.InvitePublisher)}
                  borderRadius="12px"
                  backgroundColor={Colors.white}
                  textColor={Colors.blue}
                  border={`2px solid ${Colors.blue}`}
                  backgroundColorHover={Colors.authBlockBg}
                />
              )}
            </>
          )}
        <nav>
          <Navigation />
        </nav>
      </SidebarTopBlock>
      <SidebarFootBlock>
        <Profile />
        <PoweredByBlock>
          <span>Powered by:</span>{' '}
          <a href="http://tylr.com" target="_blank" rel="noopener noreferrer">
            TYLR
          </a>
        </PoweredByBlock>
      </SidebarFootBlock>
    </SidebarWrapper>
  );
};
