import { LoaderSpin } from '@/components/common/LoaderSpin';
import { EUserRole } from '@/consts';
import { Colors } from '@/environment';
import { RootState } from '@/redux';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, PathRouteProps } from 'react-router-dom';
import { LoadingWrapper } from './PrivateRoute.styled';

interface PrivateRouteProps extends PathRouteProps {
  children: ReactNode;
  roles: Array<EUserRole>;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  roles,
}) => {
  const { user } = useSelector((store: RootState) => ({
    user: store.user.data,
  }));

  const role = user && !user.isRoot ? user.role : EUserRole.Owner;

  const userHasRequiredRole = user && roles.includes(role);

  if (!user) {
    return (
      <LoadingWrapper>
        <LoaderSpin size={50} color={Colors.blue} thickness={0.4} />
      </LoadingWrapper>
    );
  }

  if (userHasRequiredRole) {
    return <>{children}</>;
  }

  return <Navigate to="/" />;
};
