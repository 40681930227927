import { Colors } from '@/environment';
import styled from 'styled-components';

export const HeaderStyled = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.blue};
  margin-bottom: 10px;
`;

export const DataStyled = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.dark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.role {
    text-transform: capitalize;
  }
`;
