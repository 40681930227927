import { Colors } from '@/environment/Colors';
import styled from 'styled-components';

export const SetupsHeader = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.dark};
  padding-bottom: 8px;
  margin-bottom: 35px;
  border-bottom: 1px solid ${Colors.grey[100]};
`;
