import { LoaderSpin } from '@/components/common';
import { Colors } from '@/environment';
import { memo } from 'react';
import { Wrapper } from './LoadPage.styled';

export const LoadPage = memo(() => {
  return (
    <Wrapper>
      <LoaderSpin
        position="absolute"
        size={50}
        color={Colors.blue}
        thickness={0.4}
      />
    </Wrapper>
  );
});

LoadPage.displayName = 'LoadPage';
