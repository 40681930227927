import * as Yup from 'yup';

interface IRecoverPasswordForm {
  password: string;
  reEnterPassword: string;
}

const recoverPasswordFormShema: Yup.SchemaOf<IRecoverPasswordForm> = Yup.object(
  {
    password: Yup.string()
      .required('Password is required field')
      .min(
        8,
        'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
      )
      .oneOf(
        [Yup.ref('reEnterPassword'), null],
        'Passwords entered do not match.',
      ),
    reEnterPassword: Yup.string()
      .required('Re-enter password is required field')
      .oneOf([Yup.ref('password'), null], 'Passwords entered do not match.'),
  },
);

export default recoverPasswordFormShema;
