import { Colors } from '@/environment';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { memo } from 'react';
import {
  BreadcrumbDivider,
  BreadcrumbItem,
  BreadcrumbItemWrapper,
  BreadcrumbsWrapper,
} from './Breadcrums.styled';

export const Breadcrumbs = memo(() => {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <BreadcrumbsWrapper>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbItemWrapper key={`breadcrumbs-item-${item.label}`}>
          {index !== 0 && <BreadcrumbDivider>/</BreadcrumbDivider>}
          <BreadcrumbItem
            color={
              index === breadcrumbs.length - 1 ? Colors.blue : Colors.text.main
            }
          >
            {item.label}
          </BreadcrumbItem>
        </BreadcrumbItemWrapper>
      ))}
    </BreadcrumbsWrapper>
  );
});

Breadcrumbs.displayName = 'Breadcrumbs';
