import { Colors } from '@/environment';
import React, { useEffect, useState } from 'react';
import { LoaderSpin } from '../LoaderSpin';

interface IDelayedProps {
  children: React.ReactElement;
  waitBeforeShow?: number;
}

export const DelayedRender: React.FC<IDelayedProps> = ({
  children,
  waitBeforeShow = 500,
}): React.ReactElement | null => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);

    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return (
    <>
      {isShown ? (
        children
      ) : (
        <LoaderSpin
          position="absolute"
          size={50}
          color={Colors.blue}
          thickness={0.4}
        />
      )}
    </>
  );
};
