import { IPlayerData, TPlayerCreateFields } from '@/interfaces';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IPlayer,
  IPlayerArticlesParams,
  IPlayersStatistic,
  IPlayerStatsItem,
  IPlayerStatsParams,
} from '@/types/players-types';

import { baseQueryWithReauth } from './query';

interface ICreatePlayerData {
  data: TPlayerCreateFields;
}

interface IUpdatePlayerData extends ICreatePlayerData {
  playerId: string;
}

export const playersApi = createApi({
  reducerPath: 'playersApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPlayers: builder.query<
      IPlayer[],
      Partial<{
        limit: number;
        offset: number;
        orderBy: string;
        orderAsc: boolean;
        search: string;
        isOwner?: boolean;
        publisherId?: string;
        startTime?: string;
        endTime?: string;
        status?: string;
        type?: string;
      }>
    >({
      query: ({
        limit = 20,
        offset = 0,
        orderBy = 'status',
        orderAsc = false,
        search = '',
        isOwner,
        publisherId,
        startTime,
        endTime,
        status,
        type,
      }) => ({
        url: !isOwner
          ? `players?limit=${limit}&offset=${offset}&orderBy=${orderBy}&orderAsc=${orderAsc}&q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }${status ? `&status=${status}` : ''}${type ? `&type=${type}` : ''}`
          : `admin/publishers/${publisherId}/players?limit=${limit}&offset=${offset}&orderBy=${orderBy}&orderAsc=${orderAsc}&q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }${status ? `&status=${status}` : ''}${
              type ? `&type=${type}` : ''
            }`,
        method: 'GET',
      }),
    }),
    getPlayer: builder.query<
      IPlayerData,
      Partial<{ playerId: string; publisherId: string; isOwner?: boolean }>
    >({
      query: ({ playerId, publisherId, isOwner }) => ({
        url: !isOwner
          ? `players/${playerId}`
          : `admin/publishers/${publisherId}/players/${playerId}`,
        method: 'GET',
      }),
    }),
    createPlayer: builder.mutation<
      IPlayerData,
      Partial<{ data: TPlayerCreateFields }>
    >({
      query: ({ data }: ICreatePlayerData) => ({
        url: `players`,
        method: 'POST',
        body: data,
      }),
    }),
    updatePlayer: builder.mutation<
      IPlayerData,
      Partial<{
        data: Partial<TPlayerCreateFields>;
        publisherId: string;
        playerId: string;
      }>
    >({
      query: ({ data, playerId }: IUpdatePlayerData) => ({
        url: `players/${playerId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deletePlayer: builder.mutation<
      unknown,
      Partial<{
        publisherId: string;
        playerId: string;
      }>
    >({
      query: ({ playerId }: IUpdatePlayerData) => ({
        url: `players/${playerId}`,
        method: 'DELETE',
      }),
    }),
    getPlayerArticles: builder.query<
      IPlayerStatsItem[],
      Partial<IPlayerArticlesParams>
    >({
      query: ({
        playerId,
        publisherId,
        offset = 0,
        limit = 20,
        orderBy = 'createdAt',
        orderAsc = false,
        search = '',
        isOwner,
        startTime,
        endTime,
      }) => ({
        url: !isOwner
          ? `articles?playerId=${playerId}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&orderAsc=${orderAsc}&q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }`
          : `admin/publishers/${publisherId}/articles?playerId=${playerId}&limit=${limit}&offset=${offset}&orderBy=${orderBy}&orderAsc=${orderAsc}&q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }`,
        method: 'GET',
      }),
    }),
    getPlayerStats: builder.query<
      IPlayersStatistic,
      Partial<IPlayerStatsParams>
    >({
      query: ({
        playerId,
        isOwner,
        publisherId,
        search = '',
        startTime,
        endTime,
      }) => ({
        url: !isOwner
          ? `articles/stats?playerId=${playerId}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }`
          : `admin/publishers/${publisherId}/articles/stats?playerId=${playerId}&q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }`,
        method: 'GET',
      }),
    }),
    // TODO MAKE CLEANER
    getAllPlayerStats: builder.query<
      IPlayersStatistic,
      Partial<{
        publisherId: string;
        isOwner?: boolean;
        search: string;
        startTime?: string;
        endTime?: string;
        status?: string;
        type?: string;
      }>
    >({
      query: ({
        publisherId,
        isOwner,
        search = '',
        startTime,
        endTime,
        status,
        type,
      }) => ({
        url: !isOwner
          ? `players/stats?q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }${status ? `&status=${status}` : ''}${type ? `&type=${type}` : ''}`
          : `admin/publishers/${publisherId}/players/stats?q=${search}${
              startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
            }${status ? `&status=${status}` : ''}${
              type ? `&type=${type}` : ''
            }`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  // *Get array of players
  useLazyGetPlayersQuery,

  // *Get one player
  useLazyGetPlayerQuery,

  // *Get player stats
  useLazyGetPlayerStatsQuery,

  // *Get player articles
  useLazyGetPlayerArticlesQuery,

  // *Get all players stats
  useLazyGetAllPlayerStatsQuery,

  useCreatePlayerMutation,
  useUpdatePlayerMutation,
  useDeletePlayerMutation,
} = playersApi;
