import styled from 'styled-components';
import { Form } from 'antd';
import { Colors } from '@/environment';

export const BilingFormBaseLayout = styled(Form)`
  & .ant-form-item {
    margin-bottom: 12px;
  }
`;

export const DelimiterStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.scrollbar};
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const ReadableList = styled.div`
  display: grid;
  row-gap: 20px;
  padding: 40px 40px 0 40px;
  overflow-y: auto;
  width: 200px;

  scrollbar-width: auto;
  scrollbar-color: rgba(161, 161, 170, 0.1) transparent;

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }
`;

export const ReadableTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${Colors.blue};
  margin-bottom: 10px;
`;

export const ReadableText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.dark};
`;
