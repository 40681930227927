import styled from 'styled-components';
import { Colors } from '@/environment';

export const BillingLayoutWrapper = styled.div`
  padding: 40px;
  padding-bottom: 0px;
  border-radius: 24px;
  background: ${Colors.newContentBg};
  width: 100%;
`;

export const AccountInfoStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const AccountNameStyled = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.dark};
`;
export const AccountRoleStyled = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.grey[100]};
`;

export const PageHeaderStyled = styled.div`
  font-family: 'Golos Text', 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.text.bold};
`;

export const PageHeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
