import styled from 'styled-components';

export const ConfirmFormWrapper = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StatusMessageStyled = styled.div.attrs(
  (props: { color: string; cursor?: string }) => ({
    ...props,
  }),
)`
  color: ${({ color }) => color};
  font-family: 'Golos Text', 'Inter', sans-serif;
  font-size: 10px;
  text-align: center;
  cursor: ${({ cursor }) => (cursor ? cursor : 'auto')};
  text-decoration: underline;
`;
