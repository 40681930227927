import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IReportResponse,
  IReportOwnerResponse,
  IGetPublisherReports,
  IGetOwnerReports,
  IGetFileReports,
  IGetOwnerFileReports,
} from '@/types/monetization-types';
import { baseQueryWithReauth } from './query';

export const monetizationApi = createApi({
  reducerPath: 'monetizationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getReports: builder.query<IReportResponse, IGetPublisherReports>({
      query: ({
        limit,
        page,
        orderBy,
        orderAsc,
        q,
        startTime,
        endTime,
        isAdAllowed,
        playerType,
        publisherId,
      }) => ({
        url: 'monetization-reports',
        params: {
          publisherId,
          limit,
          page,
          orderBy,
          orderAsc,
          q,
          startTime,
          endTime,
          isAdAllowed,
          playerType,
        },
        method: 'GET',
      }),
    }),

    getOwnerReports: builder.query<IReportOwnerResponse, IGetOwnerReports>({
      query: ({
        limit,
        page,
        orderBy,
        orderAsc,
        q,
        startTime,
        endTime,
        isMonetizationAllowed,
      }) => ({
        url: 'admin/monetization-reports',
        params: {
          limit,
          page,
          orderBy,
          orderAsc,
          q,
          startTime,
          endTime,
          isMonetizationAllowed,
        },
        method: 'GET',
      }),
    }),

    getPublisherFileReport: builder.query<Response, IGetFileReports>({
      query(params) {
        return {
          url: `monetization-reports/export`,
          method: 'GET',
          params,
          responseHandler: async (response) => response,
          cache: 'no-cache',
        };
      },
    }),

    getOwnerFileReport: builder.query<Response, IGetOwnerFileReports>({
      query(params) {
        return {
          url: `admin/monetization-reports/export`,
          method: 'GET',
          params,
          responseHandler: async (response) => response,
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const {
  useLazyGetReportsQuery,
  useLazyGetOwnerReportsQuery,
  useLazyGetPublisherFileReportQuery,
  useLazyGetOwnerFileReportQuery,
} = monetizationApi;
