import { Colors } from '@/environment';
import styled from 'styled-components';

export const TextWrapper = styled.div`
  box-sizing: border-box;
  width: 320px;
  height: 460px;
  background: ${Colors.white};
  border-radius: 12px;
  padding: 20px 0 22px 0;
  overflow: hidden;
  position: relative;
`;

export const TextContent = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  scrollbar-width: auto;

  .terms-and-conditions {
    width: 100%;
    height: 100%;
    border: none;
  }

  & p {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: ${Colors.grey[100]};
    margin-top: 0px;
  }

  & p strong {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: ${Colors.black};
  }

  & h1 {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.black};
    margin-top: 0;
    margin-bottom: 5px;
  }

  & h2 {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.black};
    margin-top: 0;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    margin-top: 10px;
    background: rgba(161, 161, 170, 0.1);
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 24px;
    background: rgba(161, 161, 170, 0.1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }
`;

export const UpdatedInfo = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Noto Sans', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: ${Colors.lightBoldGreen};
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  button {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
`;

export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  pointer-events: none;
`;
