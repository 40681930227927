import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '@/types/users-types';

type InitialState = {
  data: Array<IUser>;
};

const initialState: InitialState = {
  data: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    updateUserStatus: (state, action) => {
      {
        const findUser = state.data.find(
          (user) => user.id === action.payload.id,
        );

        if (findUser) {
          findUser.active = action.payload.active;
          findUser.deactivatedAt = action.payload.deactivatedAt;
        }
      }
    },
    removeUser: (state, action) => {
      state.data = state.data.filter((user) => user.id !== action.payload.id);
    },
    clearUsersInfo: () => initialState,
  },
});

export const { setUsers, updateUserStatus, removeUser, clearUsersInfo } =
  usersSlice.actions;

export default usersSlice.reducer;
