import { FormEvent, useEffect, useState } from 'react';
import {
  AuthPassword,
  Button,
  FormErrors,
  StatusMessage,
} from '@/components/common';
import { Colors } from '@/environment';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import { omitObjectField } from '@/helpers/omitObjectField';
import useQueryParams from '@/hooks/useQueryParams';
import { useConfirmNewPasswordMutation } from '@/redux/api/authApi';
import { loggedOut } from '@/redux/slice/user/slice';
import { EAuthRoutes } from '@/routes';
import { Nullable } from '@/types/generic';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  InputContainerStyled,
  WrapperStyled,
} from './RecoverPasswordForm.styled';
import recoverPasswordFormShema from './validation';

export const RecoverPasswordForm = () => {
  const [textError, setTextError] = useState<Nullable<string>>(null);

  const navigate = useNavigate();
  const params = useQueryParams();
  const token = params.get('token');

  const [confirmNewPassword] = useConfirmNewPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: '',
      reEnterPassword: '',
    },
    validationSchema: recoverPasswordFormShema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const data = {
        password: values.password,
      };

      if (token) {
        confirmNewPassword({ token, data })
          .unwrap()
          .then(() => {
            loggedOut();
            navigate(`/${EAuthRoutes.Login}`);
          })
          .catch((errors) => {
            setTextError(errors.data.message);
          });
      }
    },
  });

  const { values, setFieldValue, submitForm, errors } = formik;

  const passwordFieldOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.id, e.target.value.replace(/\s+/g, ' ').trim());
  };

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitForm();
  }

  const isFilledRequiredFields = checkFormFieldsFilled(values);
  const displayedErrors = omitObjectField('reEnterPassword', errors);

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  return (
    <WrapperStyled autoComplete="nofill" onSubmit={handleSubmit}>
      <InputContainerStyled>
        <AuthPassword
          value={values.password}
          onChange={passwordFieldOnChange}
          type="password"
          id="password"
          placeholder="Password"
          autoComplete="nofill"
          isError={errors.password && 'error'}
          maxLength={255}
        />

        <AuthPassword
          value={values.reEnterPassword}
          onChange={passwordFieldOnChange}
          type="password"
          id="reEnterPassword"
          placeholder="Re-enter password"
          autoComplete="nofill"
          isError={errors.reEnterPassword && 'error'}
          maxLength={255}
        />
      </InputContainerStyled>

      <Button
        text="Confirm"
        position="center"
        type="submit"
        disabled={!isFilledRequiredFields}
        borderRadius="4px"
        backgroundColor={Colors.darkBlue}
        disabledColor={Colors.newBtnDisabled}
        height="40px"
      />
      {textError && <StatusMessage color="red" text={textError} size={12} />}

      <FormErrors errors={displayedErrors} gapTop={10} />
    </WrapperStyled>
  );
};
