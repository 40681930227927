import { useEffect, useState } from 'react';
import { Colors } from '@/environment';
import {
  NavigationButtons,
  PreviewContainer,
  VendorSiteDemoImg,
  ChooseOptionsStepWrapper,
  ButtonGroup,
  ScriptsWrapper,
  LoaderWrap,
  // DocumentationTabs,
  // DocumentationTab,
} from './ChooseOptionsStep.styled';
import { Button, LoaderSpin } from '@/components/common';
import { TPlayerCreateFields } from '@/interfaces';
import { FormikProps } from 'formik';
import { PlayerPreview } from '../../PlayerPreview';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoutes } from '@/routes';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter';
import { DocumentationByUsage } from '../../DocumentationByUsage';

interface ChooseOptionsStepProps {
  formik: FormikProps<TPlayerCreateFields>;
  changeStep: (step: number) => () => void;
  loading: boolean;
  isEdit: boolean;
  isDiff: boolean;
  playerId?: string;
  desktopPreview: string;
  mobilePreview: string;
  readOnly: boolean;
  hexColor: string;
}

export const tabs = [
  {
    value: 1,
    title: 'CMS',
  },
  {
    value: 2,
    title: 'React, Vue etc.',
  },
];
// UNCOMENT CODE IN CASE IF WILL BE NEED DOCS FOR REACT, VUE etc.
export const ChooseOptionsStep: React.FC<ChooseOptionsStepProps> = (props) => {
  const [showCode, setShowCode] = useState<boolean>(false);
  const [isImgReady, setIsImgReady] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  // const [isContainerCopied, setIsContainerCopied] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  // const [activeTab, setActiveTab] = useState(1);

  const {
    formik,
    changeStep,
    loading,
    isEdit,
    playerId,
    isDiff,
    desktopPreview,
    mobilePreview,
    readOnly,
    hexColor,
  } = props;

  const { handleSubmit, values } = formik;
  const { setBreadcrumbs } = useBreadcrumbs();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const domain = process.env.REACT_APP_API_HOST?.substr(
    0,
    process.env.REACT_APP_API_HOST.length - 4,
  );

  const scriptText = `<script type="text/javascript" src="${domain}widget/player.js"></script><script defer${values.type === 'static' ? ` id="${playerId}"` : ''
    }>TYLR_Widget.init("${playerId}")</script>`;
  // const scriptText2 = `<script type="text/javascript" src="${domain}widget/player.js"></script><link rel="stylesheet" href="${domain}widget/player.css" />`;

  const containerText = `<div id="ty-project-player-${playerId}"></div>`;

  function handleBack() {
    navigate(ERoutes.ManagePlayers);
  }

  function handleShowCode(value: boolean) {
    return () => {
      setShowCode(value);
      setIsVisible(!value);
    };
  }

  function handleClickCopy() {
    // copyToClipboard(activeTab === 1 ? scriptText : scriptText2)();
    copyToClipboard(scriptText)();

    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
      clearTimeout(timeout);
    }, 5000);
  }

  // function handleClickCopyContainer() {
  //   copyToClipboard(containerText)();
  //   setIsContainerCopied(true);

  //   const timeout = setTimeout(() => {
  //     setIsContainerCopied(false);
  //     clearTimeout(timeout);
  //   }, 5000);
  // }

  // function changeTab(tabValue: number) {
  //   return () => {
  //     setActiveTab(tabValue);
  //   };
  // }

  useEffect(() => {
    const breadcrumbsData = [
      {
        label: 'Manage Players',
      },
      {
        label: `${pathname.includes('edit') ? 'Edit' : 'Create'} a Player`,
      },
    ];

    if (!pathname.includes('edit')) {
      const createData = breadcrumbsData.concat({
        label: capitalizeFirstLetter(values.type),
      });
      if (pathname.includes('view-details')) {
        const viewersBreadcrumbsData = [
          {
            label: 'Manage Players',
          },
          {
            label: `${values.name}`,
          },
          {
            label: 'View-details',
          },
        ];
        setBreadcrumbs(viewersBreadcrumbsData);
      } else {
        setBreadcrumbs(createData);
      }
    } else {
      setBreadcrumbs(breadcrumbsData);
    }
  }, [pathname, setBreadcrumbs, values.name, values.type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsImgReady(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, [isImgReady]);

  useEffect(() => {
    setIsImgReady(false);
  }, [values.preview]);

  return (
    <ChooseOptionsStepWrapper isEdit={pathname.includes('edit')}>
      <PreviewContainer isShowCode={showCode} isVisible={isVisible}>
        <LoaderWrap isReady={isImgReady}>
          <LoaderSpin position="absolute" color={Colors.blue} />
        </LoaderWrap>

        {isImgReady && (
          <PlayerPreview playerInfo={values} hexColor={hexColor} />
        )}
        <VendorSiteDemoImg
          src={values.preview === 'mobile' ? mobilePreview : desktopPreview}
          alt="Demo vendor site layer"
          isReady={isImgReady}
        />
      </PreviewContainer>
      {readOnly ? (
        <NavigationButtons>
          <Button
            text={'Back to Manage Players'}
            borderRadius={'12px'}
            width={'179px'}
            height={'40px'}
            position={'center'}
            onClick={handleBack}
          />
        </NavigationButtons>
      ) : (
        <>
          {!showCode && (
            <NavigationButtons>
              {!isEdit ? (
                <Button
                  text={'Back'}
                  borderRadius={'8px'}
                  width={'125px'}
                  height={'40px'}
                  position={'center'}
                  onClick={changeStep(1)}
                />
              ) : (
                <Button
                  text={'Back to Manage players'}
                  borderRadius={'8px'}
                  width={'205px'}
                  height={'40px'}
                  position={'center'}
                  onClick={handleBack}
                />
              )}
              <ButtonGroup>
                {isEdit && (
                  <Button
                    text={'Show code'}
                    borderRadius={'8px'}
                    width={'125px'}
                    height={'40px'}
                    position={'center'}
                    type="submit"
                    onClick={handleShowCode(true)}
                  />
                )}
                <Button
                  text={isEdit ? 'Publish' : 'Next'}
                  borderRadius={'8px'}
                  width={'125px'}
                  height={'40px'}
                  position={'center'}
                  type="submit"
                  onClick={handleSubmit}
                  disabledColor={Colors.trasparentedBlue}
                  loading={loading}
                  disabled={isDiff}
                />
              </ButtonGroup>
            </NavigationButtons>
          )}
        </>
      )}
      {showCode && isImgReady && (
        <ScriptsWrapper>
          {/* <DocumentationTabs>
            {tabs.map((tab) => (
              <DocumentationTab
                key={tab.value}
                isActive={tab.value === activeTab}
                onClick={changeTab(tab.value)}
              >
                {tab.title}
              </DocumentationTab>
            ))}
          </DocumentationTabs> */}
          {/* {activeTab === 1 && ( */}
          <DocumentationByUsage
            scriptText={`<script
type="text/javascript"
src="${domain}widget/player.js"
></script>
<script defer${values.type === 'static' ? ` id="${playerId}"` : ''}>
TYLR_Widget.init("${playerId}")
</script>`}
            isCopied={isCopied}
            playerId={playerId}
            containerText={containerText}
            copyText={handleClickCopy}
            isCms
            playerType={values.type}
          />
          {/* )} */}
          {/* {activeTab === 2 && (
            <DocumentationByUsage
              scriptText={`<script
type="text/javascript"
src="${domain}widget/player.js"
></script>
`}
              isCopied={isCopied}
              playerId={playerId}
              containerText={containerText}
              copyText={handleClickCopy}
              clickCopyContainer={handleClickCopyContainer}
              isContainerCopied={isContainerCopied}
              withBlock
              additionalInfo={`You can initialize and reinitialize the widget using "window.Widget.initInBlock(${playerId})" at the moment when your page is being mounted. Be sure that in the case of using a dynamic widget, the insertion block does not limit anything in terms of positioning and it is located directly in the page wrapper block without attachment to the rest.`}
            />
          )} */}

          <ButtonGroup>
            <Button
              text={'Back to Manage players'}
              borderRadius={'8px'}
              width={'205px'}
              height={'40px'}
              position={'center'}
              onClick={handleBack}
            />
            <Button
              text={'Hide code'}
              borderRadius={'8px'}
              width={'125px'}
              height={'40px'}
              position={'center'}
              type="submit"
              onClick={handleShowCode(false)}
            />
          </ButtonGroup>
        </ScriptsWrapper>
      )}
    </ChooseOptionsStepWrapper>
  );
};
