
import { ReactNode } from 'react';

import {
  BillingLayoutWrapper,
  PageHeaderStyled, PageHeaderWrapperStyled, AccountRoleStyled, AccountNameStyled, HeaderRow, AccountInfoStyled
} from './MonetizationLayout.styled'
import { useSelector } from 'react-redux';
import { RootState } from '@/redux';

interface IMonetizationLayout {
  children: ReactNode;
  topRightSlot?: ReactNode;
  headerRightSlot?: ReactNode;
  header: string;
  role?: string;
  publisherName?: string;
}

export const MonetizationLayout: React.FC<IMonetizationLayout> = (props) => {
  const { children, topRightSlot, header, headerRightSlot, role: propsRole, publisherName: propsPublisherName } = props;

  const { role: currentUserRole, currentPublisher } = useSelector(
    (store: RootState) => ({
      role: store.user.data?.role,
      currentPublisher: store.publishers.currentPublisher,
    }),
  );
  const role = propsRole === undefined ? currentUserRole : propsRole;
  const publisherName = propsPublisherName === undefined ? currentPublisher?.name : propsPublisherName;

  return (
    <BillingLayoutWrapper>
      <HeaderRow>
        <AccountInfoStyled>
          <AccountNameStyled>
            {publisherName}
          </AccountNameStyled>
          <AccountRoleStyled>{role}</AccountRoleStyled>
        </AccountInfoStyled>
        {topRightSlot}

      </HeaderRow>
      <PageHeaderWrapperStyled>
        <PageHeaderStyled>
          {header}
        </PageHeaderStyled>
        {headerRightSlot}

      </PageHeaderWrapperStyled>

      {children}
    </BillingLayoutWrapper>
  )
};
