import styled from 'styled-components';
import { Radio } from 'antd';
import { Colors } from '@/environment';

const { Group } = Radio;

export const RadioGroup = styled(Group)`
  width: 100%;
  height: 36px;
  background-color: ${Colors.grey[50]};
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif;

  .ant-radio-button-wrapper {
    border-radius: 16px;
    background-color: ${Colors.radioUnchecked} !important;
    border: none;
    color: ${Colors.text.bold} !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0px;
    font-size: 12px;

    &::before {
      display: none;
    }

    &:hover {
      background-color: ${Colors.radioUnchecked} !important;
      outline: none;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: ${Colors.blue} !important;
    color: ${Colors.white} !important;

    &:hover {
      background-color: ${Colors.blue} !important;
      outline: none;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
    background-color: ${Colors.text.main} !important;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
    cursor: initial;
  }
`;
