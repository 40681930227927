import { EGeneralRoutes } from '@/routes';
import { Component, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

export class ErrorBoundary extends Component<Props> {
  public state = {
    error: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: Error, info: any) {
    console.log(error, info);
    this.setState({ error: true });
  }
  public render() {
    if (this.state.error) {
      return <Navigate to={EGeneralRoutes.NotFound} />;
    }

    return this.props.children;
  }
}
