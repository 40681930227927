import { InputProps } from 'antd';
import { memo } from 'react';
import { AuthInputErrorStyled, AuthInputStyled } from './AuthInput.styled';

interface IAuthInputProps extends InputProps {
  errorMessage?: string;
  isError?: string;
}

export const AuthInput: React.FC<IAuthInputProps> = memo((props) => {
  const { errorMessage, isError, ...inputProps } = props;

  return (
    <>
      <AuthInputStyled
        {...inputProps}
        className="auth-input"
        isError={isError === 'error'}
      />

      {errorMessage && (
        <AuthInputErrorStyled>{errorMessage}</AuthInputErrorStyled>
      )}
    </>
  );
});

AuthInput.displayName = 'AuthInput';
