import styled from 'styled-components';
import { Colors } from '@/environment';

export const ScriptBlock = styled.div`
  color: ${Colors.black};
  height: max-content;
  min-height: 185px;
  background: ${Colors.lightblue};
  width: 100%;
  border-radius: 12px;
  padding: 20px 80px 20px 22.5px;
  border: 2px solid ${Colors.blue};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  max-width: 600px;

  pre {
    margin: 0;
  }
`;

export const ContainerBlock = styled(ScriptBlock)`
  min-height: 100px;
`;

export const CopyIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  right: 20px;
  top: 20px;
  background: ${Colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  cursor: pointer;

  &.copied {
    background: ${Colors.blue};
  }
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.grey[100]};
  max-width: 600px;
  width: 100%;
`;

export const InstructionsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #172b4d;
  font-weight: 400;
  font-size: 14px;
  max-width: 600px;

  & > :first-child,
  & .bold {
    font-weight: 600;
  }

  & .italic {
    font-style: italic;
  }
`;
