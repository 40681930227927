import * as Yup from 'yup';

interface ISignUpUserForm {
  firstName: string;
  lastName: string;
  email: string;
  publisherName: string;
  phone?: string;
  password: string;
  reEnterPassword: string;
}

const signUpUserFormShema: Yup.SchemaOf<ISignUpUserForm> = Yup.object({
  firstName: Yup.string()
    .trim()
    .max(255)
    .required('First name is required field'),
  lastName: Yup.string()
    .trim()
    .max(255)
    .required('Last name is required field'),
  publisherName: Yup.string()
    .min(2, "can't be less than 2 characters")
    .max(255)
    .required('Required field'),
  phone: Yup.string().matches(
    /^([+]?[1]+)\s?\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
    'Phone number is not valid',
  ),
  // .required('Phone number is required field')
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .max(255)
    .required('Email is required field'),
  password: Yup.string()
    .trim()
    .required('Password is required field')
    .min(
      8,
      'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
    )
    .max(255)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
    )
    .oneOf([Yup.ref('reEnterPassword'), null], 'Passwords doesn’t match'),
  reEnterPassword: Yup.string()
    .trim()
    .required('Re-enter password is required field')
    .max(255)
    .oneOf([Yup.ref('password'), null], 'Passwords doesn’t match'),
});

export default signUpUserFormShema;
