import styled from 'styled-components';
import { DatePicker } from 'antd';
import { Colors } from '@/environment';

const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  border-radius: 16px;

  .ant-picker-input input {
    text-align: center;
  }
`;

export const Separator = styled.div`
  color: ${Colors.text.main};
  padding: 0;
`;
