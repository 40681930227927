import styled from 'styled-components';
import { Input } from 'antd';
import { Colors } from '@/environment';

export const StyledInput = styled(Input)`
  border: none;
  border-radius: 12px;
  background-color: ${Colors.white};
  width: 320px;
  padding: 6px 14px;
  box-sizing: border-box;
  display: flex;
  gap: 4px;
  box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
  font-family: 'Inter', sans-serif;
  border: 2px solid ${Colors.transparentBorderBlue};
  font-size: 12px;
  font-weight: 400;

  .ant-input {
    font-family: 'Inter', sans-serif;
    font-size: 12px !important;
    font-weight: 400;
  }

  &:hover {
    background-color: ${Colors.white};
    border: 2px solid ${Colors.transparentBorderBlue} !important;
    box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
  }

  & > input::placeholder {
    color: ${Colors.text.main};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
  }

  & > input:hover {
    background-color: ${Colors.white};
  }
`;
