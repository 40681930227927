import { EInviteUserRole } from '@/consts';
import * as Yup from 'yup';

interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  reEnterEmail: string;
}

const userFormSchema: Yup.SchemaOf<IUserForm> = Yup.object({
  role: Yup.string().oneOf(Object.values(EInviteUserRole)).required(),
  firstName: Yup.string()
    .trim()
    .max(255)
    .required('First name is required field'),
  lastName: Yup.string()
    .trim()
    .max(255)
    .required('Last name is required field'),
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .oneOf([Yup.ref('reEnterEmail'), null], 'Email addresses doesn’t match')
    .required('Email is required field'),
  reEnterEmail: Yup.string()
    .trim()
    .required('Re-enter email is required field')
    .oneOf([Yup.ref('email'), null], 'Email addresses doesn’t match'),
});

export default userFormSchema;
