import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  AuthInput,
  AuthPassword,
  FormErrors,
  StatusMessage,
  TermsOfConditions,
  TermsOfConditionsCheckbox,
} from '@/components/common';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import { AuthButtonStyled } from '@/components/ui/Auth/AuthWrapper/AuthWrapper.styled';
import { usePublisherRegisterMutation } from '@/redux/api/authApi';
import { loggedOut } from '@/redux/slice/user/slice';
import { EAuthRoutes } from '@/routes';
import { TPublisherRegisterRequest } from '@/types/publisher-types';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { PublisherSignUpFormWrapper } from './PublisherSignUpForm.styled';
import signUpPublisherFormShema from './validation';
import { AuthGroupInput, EmailGroup } from '@/components/ui';
import { Nullable } from '@/types/generic';

interface IPublisherSignUpFormProps {
  isShowTerms: boolean;
  setIsShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PublisherSignUpForm: React.FC<IPublisherSignUpFormProps> = (
  props,
) => {
  const [textError, setTextError] = useState<Nullable<string>>(null);
  const [isTermSelected, setIsTermSelected] = useState(false);

  const { isShowTerms, setIsShowTerms } = props;

  const navigate = useNavigate();

  const [publisherRegister] = usePublisherRegisterMutation();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      publisherName: '',
      phone: '',
      website: '',
      email: '',
      reEnterEmail: '',
      password: '',
      reEnterPassword: '',
    },
    validationSchema: signUpPublisherFormShema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const data: TPublisherRegisterRequest = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        password: values.password.trim(),
        publisherName: values.publisherName.trim(),
        website: values.website.trim(),
        phone: values.phone,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      publisherRegister({ data }).then((res: any) => {
        if (res.error.originalStatus === 201) {
          loggedOut();
          navigate(`/${EAuthRoutes.Confirm}?email=${values.email}`);
        } else {
          setTextError(res.error.data.message);
        }
      });
    },
  });

  const { values, setFieldValue, handleChange, submitForm, errors } = formik;

  const fieldOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== ' ') {
      setFieldValue(e.target.id, e.target.value.replace(/\s+/g, ' '));
    }
  };

  const passwordFieldOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.id, e.target.value.replace(/\s+/g, ' ').trim());
  };

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitForm();
  }

  const isFilledRequiredFields = checkFormFieldsFilled(
    omit(values, ['phone', 'website']),
  );
  const displayedErrors = omit(errors, ['reEnterEmail', 'reEnterPassword']);

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  useEffect(() => {
    if (errors) {
      setTextError('');
    }
  }, [errors]);

  return (
    <>
      {!isShowTerms && (
        <PublisherSignUpFormWrapper
          autoComplete="nofill"
          onSubmit={handleSubmit}
        >
          <AuthGroupInput>
            <AuthInput
              value={values.firstName}
              onChange={fieldOnChange}
              type="text"
              id="firstName"
              placeholder="First Name*"
              status={errors.firstName && 'error'}
              maxLength={255}
            />

            <AuthInput
              value={values.lastName}
              onChange={fieldOnChange}
              type="text"
              id="lastName"
              placeholder="Last Name*"
              status={errors.lastName && 'error'}
              maxLength={255}
            />
          </AuthGroupInput>

          <AuthInput
            value={values.publisherName}
            onChange={fieldOnChange}
            type="text"
            id="publisherName"
            placeholder="Name of organization*"
            status={errors.publisherName && 'error'}
            maxLength={255}
          />

          <PatternFormat
            id="phone"
            value={values.phone}
            onChange={handleChange}
            customInput={AuthInput}
            format="+1 (###) ###-####"
            mask={'_'}
            placeholder="Phone number"
            autoComplete="nofill"
            status={errors.phone && 'error'}
          />

          <AuthInput
            value={values.website}
            onChange={fieldOnChange}
            type="string"
            id="website"
            placeholder="Company URL"
            status={errors.website && 'error'}
            maxLength={255}
          />

          <EmailGroup
            email={values.email}
            reEnterEmail={values.reEnterEmail}
            emailError={errors.email}
            reEnterEmailError={errors.reEnterEmail}
            handleChange={fieldOnChange}
          />

          <AuthPassword
            value={values.password}
            onChange={passwordFieldOnChange}
            type="password"
            id="password"
            placeholder="Create a password*"
            autoComplete="new-password"
            isError={errors.password && 'error'}
          />

          <AuthPassword
            value={values.reEnterPassword}
            onChange={passwordFieldOnChange}
            type="password"
            id="reEnterPassword"
            placeholder="Re-enter password*"
            autoComplete="new-password"
            isError={errors.reEnterPassword && 'error'}
          />

          <TermsOfConditionsCheckbox
            isTermSelected={isTermSelected}
            setIsShowTerms={setIsShowTerms}
            setIsTermSelected={setIsTermSelected}
          />

          <AuthButtonStyled
            type="primary"
            size="large"
            block
            disabled={!isFilledRequiredFields || !isTermSelected}
            htmlType="submit"
          >
            Submit
          </AuthButtonStyled>

          {textError && (
            <StatusMessage color={'red'} text={textError} size={12} />
          )}

          <FormErrors errors={displayedErrors} />
        </PublisherSignUpFormWrapper>
      )}

      {isShowTerms && (
        <TermsOfConditions
          setIsShowTerms={setIsShowTerms}
          setIsTermSelected={setIsTermSelected}
        />
      )}
    </>
  );
};
