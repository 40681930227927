import styled from 'styled-components';

export const StyledButton = styled.button.attrs(
  (props: {
    borderRadius: string;
    position: string;
    width: string;
    height: string;
    type: string;
    backgroundColor: string;
    textColor: string;
    border: string;
    disabledColor: string;
    backgroundColorHover: string;
  }) => ({
    ...props,
  }),
)`
  outline: none;
  border: ${(props) => props.border};
  cursor: pointer;
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.position};
  gap: 15px;
  width: ${(props) => props.width};
  color: ${(props) => props.textColor};
  padding: 8px 0;
  height: ${(props) => props.height};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  opacity: ${(props) => (props.type === 'button' && props.disabled ? 0.2 : 1)};

  &:disabled {
    background: ${(props) => props.disabledColor};
    cursor: initial;
  }

  &:hover {
    background-color: ${(props) =>
      props.backgroundColorHover
        ? props.backgroundColorHover
        : props.backgroundColor};
  }

  &:disabled:hover {
    cursor: default;
    background: ${(props) => props.disabledColor};
  }
`;
