import { useEffect, useRef } from 'react';
import { CustomCursor } from './SphereBlob.styled';
import { Nullable } from '@/types/generic';

export const SphereBlob = () => {
  const blobRef = useRef<Nullable<HTMLDivElement>>(null);
  const cursorRef = useRef({ x: 200, y: 200 });

  useEffect(() => {
    function onMouseMove({
      clientX,
      clientY,
    }: {
      clientX: number;
      clientY: number;
    }) {
      cursorRef.current = {
        x: clientX,
        y: clientY,
      };
    }
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useEffect(() => {
    const loop = () => {
      if (!blobRef.current) {
        return;
      }
      blobRef.current?.style.setProperty(
        '--left',
        cursorRef.current.x.toString(),
      );
      blobRef.current?.style.setProperty(
        '--top',
        cursorRef.current.y.toString(),
      );
      requestAnimationFrame(loop);
    };

    loop();
  }, []);

  return <CustomCursor ref={blobRef} />;
};
