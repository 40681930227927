import { Colors } from '@/environment';
import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.div`
  box-sizing: border-box;
  padding-left: 30px;
  padding-left: 95px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const BreadcrumbDivider = styled.span`
  margin: 0 0.3rem;
  color: ${Colors.grey[40]};
`;

export const BreadcrumbItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadcrumbItem = styled.span.attrs((props: { color: string }) => ({
  ...props,
}))`
  display: block;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  color: ${(props) => props.color};
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
