import { Tooltip } from 'antd';
import { Icon } from '../Icon';
import { IconType } from '@/consts';
import { FC, ReactNode, useState } from 'react';

type TQuestionTooltipProps = {
  title: ReactNode;
};

export const QuestionTooltip: FC<TQuestionTooltipProps> = ({ title }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Tooltip
      title={
        <span
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          {title}
        </span>
      }
      placement="bottom"
      destroyTooltipOnHide
      open={isActive}
      onOpenChange={(open) => setIsActive(open)}
      color="#8aa1bf"
      overlayStyle={{ width: '100%', maxWidth: 'max-content', padding: 8 }}
    >
      <div
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          setIsActive((prev) => !prev);
        }}
      >
        <Icon name={isActive ? IconType.TYQustionFilled : IconType.TYQustion} />
      </div>
    </Tooltip>
  );
};
