import styled from 'styled-components';

export const WrapperStyled = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;

  button {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  & .message {
    margin-top: 20px;
  }
`;

export const InputContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
  width: 320px;
`;
