import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlayer, IPlayersStatistic } from '@/types/players-types';
import { Nullable } from '@/types/generic';

type InitialState = {
  data: Array<IPlayer>;
  stats: Nullable<IPlayersStatistic>;
};

const initialState: InitialState = {
  data: [],
  stats: null,
};

const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setPlayers: (state, { payload }: PayloadAction<IPlayer[]>) => ({
      ...state,
      data: payload,
    }),
    setMorePlayers: (state, { payload }: PayloadAction<IPlayer[]>) => ({
      ...state,
      data: state.data.concat(payload),
    }),
    removePlayer: (state, action) => {
      state.data = state.data.filter(
        (player) => player.id !== action.payload.id,
      );
    },
    updatePlayer: (state, action) => {
      const player = state.data.find(
        (player) => player.id === action.payload.id,
      );

      if (player) {
        player.isAdAllowed = action.payload.isAdAllowed;
        player.status = action.payload.status;
      }
    },
    setStats: (state, { payload }: PayloadAction<IPlayersStatistic>) => ({
      ...state,
      stats: payload,
    }),
    clearPlayersInfo: () => initialState,
  },
});

export const {
  setPlayers,
  removePlayer,
  updatePlayer,
  setMorePlayers,
  setStats,
  clearPlayersInfo,
} = playersSlice.actions;

export default playersSlice.reducer;
