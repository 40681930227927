/* eslint-disable indent */
import { IconType } from '@/consts';
import { ERoutes } from '@/routes';

export default function useNavigationData(isAdmin: boolean, isRoot: boolean) {
  const TutoriaRoute = {
    title: 'Tutorials',
    icon: IconType.TYTutorialsIcon,
    path: ERoutes.Tutorials,
  };

  const getMonetization = (path: string) => ({
    title: 'Revenue',
    icon: IconType.TYRevenueIcon,
    path: path,
  });

  return isAdmin
    ? [
        {
          title: 'Dashboard',
          icon: IconType.TYDashboardIcon,
          path: ERoutes.Root,
        },
        ...(isRoot
          ? [
              {
                title: 'Manage publishers',
                icon: IconType.TYManagePublishers,
                path: ERoutes.ManagePublishers,
              },
              {
                title: 'Manage players',
                icon: IconType.TYManageUsersIcon,
                path: ERoutes.ManagePlayers,
              },
              getMonetization(ERoutes.OwnerMonetization),
            ]
          : [
              {
                title: 'Manage players',
                icon: IconType.TYManagePlayersIcon,
                path: ERoutes.ManagePlayers,
              },
              {
                title: 'Manage users',
                icon: IconType.TYManageUsersIcon,
                path: ERoutes.ManageUsers,
              },
              getMonetization(ERoutes.PubliserMonetization),
            ]),
        TutoriaRoute,
      ]
    : [
        {
          title: 'Dashboard',
          icon: IconType.TYDashboardIcon,
          path: ERoutes.Root,
        },
        {
          title: 'Manage players',
          icon: IconType.TYManagePlayersIcon,
          path: ERoutes.ManagePlayers,
        },
        getMonetization(ERoutes.PubliserMonetization),
        TutoriaRoute,
      ];
}
