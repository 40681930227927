import { Card } from '@/components/common';
import { memo, useCallback, useState } from 'react';
import { CardsWrapper } from './CardsGroup.styled';
import difference from 'lodash/difference';

interface ICard {
  title: string;
  value: string;
  key: string;
  compareValue: number;
}

interface ICardsGroupProps {
  cards: ICard[];
  initialKeys: string[];
}

export const CardsGroup: React.FC<ICardsGroupProps> = memo(
  ({ cards, initialKeys }) => {
    const [selectedCards, setSelectedCards] = useState<string[]>(initialKeys);

    const setCurrentCards = useCallback(
      (value: string, index: number) => {
        return () => {
          // eslint-disable-next-line prefer-const
          let newCards = [...selectedCards];

          if (selectedCards.includes(value)) {
            const prevIndex = selectedCards.indexOf(value);
            const allCards = cards.map((card) => card.key);
            const diff = difference(allCards, selectedCards);

            newCards[index] = value;
            newCards[prevIndex] = diff[0];
          } else {
            newCards[index] = value;
          }
          setSelectedCards(newCards);
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedCards],
    );

    return (
      <CardsWrapper>
        {selectedCards.map((card: string, index: number) => (
          <Card
            key={card}
            id={index}
            selected={card}
            cards={cards}
            setSelectedCards={setCurrentCards}
            selectedCards={selectedCards}
          />
        ))}
      </CardsWrapper>
    );
  },
);

CardsGroup.displayName = 'CardsGroup';
