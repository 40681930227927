import styled from 'styled-components';
import { Colors } from '@/environment';

export const AmountWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 24px;
  align-items: center;
`;
export const AmountLabelTextStyled = styled.div`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.text.main};
`;
