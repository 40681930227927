import { memo } from 'react';
import { IconType } from '@/consts';
import { Icon } from '../Icon';
import { StyledInput } from './SearchInput.styled';

type TSearchInputProps = {
  onChange: (value: string) => void;
};

export const SearchInput: React.FC<TSearchInputProps> = memo(({ onChange }) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return (
    <div>
      <StyledInput
        bordered={false}
        onChange={handleChange}
        placeholder="Type something to search "
        prefix={<Icon name={IconType.TYSearchIcon} />}
      />
    </div>
  );
});

SearchInput.displayName = 'SearchInput';
