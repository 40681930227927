import * as Yup from 'yup';

export interface IInvitePublisherForm {
  firstName: string;
  lastName: string;
  email: string;
}

const invitePublisherFormSchema: Yup.SchemaOf<IInvitePublisherForm> =
  Yup.object({
    firstName: Yup.string().max(255).required('First name is required field'),
    lastName: Yup.string().max(255).required('Last name is required field'),
    email: Yup.string()
      .email('This Email address is invalid.')
      .required('Email is required field'),
  });

export default invitePublisherFormSchema;
