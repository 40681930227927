import { Colors } from '@/environment';
import { Input } from 'antd';
import styled, { css } from 'styled-components';

export const AuthInputStyled = styled(Input).attrs(
  (props: { isError: boolean }) => ({
    ...props,
  }),
)`
  &.auth-input {
    background: ${Colors.authInputBg} !important;
    border-radius: 4px;
    border-color: transparent;
    ${({ isError }) =>
      isError &&
      css`
        border-color: ${Colors.error} !important;
      `}

    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.darkBlue};
    font-family: 'Noto Sans', 'Inter', sans-serif;
    padding: 8px 16px;

    &::placeholder {
      color: ${Colors.grey[100]};
    }

    &:hover {
      border-color: transparent;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${Colors.authInputBg} inset !important;
      box-shadow: 0 0 0 50px ${Colors.authInputBg} inset !important;
      background-color: ${Colors.authInputBg} !important;
      background: ${Colors.authInputBg} !important;
      border-color: ${Colors.authInputBg};
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${Colors.authInputBg} inset !important;
      box-shadow: 0 0 0 50px ${Colors.authInputBg} inset;
      background-color: ${Colors.authInputBg};
    }

    & .ant-input {
      padding-top: 2px;
      background-color: ${Colors.authInputBg} !important;
      background: ${Colors.authInputBg} !important;
      box-shadow: none !important;

      &::placeholder {
        letter-spacing: normal;
        color: ${Colors.grey[100]};
      }
    }
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid ${Colors.authInputFocus};

    &:hover {
      border-color: ${Colors.authInputFocus};
    }
  }

  &.auth-input:focus {
    border-color: ${Colors.authInputFocus} !important;
    box-shadow: none;
  }

  &.auth-input.ant-input-disabled {
    background: ${Colors.authInputDisabled} !important;
    border-color: ${Colors.authInputDisabledBorder} !important;
    cursor: auto;
  }

  &.auth-input.ant-input-disabled:hover {
    cursor: auto;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const AuthInputErrorStyled = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: ${Colors.error};
  margin-top: -15px;
`;
