import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, FormErrors, BaseFormInput } from '@/components/common';
import { Colors } from '@/environment';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import useBackPage from '@/hooks/useBackPage';
import { useCheckInvitation } from '@/hooks/useCheckInvitation';
import {
  DelimiterStyled,
  StatusMessageStyled,
} from '@/components/ui/Layouts/InviteFormLayout/InviteFormLayout.styled';

import { Form } from 'antd';
import { useFormik } from 'formik';
import invitePublisherFormSchema, { IInvitePublisherForm } from './validation';
import { DEFAULT_PUBLISHER_NAME } from '@/consts';
import { EInvitationStatus, IInvatationStatus } from '@/interfaces';
import { InviteFormLayout } from '@/components/ui/Layouts/InviteFormLayout';
import { useInvitePublisherMutation } from '@/redux/api/usersApi';
import { Nullable } from '@/types/generic';

interface IInviteUserFormProps {
  setUserName: Dispatch<SetStateAction<string>>;
}

export const InvitePublisherForm: React.FC<IInviteUserFormProps> = ({
  setUserName,
}) => {
  const [responseErrors, setResponseErrors] = useState<Nullable<string>>(null);

  const onBackPage = useBackPage();

  const [invitePublisher] = useInvitePublisherMutation();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: invitePublisherFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const data: IInvitePublisherForm = {
        firstName: values.firstName.replace(/\s/g, ''),
        lastName: values.lastName.replace(/\s/g, ''),
        email: values.email.replace(/\s/g, ''),
      };
      invitePublisher(data)
        .unwrap()
        .then(() => {
          setResponseErrors('');
          setInvitationStatus({
            color: Colors.green,
            message: `Invitation was succesfully sent to ${values.email}`,
            status: EInvitationStatus.Submitted,
          });
        })
        .catch((errors) => {
          setResponseErrors(errors.data.message);
        });
    },
  });

  const { values, handleChange, submitForm, errors: yupErrors } = formik;

  useEffect(() => {
    const userName = `${values.firstName} ${values.lastName}`.trim();

    if (userName) {
      setUserName(`${values.firstName} ${values.lastName}`);
    } else {
      setUserName(DEFAULT_PUBLISHER_NAME);
    }
  }, [values.firstName, values.lastName, setUserName]);

  const { invitationStatus, setInvitationStatus } = useCheckInvitation(
    values.email,
  );

  const isFilledRequiredFields = checkFormFieldsFilled(values);

  const getSubmitButtonText = useCallback(
    (invitationStatus: Nullable<IInvatationStatus>, role: string) => {
      if (invitationStatus?.status === EInvitationStatus.Submitted) {
        return `Back to Manage ${role}`;
      }

      if (invitationStatus?.status === EInvitationStatus.SentEarlier) {
        return 'Send again';
      }

      return 'Send invitation';
    },
    [],
  );

  useEffect(() => {
    if (yupErrors) {
      setResponseErrors('');
    }
  }, [yupErrors]);

  return (
    <InviteFormLayout>
      <Form.Item>
        <BaseFormInput
          id="firstName"
          label="First Name"
          value={values.firstName.replace(/\s/g, '')}
          onChange={handleChange}
          color={Colors.inputBlue}
          status={yupErrors.firstName && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="lastName"
          label="Last Name"
          value={values.lastName.replace(/\s/g, '')}
          onChange={handleChange}
          color={Colors.inputBlue}
          status={yupErrors.lastName && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="email"
          label="Email address"
          value={values.email.replace(/\s/g, '')}
          onChange={handleChange}
          color={Colors.inputBlue}
          status={yupErrors.email && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <DelimiterStyled />

      <Button
        text={getSubmitButtonText(invitationStatus, 'Publishers')}
        type="primary"
        height="40px"
        onClick={
          invitationStatus?.status === EInvitationStatus.Submitted
            ? onBackPage
            : submitForm
        }
        position="center"
        borderRadius="12px"
        disabled={
          !isFilledRequiredFields ||
          invitationStatus?.status === EInvitationStatus.UserAlready
        }
        disabledColor={Colors.grey[100]}
      />

      {invitationStatus && (
        <StatusMessageStyled color={invitationStatus.color}>
          {invitationStatus.message}
        </StatusMessageStyled>
      )}

      {responseErrors && (
        <StatusMessageStyled color={Colors.error} fontWeight="400">
          {responseErrors}
        </StatusMessageStyled>
      )}

      <FormErrors errors={yupErrors} gapTop={20} fontSize={12} />
    </InviteFormLayout>
  );
};
