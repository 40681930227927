import { Colors } from '@/environment';
import { Divider } from 'antd';
import styled from 'styled-components';

export const PlayerFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  overflow: auto;
  padding: 40px 20px 20px 40px;
  scrollbar-width: auto;
  scrollbar-color: ${Colors.scrollbar} transparent;

  ::-webkit-scrollbar {
    width: 4px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }

  @media (max-width: 1240px) {
    padding-top: 30px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
  & .base-label {
    color: ${Colors.text.bold};
  }

  & .ant-input {
    height: 40px;
    border-radius: 12px;
    border: 2px solid ${Colors.transparentBorderBlue};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    &::placeholder {
      color: ${Colors.text.main};
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }
  }

  & .ant-select .ant-select-selector {
    box-shadow: none !important;
  }

  & .ant-space-item {
    color: ${Colors.dark};
  }

  & .input-error {
    font-size: 10px;
  }
`;

export const StyledDivider = styled(Divider).attrs((props: { mr: string }) => ({
  ...props,
}))`
  background-color: ${Colors.grey[80]};
  margin: ${(props) => props.mr};
`;

export const SwitchRow = styled.div.attrs(
  (props: { disabled: boolean; checked: boolean }) => ({
    ...props,
  }),
)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .switch-title {
    color: ${({ checked }) => (checked ? Colors.blue : Colors.text.main)};
    display: flex;
    gap: 7px;
    align-items: center;
  }

  .tooltip-text {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    white-space: nowrap;
    word-wrap: normal;
  }
`;

export const SwitchesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding-left: 17px;
  box-sizing: border-box;
`;

export const WidthInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  color: ${Colors.text.bold};

  & > :first-child {
    padding: 8px 16px 0px 0px;
  }

  .width-title {
    padding-top: 8px;
  }

  & > :last-child {
    padding: 8px 0px 0px 8px;
  }
`;

export const ColorOption = styled.div.attrs((props: { color: string }) => ({
  ...props,
}))`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  background: ${(props) => props.color};
  border-radius: 50%;
`;

export const RecommendationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 8px 0px 0px 17px;
  color: ${Colors.text.bold};
`;

export const HexInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-start;
  color: ${Colors.text.bold};
  padding-bottom: 35px;
  border-bottom: 1px solid #dde5ef;

  & > :last-child {
    margin-top: 36px;
    margin-right: 10px;
  }

  .styled-input {
    width: 240px !important;
  }
`;

export const ColorsControlWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hex {
    gap: 0px !important;
  }
`;

export const ColorSelectPlaceholder = styled.div`
  height: 16px;
  width: 1px;
`;
