import { Colors } from '@/environment';
import styled from 'styled-components';

interface IUserListCardProps {
  selected: boolean;
  active?: boolean;
}

interface IconWrapperProps {
  width: number;
  height: number;
  background: string;
  borderRadius: number;
}

interface TabsHeaderItemProps {
  isActive: boolean;
}

interface TabsHeaderProps {
  isSinglePublisherTabs?: boolean;
}

const getHoverColor = ({ selected }: IUserListCardProps) =>
  selected ? 'rgba(141, 212, 252, 0.2)' : 'rgba(0, 88, 208, 0.02)';

const getBackgroundColor = ({ selected }: IUserListCardProps) =>
  selected ? 'rgba(141, 212, 252, 0.2)' : 'transparent';

const getUserNameColor = ({ selected, active }: IUserListCardProps) =>
  selected
    ? `${Colors.black}`
    : active === undefined || active
    ? `${Colors.text.main}`
    : Colors.grey[100];

export const WrapperStyled = styled.div`
  background-color: ${Colors.newContentBg};
  border-radius: 24px 24px 0px 0px;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 1240px) {
    width: calc(100% - 220px - 20px);
    padding: 30px 30px 0px 30px;
  }
`;

export const LeftContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;
  border-right: 1px solid #eaebee;
  padding-top: 40px;
`;

export const RightContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;
  padding-top: 40px;
`;

export const InfoBlockStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.text.bold};
  width: 100%;
  padding: 0px 40px 0px 40px;
  box-sizing: border-box;
  height: 51px;
  flex: none;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

export const InfoBlockTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.grey[100]};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 280px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;

  & > :first-child {
    color: ${Colors.text.bold};
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
  }

  & .role {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.grey[100]};
  }

  & .role:first-letter {
    text-transform: uppercase;
  }

  & .deactivated {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.grey[100]};
    display: flex;
    align-items: center;

    & .date {
      color: ${Colors.black};
      padding: 0 0 0 5px;
      margin: 0;
    }
  }

  & .user-status {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.error};
  }
`;

export const InfoBlockIconStyled = styled.div`
  align-self: flex-start;
  margin-left: auto;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
`;

export const UserListStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 40px 40px 30px;
  gap: 5px;
  margin-top: 20px;
  overflow-y: scroll;
  align-items: center;

  scrollbar-width: auto;
  scrollbar-color: rgba(161, 161, 170, 0.1) transparent;

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }

  &.inactive {
    margin-top: 15px;
  }
`;

export const NestedUserListStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  gap: 5px;

  &.inactive {
    margin-top: 15px;
  }
`;

export const NestedUserListCardStyled = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.black};
  gap: 16px;
  padding: 12px 26px 12px 38px;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;

  background: ${getBackgroundColor};

  &:hover {
    background: ${getHoverColor};
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
`;

export const UserListCardStyled = styled.div<IUserListCardProps>`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.black};
  gap: 16px;
  padding: 12px 26px;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  min-height: 39px;

  background: ${getBackgroundColor};

  &:hover {
    background: ${getHoverColor};
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
`;

export const IconWrapperStyled = styled.div<IconWrapperProps>`
  display: flex;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  border-radius: ${(props) => `${props.borderRadius}px`};
`;

export const NameStyled = styled.div<IUserListCardProps>`
  color: ${getUserNameColor};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;

  min-height: 20px;
`;

export const NameInactiveUserStyled = styled.div<IUserListCardProps>`
  color: ${Colors.text.main};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`;

export const InactiveUserHeaderStyled = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.dark};
  margin-left: 50px;
`;

export const TabsHeader = styled.div<TabsHeaderProps>`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  padding-bottom: 8px;
  padding-left: ${(props) => (props.isSinglePublisherTabs ? '40px' : '50px')};
  gap: 0 20px;
  border-bottom: 1px solid #eaebee;
`;

export const TabsHeaderItem = styled.div<TabsHeaderItemProps>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => (props.isActive ? Colors.dark : Colors.grey[100])};

  &:hover {
    cursor: pointer;
  }
`;

export const OwnerLogoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  background: #151968;
  border-radius: 32px;
  letter-spacing: -1px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: ${Colors.white};
`;
