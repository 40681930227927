import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { loggedOut } from '../slice/user/slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders(headers) {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const token = localStorage.getItem('refreshToken');

    if (!token) {
      return result;
    }

    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'GET',
        headers: {
          Authorization: String(token),
        },
      },
      api,
      extraOptions,
    );
    if (refreshResult.meta && refreshResult.error?.status !== 401) {
      localStorage.setItem(
        'accessToken',
        JSON.stringify(
          refreshResult.meta?.response?.headers.get('Access-Token'),
        ),
      );

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(loggedOut());
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      (window as Window).location.href = 'auth/login';
    }
  }
  return result;
};
