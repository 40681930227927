import styled from 'styled-components';
import { Colors } from '@/environment';

export const Wrapper = styled.header`
  min-height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  gap: 125px;
`;

export const TitleLogoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 4.57px;
  cursor: pointer;

  & > :last-child {
    margin-top: 3px;
    box-sizing: border-box;
  }
`;

export const MainTitle = styled.h1`
  color: ${Colors.logoBlack};
  font-size: 16px;
  height: 16px;
  font-weight: 700;
  z-index: 2;
  font-family: 'Golos Text', 'Inter', sans-serif;
`;
