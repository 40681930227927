import { createApi } from '@reduxjs/toolkit/query/react';
import {
  TAuthResponse,
  LoginRequest,
  TUserConfirmRequest,
} from '@/types/auth-types';

import { baseQueryWithReauth } from './query';
import {
  TConfirmNewPasswordRequest,
  TPublisherConfirmRequest,
  TPublisherRegisterRequest,
  TResetPasswordRequest,
} from '@/types/publisher-types';

type FetchBaseQueryMeta = { request: Request; response?: Response };

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation<TAuthResponse, Partial<LoginRequest>>({
      query: (data) => ({
        url: 'auth/login',
        method: 'POST',
        body: data,
      }),
      transformResponse(res: TAuthResponse, meta: FetchBaseQueryMeta) {
        const access = meta.response?.headers.get('Access-Token');
        const refresh = meta.response?.headers.get('Refresh-Token');

        if (access && refresh) {
          localStorage.setItem('accessToken', access);
          localStorage.setItem('refreshToken', refresh);
        }
        return res;
      },
    }),
    userConfirm: builder.mutation<
      void,
      Partial<{ token: string; data: TUserConfirmRequest }>
    >({
      query: ({ token, data }) => ({
        url: 'auth/confirm',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    publisherRegister: builder.mutation<
      unknown,
      Partial<{ data: TPublisherRegisterRequest }>
    >({
      query: ({ data }) => ({
        url: 'auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    publisherConfirm: builder.mutation<
      unknown,
      Partial<{ data: TPublisherConfirmRequest }>
    >({
      query: ({ data }) => ({
        url: 'auth/verify',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<
      unknown,
      Partial<{ data: TResetPasswordRequest }>
    >({
      query: ({ data }) => ({
        url: 'auth/reset',
        method: 'POST',
        body: data,
      }),
    }),
    confirmNewPassword: builder.mutation<
      unknown,
      Partial<{ token: string; data: TConfirmNewPasswordRequest }>
    >({
      query: ({ token, data }) => ({
        url: 'auth/reset',
        method: 'PATCH',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    resendCode: builder.mutation<unknown, { email: string }>({
      query: (data) => ({
        url: 'auth/resend-code',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useUserConfirmMutation,
  usePublisherRegisterMutation,
  usePublisherConfirmMutation,
  useResetPasswordMutation,
  useConfirmNewPasswordMutation,
  useResendCodeMutation,
} = authApi;
