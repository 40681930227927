import { Colors } from '@/environment';
import styled from 'styled-components';

export const TermsOfConditionStyled = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;

  & .styled-checkbox {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    font-family: 'Noto Sans', 'Inter', sans-serif;
    color: ${Colors.text.bold};

    .ant-checkbox-inner {
      border-color: ${Colors.newCheckboxColor} !important;
      background-color: ${Colors.white};
    }

    & p {
      color: ${Colors.text.bold};
      font-weight: 400;
      font-size: 10px;
      font-family: 'Inter', sans-serif;
      text-decoration: underline;
      margin: 0 0 0 3px;
      padding: 0;
    }

    & > .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${Colors.newCheckboxColor} !important;
      border-color: ${Colors.newCheckboxColor} !important;

      &:hover,
      &:focus {
        background-color: ${Colors.newCheckboxColor} !important;
        border-color: ${Colors.newCheckboxColor} !important;
      }
    }

    & > .ant-checkbox-checked::after {
      display: none;
    }
  }
`;

export const CheckboxContent = styled.span`
  display: flex;
  align-items: center;
  height: 20px;
`;
