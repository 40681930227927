import React, { memo, useEffect } from 'react';
import { Wrapper } from './ChoosePlayerStep.styled';
import { playersInfo } from '@/consts';
import { PlayerCard } from './components/PlayerCard';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

interface ChooseOptionsStepProps {
  setPlayerType: (value: string) => () => void;
  changeStep: (step: number) => () => void;
}

export const ChoosePlayerStep: React.FC<ChooseOptionsStepProps> = memo(
  ({ changeStep, setPlayerType }) => {
    const { setBreadcrumbs } = useBreadcrumbs();

    useEffect(() => {
      const breadcrumbsData = [
        {
          label: 'Manage Players',
        },
        {
          label: 'Create a Player',
        },
      ];

      setBreadcrumbs(breadcrumbsData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Wrapper>
        {playersInfo.map((playerInfo) => (
          <PlayerCard
            key={playerInfo.title}
            changeStep={changeStep(2)}
            setPlayerType={setPlayerType(playerInfo.value)}
            playerInfo={playerInfo}
          />
        ))}
      </Wrapper>
    );
  },
);

ChoosePlayerStep.displayName = 'ChoosePlayerStep';
