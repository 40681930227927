import * as Yup from 'yup';

interface IForgotPasswordForm {
  email: string;
}

const forgotPasswordFormShema: Yup.SchemaOf<IForgotPasswordForm> = Yup.object({
  email: Yup.string()
    .email('Wrong email address')
    .required('Email is required field'),
});

export default forgotPasswordFormShema;
