import styled from 'styled-components';
import { Colors } from '@/environment';

export const FormWrapper = styled.div`
  margin-top: 30px;
  box-shadow: 0px 8px 24px 3px #06479f29;
  background: #ffffff;

  box-sizing: border-box;
  border-radius: 32px 32px 0px 0px;
  max-width: 400px;
  padding-top: 40px;
  height: calc(100% - 110px);
`;

export const ScrollWrapper = styled.div`
  width: 99%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: auto;
  scrollbar-color: rgba(161, 161, 170, 0.1) transparent;
  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }
`;

export const StatusMessageStyled = styled.div.attrs(
  (props: { color: string; fontWeight?: string }) => ({
    ...props,
  }),
)`
  color: ${({ color }) => color};
  margin-top: 20px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 0 16px;
`;
