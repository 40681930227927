import { useEffect, useState } from 'react';
import {
  Wrapper,
  StyledSelect,
  RangePickerWrap,
  StyledDivider,
  SelectFooter,
  ChoosedOption,
} from './SelectWithRangePicker.styled';
import { RangePicker } from '../../common/RangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Icon } from '../../common/Icon';
import { IconType } from '@/consts';
import { Colors } from '@/environment';

import { generateSortDates } from '@/helpers/generateSortDates';
import { Nullable } from '@/types/generic';
import moment from 'moment';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { Option } = StyledSelect;

type ISelectValues =
  'all_time'
  | 'today'
  | 'yesterday'
  | 'this_week'
  | 'last_week'
  | 'this_month'
  | 'last_month'
  | 'this_year'
  | 'custom';
export interface ITimeSort {
  value: ISelectValues,
  dates: Nullable<string>[],
}

type TProps = {
  setTimeSort?: (time: ITimeSort) => void;
  dates?: ITimeSort
};

const convertTime = (el: Nullable<string>) => {
  if (!el) return null
  return dayjs(el)
}

export const SelectWithRangePicker = ({ setTimeSort, dates: propsDate }: TProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<ISelectValues>('today');
  const [dates, setDates] = useState<RangeValue>(null);

  useEffect(() => {
    if (!propsDate) return
    setSelectValue(propsDate.value)
    if (propsDate.value === 'custom') {
      setDates([convertTime(propsDate.dates[0]), convertTime(propsDate.dates[1])])
      return
    }
    const [leftTime, rightTime] = generateSortDates(propsDate.value);
    const normalizedTime: RangeValue = [convertTime(leftTime), convertTime(rightTime)]
    setDates(normalizedTime)
  }, [])
  const options = [
    {
      label: 'All time',
      value: 'all_time',
    },
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
    },
    {
      label: 'This week',
      value: 'this_week',
    },
    {
      label: 'Last week',
      value: 'last_week',
    },
    {
      label: 'This month',
      value: 'this_month',
    },
    {
      label: 'Last month',
      value: 'last_month',
    },
    {
      label: 'This year',
      value: 'this_year',
    },
    {
      label: `${dates && dayjs(dates[0]).format('DD/MM/YYYY')} - ${dates && dayjs(dates[1]).format('DD/MM/YYYY')
        }`,
      value: 'custom',
    },
  ];

  // Установка из календаря
  function handleRangePickerChange(customaDates: RangeValue) {
    setDates(customaDates);
    setSelectValue('custom');
    if (!customaDates || !setTimeSort) return
    customaDates[0]?.toISOString() === customaDates[1]?.toISOString()
      ? handlePrepareSameDates()
      : setTimeSort({
        value: 'custom',
        dates: customaDates.map((date, index) => {
          if (!date) {
            return null;
          }
          if (index === 0) {
            return date && date.toISOString();
          } else {
            const check = moment(date.toISOString()).isSame(
              moment().toISOString(),
              'd',
            );
            return check
              ? moment().toISOString()
              : moment(date.toISOString()).endOf('day').toISOString();
          }
        }),
      }

      );

  }

  function handleSetOpen(open: boolean) {
    return () => {
      setIsOpen(open);
    };
  }
  function handleSelectChange(value: unknown) {
    setSelectValue(value as ISelectValues);
    setTimeSort && setTimeSort(
      { value: value as ISelectValues, dates: generateSortDates(value as string) }
    )
  }

  function handlePrepareSameDates() {
    if (dates && dates[1] && dates[0] && setTimeSort) {
      const check = moment(dates[1].toISOString()).isSame(
        moment().toISOString(),
        'd',
      );

      return setTimeSort({
        value: 'custom',
        dates: [
          moment(dates[0].toISOString()).startOf('day').toISOString(),
          !check
            ? moment(dates[1].toISOString()).endOf('day').toISOString()
            : moment().toISOString(),
        ]
      });
    }
    return [];
  }

  function handleDropdownRender(
    // !ANY TYPES FROM DOCS
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  ) {
    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {menu}
        <SelectFooter>
          <StyledDivider />
          <RangePickerWrap>
            <RangePicker
              onChange={handleRangePickerChange}
              isOpen={isOpen}
              dates={dates}
              selectValue={selectValue}
            />
          </RangePickerWrap>
        </SelectFooter>
      </div>
    );
  }

  useEffect(() => {
    if (
      selectValue === 'custom' &&
      dates?.length === 2 &&
      dates[0] !== null &&
      dates[1] !== null
    ) {
      setIsOpen(false);
    }

    if (selectValue !== 'custom') {
      setIsOpen(false);
    }
  }, [dates, selectValue]);

  return (
    <Wrapper id="select-with-rangepicker">
      <StyledSelect
        open={isOpen}
        value={selectValue}
        onClick={handleSetOpen(true)}
        defaultValue={'today'}
        bordered={false}
        optionLabelProp="label"
        suffixIcon={
          <Icon name={IconType.TYDropdownArrow} color={Colors.text.main} />
        }
        dropdownStyle={{
          width: 200,
        }}
        onChange={handleSelectChange}
        popupClassName={'select'}
        onDropdownVisibleChange={(visible) => setIsOpen(visible)}
        getPopupContainer={() =>
          document.getElementById('select-with-rangepicker') as HTMLElement
        }
        placeholder={
          <>
            <Icon name={IconType.TYClock} color={Colors.blue} />
          </>
        }
        dropdownRender={handleDropdownRender}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            className={`option ${option.value === 'custom' || option.value !== 'custom'
              ? ''
              : 'option-hidden'
              }`}
            label={
              <>
                <Icon name={IconType.TYClock} />
                {option.label}
              </>
            }
          >
            <ChoosedOption>
              {option.label}
              {option.value === selectValue && (
                <Icon
                  name={IconType.TYTimeSortCheck}
                  iconHeight={8}
                  iconWidth={10}
                  color={Colors.blue}
                />
              )}
            </ChoosedOption>
          </Option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};
