import { IconType } from '@/consts';
import { Icons } from '@/environment';
import { memo } from 'react';
import { IconWrapper } from './Icon.styled';

interface IconProps {
  name: string;
  color?: string;
  iconWidth?: number;
  iconHeight?: number;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
}

export const Icon: React.FC<IconProps> = memo(
  ({
    name,
    color,
    iconWidth,
    iconHeight,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let IconView: any;

    switch (name) {
      case IconType.TYArrowDown:
        IconView = Icons.TYArrowDown;
        break;
      case IconType.TYArrowUp:
        IconView = Icons.TYArrowUp;
        break;
      case IconType.TYClock:
        IconView = Icons.TYClock;
        break;
      case IconType.TYDashboardIcon:
        IconView = Icons.TYDashboardIcon;
        break;
      case IconType.TYDropdownArrow:
        IconView = Icons.TYDropdownArrow;
        break;
      case IconType.TYDropdownDots:
        IconView = Icons.TYDropdownDots;
        break;
      case IconType.TYFilterIcon:
        IconView = Icons.TYFilterIcon;
        break;
      case IconType.TYMainLogoIcon:
        IconView = Icons.TYMainLogoIcon;
        break;
      case IconType.TYManagePlayersIcon:
        IconView = Icons.TYManagePlayersIcon;
        break;
      case IconType.TYManageUsersIcon:
        IconView = Icons.TYManageUsersIcon;
        break;
      case IconType.TYPlusIcon:
        IconView = Icons.TYPlusIcon;
        break;
      case IconType.TYSearchIcon:
        IconView = Icons.TYSearchIcon;
        break;
      case IconType.TYSettingsIcon:
        IconView = Icons.TYSettingsIcon;
        break;
      case IconType.TYUserIcon:
        IconView = Icons.TYUserIcon;
        break;
      case IconType.TYPlayerBack:
        IconView = Icons.TYPlayerBack;
        break;
      case IconType.TYPlayerLoading:
        IconView = Icons.TYPlayerLoading;
        break;
      case IconType.TYPlayerPause:
        IconView = Icons.TYPlayerPause;
        break;
      case IconType.TYPlayerForward:
        IconView = Icons.TYPlayerForward;
        break;
      case IconType.TYPlayerVolume:
        IconView = Icons.TYPlayerVolume;
        break;
      case IconType.TYPlayerPlayback:
        IconView = Icons.TYPlayerPlayback;
        break;
      case IconType.TYPlayerPreferences:
        IconView = Icons.TYPlayerPreferences;
        break;
      case IconType.TYSelectIcon:
        IconView = Icons.TYSelectIcon;
        break;
      case IconType.TYNewUserIcon:
        IconView = Icons.TYNewUserIcon;
        break;
      case IconType.TYCopyIcon:
        IconView = Icons.TYCopyIcon;
        break;
      case IconType.TYCopiedIcon:
        IconView = Icons.TYCopiedIcon;
        break;
      case IconType.TYUserListIcon:
        IconView = Icons.TYUserListIcon;
        break;
      case IconType.TYEmailMathesIcon:
        IconView = Icons.TYEmailMathesIcon;
        break;
      case IconType.TYShowPasswordIcon:
        IconView = Icons.TYShowPasswordIcon;
        break;
      case IconType.TYTableDropdownIcon:
        IconView = Icons.TYTableDropdownIcon;
        break;
      case IconType.TYTableDropdownPause:
        IconView = Icons.TYTableDropdownPause;
        break;
      case IconType.TYTableDropdownPlay:
        IconView = Icons.TYTableDropdownPlay;
        break;
      case IconType.TYPublisherArrowDown:
        IconView = Icons.TYPublisherArrowDown;
        break;
      case IconType.TYPublisherArrowUp:
        IconView = Icons.TYPublisherArrowUp;
        break;
      case IconType.TYTableStaticType:
        IconView = Icons.TYTableStaticType;
        break;
      case IconType.TYTableDynamicType:
        IconView = Icons.TYTableDynamicType;
        break;
      case IconType.TYManagePublishers:
        IconView = Icons.TYManagePublishers;
        break;
      case IconType.TYTimeSortCheck:
        IconView = Icons.TYTimeSortCheck;
        break;
      case IconType.TYCloseXMark:
        IconView = Icons.TYCloseXMark;
        break;
      case IconType.TYCalendar:
        IconView = Icons.TYCalendar;
        break;
      case IconType.TYPlayerActivateMask:
        IconView = Icons.TYPlayerActivateMask;
        break;
      case IconType.TYPlayerDynamicClose:
        IconView = Icons.TYPlayerDynamicClose;
        break;
      case IconType.TYPlayerDynamicPreferences:
        IconView = Icons.TYPlayerDynamicPreferences;
        break;
      case IconType.TYNotificationCheck:
        IconView = Icons.TYNotificationCheck;
        break;
      case IconType.TYPasswordSuffix:
        IconView = Icons.TYPasswordSuffix;
        break;
      case IconType.TYQustion:
        IconView = Icons.TYQustion;
        break;
      case IconType.TYQustionFilled:
        IconView = Icons.TYQustionFilled;
        break;
      case IconType.TYTutorialsIcon:
        IconView = Icons.TYTutorialsIcon;
        break;
      case IconType.TYRevenueIcon:
        IconView = Icons.TYRevenueIcon;
        break;
      case IconType.TYDownloadIcon:
        IconView = Icons.TYDownloadIcon;
        break;

      default:
        IconView = Icons.TYArrowDown;
    }

    return (
      <IconWrapper
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
      >
        <IconView color={color} iconWidth={iconWidth} iconHeight={iconHeight} />
      </IconWrapper>
    );
  },
);

Icon.displayName = 'Icon';
