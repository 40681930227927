import { Icon, LoaderSpin } from '@/components/common';
import { EPlayerStatus, IconType } from '@/consts';
import { Colors } from '@/environment';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';
import { RootState } from '@/redux';
import { useUpdatePlayerMutation } from '@/redux/api/playersApi';
import { updatePlayer } from '@/redux/slice/players/slice';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BtnsWrap,
  EditBtn,
  StatusBtn,
  WrapperStyled,
  ToggleAdsBtn
} from './RowPopover.styled';

interface RowPopoverProps {
  playerId: string;
}

export const RowPopover: React.FC<RowPopoverProps> = ({ playerId }) => {
  const { players, viewAsPublisherId, currentPublisher } = useSelector((store: RootState) => ({
    players: store.players.data,
    viewAsPublisherId: store.user.viewAsPublisherId,
    currentPublisher: store.publishers.currentPublisher,

  }));

  const [AdStatusLoading, setAdStatusLoading] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updatePlayerStatus, { data: updateData, isLoading: updateLoading }] =
    useUpdatePlayerMutation();

  const player = players.find((player) => player.id === playerId);

  const currentPlayerStatus = useMemo(() => {
    if (player && playerId && !updateData) {
      return player.status === 'active';
    } else if (player && playerId && updateData) {
      return updateData.status === 'active';
    }
    return false;
  }, [player, playerId, updateData]);

  const currentPlayerAdStatus = useMemo(() => {
    if (player) {
      return player.isAdAllowed
    }
    return false;
  }, [player]);

  const iconByLoading = useMemo(() => {
    if (!updateLoading || AdStatusLoading) {
      return (
        <Icon
          name={
            currentPlayerStatus
              ? IconType.TYTableDropdownPause
              : IconType.TYTableDropdownPlay
          }
        />
      );
    }
    return (
      <LoaderSpin
        size={10}
        color={
          currentPlayerStatus ? Colors.saturatedYellow : Colors.saturatedGreen
        }
      />
    );
  }, [currentPlayerStatus, updateLoading, AdStatusLoading]);

  const handleUpdateStatus = (value: boolean) => {
    return () => {
      updatePlayerStatus({
        data: {
          status: !value ? 'inactive' : 'active',
        },
        playerId,
      })
        .unwrap()
        .then((res) => dispatch(updatePlayer(res)));
    };
  };

  const handleUpdateAdStatus = (value: boolean) => {
    return () => {
      setAdStatusLoading(true)
      updatePlayerStatus({
        data: {
          isAdAllowed: value
        },
        playerId,
      })
        .unwrap()
        .then((res) => dispatch(updatePlayer(res)))
        .finally(() => setAdStatusLoading(false)
        );
    };
  };

  const handleNavigate = () => {
    player?.status === EPlayerStatus.Archived
      ? navigate(`${playerId}/view-details`)
      : navigate(`edit/${playerId}`);
  };

  return (
    <WrapperStyled>
      <BtnsWrap isDeleted={player?.status === EPlayerStatus.Archived}>
        <EditBtn onClick={handleNavigate}>
          {checkLengthIsPositive(viewAsPublisherId) ||
            player?.status === EPlayerStatus.Archived
            ? 'View'
            : 'Edit'}
        </EditBtn>
        {player?.status !== EPlayerStatus.Archived && (
          <StatusBtn
            status={currentPlayerStatus}
            disabled={checkLengthIsPositive(viewAsPublisherId)}
            onClick={handleUpdateStatus(!currentPlayerStatus)}
          >
            {currentPlayerStatus ? 'Pause' : 'Activate'}
            {iconByLoading}
          </StatusBtn>
        )}
        {currentPublisher?.isMonetizationAllowed && <ToggleAdsBtn
          disabled={checkLengthIsPositive(viewAsPublisherId)}
          isAddActive={currentPlayerAdStatus}
          onClick={handleUpdateAdStatus(!currentPlayerAdStatus)}
        >
          {currentPlayerAdStatus ? 'Turn Off Ads' : 'Turn On Ads'}
          {AdStatusLoading && <LoaderSpin
            size={10}
            color={
              currentPlayerStatus ? Colors.saturatedYellow : Colors.saturatedGreen
            }
          />}

        </ToggleAdsBtn>
        }
      </BtnsWrap>
    </WrapperStyled>
  );
};
