import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { Colors } from '@/environment';

export const StyledBaseFormInput = styled(Input).attrs(
  (props: { color: string }) => ({
    ...props,
  }),
)`
  &.styled-input {
    background: ${(props) => props.color};
    border-radius: 16px;
    border-color: transparent;
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.dark};
    font-family: 'Inter', sans-serif;
    padding: 4px 17px;

    ${({ color, disabled }) =>
      color === Colors.white &&
      !disabled &&
      css`
        box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
      `}
  }
  &.ant-input-disabled {
    cursor: initial;
  }
`;

export const ErrorMessageFormInput = styled.div`
  color: ${Colors.error};
  margin: 8px 0 0 8px;
  font-family: 'Inter', sans-serif;
`;
