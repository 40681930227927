import { FormikErrors } from 'formik';
import { memo } from 'react';
import { ErrorStyled, ErrorsWrapper } from './FormErrors.styled';

interface IFormErrorsProps {
  errors: FormikErrors<Record<string, string>>;
  gapTop?: number;
  gapBottom?: number;
  fontSize?: number;
}

export const FormErrors: React.FC<IFormErrorsProps> = memo((props) => {
  const { errors, gapTop, gapBottom, fontSize } = props;
  const errorList = Object.values(errors);

  if (!errorList.length) {
    return null;
  }

  return (
    <ErrorsWrapper gapTop={gapTop} gapBottom={gapBottom}>
      {errorList.map((error, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ErrorStyled key={index} fontSize={fontSize}>
            {error}
          </ErrorStyled>
        );
      })}
    </ErrorsWrapper>
  );
});

FormErrors.displayName = 'FormErrors';
