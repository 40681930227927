import sanityClient from '@sanity/client';

const client = sanityClient({
    projectId: '68iijm42',
    dataset: 'production',
    token: 'skbPzvibaybzSo3agAz2FouJP8PaGeFOj8HwG0xeHnJExVvimTk8MSJm79dsELwdtBRTbnZDNmCuGMXrT20Frhu5ZhDsmOZLUbbArPM9ddvfZbWEmoWJlo6iFpzTyUMdo7aBGmfd1qg2eEtnfL7REWHGQ9yulExTzvWvXsO75I3s25UUGCTj',
    useCdn: true, // `false` if you want to ensure fresh data
    ignoreBrowserTokenWarning: true,
});

export default client;