import {
  AuthInput,
  Button,
  FormErrors,
  Icon,
  StatusMessage,
} from '@/components/common';
import { Colors } from '@/environment';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import { useResetPasswordMutation } from '@/redux/api/authApi';
import { Nullable } from '@/types/generic';
import { useFormik } from 'formik';
import { FormEvent, useState } from 'react';
import {
  BackToSignInLink,
  EmailInfo,
  ForgotPasswordFormWrapper,
  IconWrapper,
  SuccessBlock,
} from './ForgotPasswordForm.styled';
import forgotPasswordFormShema from './validation';
import { IconType } from '@/consts';
import { EAuthRoutes } from '@/routes';

export const ForgotPasswordForm = () => {
  const [successMessage, setSuccessMessage] = useState<Nullable<string>>(null);
  const [textError, setTextError] = useState<Nullable<string>>(null);
  const [resetPassword] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordFormShema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      resetPassword({ data: { email: values.email } })
        .unwrap()
        .then(() => {
          setSuccessMessage('Recovery mail was sent to:');
          setTextError('');
        })
        .catch(() => {
          setTextError('Incorrect email or password');
        });
    },
  });

  const { values, handleChange, submitForm, errors } = formik;

  const isFilledRequiredFields = checkFormFieldsFilled(values);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitForm();
    setTextError('');
  }

  const isValidEmail = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    values.email,
  );

  return (
    <ForgotPasswordFormWrapper
      autoComplete="nofill"
      onSubmit={handleSubmit}
      successMessage={!!successMessage}
    >
      {successMessage && (
        <SuccessBlock>
          <IconWrapper id="icon-wrapper">
            <Icon
              name={IconType.TYTimeSortCheck}
              iconWidth={15}
              iconHeight={11}
              color={Colors.green}
            />
          </IconWrapper>
          <span>Recovery mail was sent to:</span>
          <EmailInfo id="email-info">{values.email}</EmailInfo>
        </SuccessBlock>
      )}

      {!successMessage && (
        <StatusMessage
          color={Colors.boldGreen}
          size={14}
          className="info-status"
          text={
            'Please enter your legitimate Email address. And we’ll sent a password recovery link to this address.'
          }
        />
      )}

      {!successMessage && (
        <AuthInput
          value={values.email}
          onChange={handleChange}
          type="email"
          id="email"
          placeholder="Email address"
          isError={
            (errors.email && 'error') ||
            (textError !== null && textError.length > 0 ? 'error' : undefined)
          }
          autoComplete="nofill"
          maxLength={255}
          style={{ marginBottom: 25 }}
        />
      )}

      {!successMessage && (
        <Button
          text="Sent"
          position="center"
          type="submit"
          borderRadius="4px"
          backgroundColor={Colors.darkBlue}
          disabledColor={Colors.newBtnDisabled}
          disabled={!isFilledRequiredFields || !!successMessage || isValidEmail}
          height="40px"
        />
      )}

      {successMessage && (
        <BackToSignInLink to={`../${EAuthRoutes.Login}`} replace>
          Go back to Sign in
        </BackToSignInLink>
      )}

      {textError && <StatusMessage color={'red'} text={textError} />}

      <FormErrors errors={errors} />
    </ForgotPasswordFormWrapper>
  );
};
