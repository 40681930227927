import { memo, useEffect, useMemo, useState } from 'react';
import { AuthInput, Icon } from '@/components/common';
import { IconType } from '@/consts';
import { Colors } from '@/environment';

interface EmailGroupProps {
  email: string;
  reEnterEmail: string;
  emailError?: string;
  reEnterEmailError?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EmailGroup: React.FC<EmailGroupProps> = memo((props) => {
  const { email, reEnterEmail, emailError, reEnterEmailError } = props;
  const { handleChange } = props;
  const [isEmailsMatches, setIsEmailsMatches] = useState(false);

  const suffixIcon = useMemo(() => {
    if (isEmailsMatches) {
      return <Icon name={IconType.TYEmailMathesIcon} color={Colors.green} />;
    }
    return <span />;
  }, [isEmailsMatches]);

  useEffect(() => {
    if (email === reEnterEmail && email && reEnterEmail) {
      setIsEmailsMatches(true);
    } else {
      setIsEmailsMatches(false);
    }
  }, [email, reEnterEmail]);

  return (
    <>
      <AuthInput
        value={email}
        onChange={handleChange}
        type="text"
        id="email"
        placeholder="Email address*"
        status={emailError && 'error'}
        suffix={suffixIcon}
      />

      <AuthInput
        value={reEnterEmail}
        onChange={handleChange}
        type="text"
        id="reEnterEmail"
        placeholder="Re-enter Email address*"
        status={reEnterEmailError && 'error'}
        suffix={suffixIcon}
      />
    </>
  );
});

EmailGroup.displayName = 'EmailGroup';
