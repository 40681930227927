import { Colors } from '@/environment';
import { Input } from 'antd';
import styled from 'styled-components';

export const AuthGroupInputStyled = styled(Input.Group)`
  display: flex;

  & > input:first-child {
    border-right: 1px solid ${Colors.white};
  }

  & > input:last-child {
    border-left: 1px solid ${Colors.white};
  }

  & input:focus {
    border-color: ${Colors.authInputFocus};
  }
`;
