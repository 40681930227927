import { InfoItem } from '@/components/common';
import { RootState } from '@/redux';
import { Nullable } from '@/types/generic';
import { IUser } from '@/types/users-types';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { UserInfoWrapper } from './UserInfo.styled';

interface IUserInfoProps {
  selectedUser: Nullable<IUser>;
}

export const UserInfo: React.FC<IUserInfoProps> = (props) => {
  const { selectedUser } = props;

  const { publishers } = useSelector((store: RootState) => ({
    publishers: store.publishers.data,
  }));

  const companyURL = publishers.find(
    (publisher) => publisher.id === selectedUser?.publisherId,
  )?.website;

  return (
    <UserInfoWrapper>
      <InfoItem header="Email address" data={selectedUser?.email} />
      <InfoItem header="Role" data={selectedUser?.role} className="role" />
      <InfoItem
        header="Sign up date"
        data={
          selectedUser && selectedUser.invitedAt
            ? format(new Date(selectedUser.invitedAt), 'dd/MM/yyyy')
            : ''
        }
      />
      <InfoItem header="Contact phone number" data={selectedUser?.phone} />
      <InfoItem header="Company URL" data={companyURL} />
      <InfoItem header="Country" data="USA" />
    </UserInfoWrapper>
  );
};
