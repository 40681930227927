import { Colors } from '@/environment';
import styled from 'styled-components';

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;

export const Username = styled.div`
  color: ${Colors.text.main};
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
