import { IconType } from '@/consts';
import { Breadcrumbs, Icon } from '@/components/common';
import { MainTitle, TitleLogoBlock, Wrapper } from './Header.styled';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '@/routes';

export const Header = memo(() => {
  const navigate = useNavigate();

  function navToRoot() {
    navigate(ERoutes.Root);
  }

  return (
    <Wrapper>
      <TitleLogoBlock onClick={navToRoot}>
        <MainTitle>TYLR</MainTitle>
        <Icon name={IconType.TYMainLogoIcon} />
      </TitleLogoBlock>
      <Breadcrumbs />
    </Wrapper>
  );
});

Header.displayName = 'Header';
