import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { Colors } from '../../../environment';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 7px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  border-radius: 100px;
  color: ${Colors.text.main};
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;

  &.active {
    color: ${Colors.blue};
    border: 1px solid ${Colors.borders.navLink};
    background-color: ${Colors.white};
    box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);

    svg {
      fill: ${Colors.blue};
      path {
        fill: ${Colors.blue};
      }
    }
  }
`;
