
import { useFormik } from 'formik';
import { FormWrapper, StatusMessageStyled, ScrollWrapper } from './BilingFormPublisher.styled'

import { RootState } from '@/redux';
import { useSelector, useDispatch } from 'react-redux';
import { basePublisherBillingInfo } from '@/consts'
import BILLING_VALIDATION_SCHEMA from './schema'
import { IPublisherBillingInfoForm } from '../BilingFormBase';

import {
  useCreateBillingInfoMutation,
  useUpdateBillingInfoMutation
} from '@/redux/api/publishersApi';

import {
  updateCurrentPublisherBillingInfo
} from '@/redux/slice/publishers/slice';

import { useEffect, useState } from 'react';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import { BilingFormBase } from '../BilingFormBase';
import { Button, FormErrors } from '@/components/common';
import omit from 'lodash/omit';
import { Colors } from '@/environment';

export const BilingFormPublisher: React.FC = () => {
  const { currentPublisher } = useSelector(
    (store: RootState) => ({
      currentPublisher: store.publishers.currentPublisher,
    }),
  );
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false)
  const [createBilling, { isLoading: isLoadingCreate }] = useCreateBillingInfoMutation();
  const [updateBilling, { isLoading: isLoadingUpdate }] = useUpdateBillingInfoMutation();
  const dispatch = useDispatch();

  const formik = useFormik<IPublisherBillingInfoForm>({
    initialValues: basePublisherBillingInfo,
    validationSchema: BILLING_VALIDATION_SCHEMA,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!currentPublisher) {
        throw new Error('No currentPublisher')
      }
      setIsSuccessUpdate(false)

      if (currentPublisher.publishersBillingInfo) {
        const billingInfoId = currentPublisher.publishersBillingInfo.id
        updateBilling({
          data: values,
          publisherId: currentPublisher.id,
          billingInfoId,

        })
          .unwrap()
          .then((res) => {
            setIsSuccessUpdate(true)
            dispatch(updateCurrentPublisherBillingInfo(res));

          })
          .catch((errors) => {
            throw new Error(errors)
          });
        return
      }
      createBilling({ data: values, publisherId: currentPublisher.id })
        .unwrap()
        .then((res) => {
          setIsSuccessUpdate(true)
          dispatch(updateCurrentPublisherBillingInfo(res));

        })
        .catch((errors) => {
          throw new Error(errors)

        });
    },
  });

  const { values, setFieldValue, handleSubmit, setValues, errors: formValidationErrors } = formik;

  useEffect(() => {
    if (currentPublisher?.publishersBillingInfo) {
      setValues(
        omit(currentPublisher.publishersBillingInfo, ['deletedAt', 'id', 'publisherId'])
      )
    }
  }, [currentPublisher?.publishersBillingInfo, setValues]);

  const isFilledRequiredFields = checkFormFieldsFilled(omit(values, ['zip', 'state', 'updatedAt']));

  useEffect(() => {
    if (formValidationErrors) {
      setIsSuccessUpdate(false);
    }
  }, [formValidationErrors]);

  return (
    <FormWrapper>
      <ScrollWrapper>
        <div style={{ padding: '40px', paddingTop: '0px' }}>
          <BilingFormBase values={values} setFieldValue={setFieldValue} formValidationErrors={formValidationErrors} footer={
            <Button
              text={'Save'}
              type="primary"
              position="center"
              borderRadius="12px"
              disabled={
                !isFilledRequiredFields
              }
              onClick={handleSubmit}
              disabledColor={Colors.grey[100]}
              backgroundColor={Colors.blue}
              loading={isLoadingCreate || isLoadingUpdate}
            />
          }></BilingFormBase>

          {isSuccessUpdate && (
            <StatusMessageStyled color={Colors.green} fontWeight="400">
              Data successfully updated.
            </StatusMessageStyled>
          )}
          <FormErrors errors={formValidationErrors} gapTop={20} fontSize={12} />
        </div>

      </ScrollWrapper>
    </FormWrapper >
  )
}