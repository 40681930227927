import { EUserRole } from '@/consts';
import { Colors } from '@/environment';

function getUserIconColor(userRole: EUserRole) {
  if (userRole === EUserRole.Admin) {
    return Colors.error;
  }

  if (userRole === EUserRole.Editor) {
    return Colors.icon.yellow;
  }

  return Colors.blue;
}

export default getUserIconColor;
