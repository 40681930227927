import { memo } from 'react';
import { Message } from './StatusMessage.styled';

interface IStatusMessageProps {
  color: string;
  text: string;
  size?: number;
  className?: string;
}

export const StatusMessage: React.FC<IStatusMessageProps> = memo(
  ({ color, text, size = 10, className = '' }) => {
    return (
      <Message color={color} size={size} className={`message ${className}`}>
        {text}
      </Message>
    );
  },
);

StatusMessage.displayName = 'StatusMessage';
