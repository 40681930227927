import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useBackPage = (): (() => void) => {
  const navigate = useNavigate();

  const onBackPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return onBackPage;
};

export default useBackPage;
