import { Colors } from '@/environment';
import { Button } from 'antd';
import styled from 'styled-components';

export const LinkButtonStyled = styled(Button)`
  color: ${Colors.blue};
  padding: 0px;
  display: flex;
  align-items: center;
  height: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
`;
