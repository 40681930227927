import styled, { css } from 'styled-components';
import { PlayerSize } from '.';
import { playerActiveOptions, playerHovers } from '@/consts';
import { Colors } from '@/environment';

export const Wrapper = styled.div.attrs(
  (props: {
    color: string;
    size: string;
    preview?: string;
    playerType: string;
  }) => ({
    ...props,
  }),
)`
  background-color: #f5f5f7;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 4px;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11px;
  box-sizing: border-box;

  ${({ size }) =>
    size === PlayerSize.LG &&
    css`
      left: 62px;
      bottom: 35px;
      width: 320px;
      height: 42px;
    `}

  ${({ size }) =>
    size === PlayerSize.MD &&
    css`
      left: 100px;
      bottom: 35px;
      width: 240px;
      height: 42px;
    `}

  ${({ size }) =>
    size === PlayerSize.SM &&
    css`
      left: 52%;
      bottom: 10px;
      transform: translateX(-50%);
      width: 200px;
      height: 48px;
      justify-content: flex-start;
      padding: 0px 17px 0px 6px;
    `}

    ${({ size }) =>
    size === PlayerSize.DYNAMIC &&
    css`
      flex-direction: column;
      padding: 4px 7px;
      border-radius: 0px;
    `}

    ${({ size }) =>
    size === PlayerSize.DYNAMICSM &&
    css`
      flex-direction: column;
      padding: 8px 13px;
      height: 76px;
      width: 220px;
      border-radius: 0px;
      left: 51.5%;
      transform: translateX(-50%);
    `}
`;

export const PlayerInput = styled.input.attrs(
  (props: {
    color: string;
    playerType: string;
    isVolume: boolean;
    hexColor: string;
  }) => ({
    ...props,
  }),
)`
  appearance: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  height: ${({ isVolume }) => (isVolume ? '2px' : '2.5px')};
  pointer-events: none;

  ${({ color, isVolume, hexColor }) =>
    isVolume
      ? css`
          width: 30px;
          background: linear-gradient(
            to right,
            ${color === 'hex' ? hexColor : color} 0%,
            ${color === 'hex' ? hexColor : color} 75%,
            ${color === 'hex'
                ? `${hexColor}${hexColor.length > 4 ? 30 : 3}`
                : playerHovers[color]}
              75%,
            ${color === 'hex'
                ? `${hexColor}${hexColor.length > 4 ? 30 : 3}`
                : playerHovers[color]}
              100%
          );

          &::-webkit-slider-thumb {
            appearance: none;
            height: 100%;
            width: 4px;
            cursor: pointer;
          }
        `
      : css`
          width: 100%;
          background: linear-gradient(
            to right,
            ${color === 'hex' ? hexColor : color} 0%,
            ${color === 'hex' ? hexColor : color} 50%,
            ${color === 'hex'
                ? `${hexColor}${hexColor.length > 4 ? 30 : 3}`
                : playerHovers[color]}
              50%,
            ${color === 'hex'
                ? `${hexColor}${hexColor.length > 4 ? 30 : 3}`
                : playerHovers[color]}
              100%
          );

          /* webkit browsers */
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: ${color === 'hex' ? hexColor : color};
            overflow: visible;
            cursor: pointer;
          }
          /* Firefox */

          &::-moz-range-thumb {
            -moz-appearance: none;
            appearance: none;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: ${color === 'hex' ? hexColor : color};
            overflow: visible;
            cursor: pointer;
          }

          &::-moz-focus-outer {
            border: 0;
          }
        `}
`;

export const PauseIconWrap = styled.div.attrs(
  (props: {
    color: string;
    isDynamic: boolean;
    isDynamicSM: boolean;
    hexColor: string;
  }) => ({
    ...props,
  }),
)`
  min-height: 17px;
  min-width: 17px;
  max-height: 17px;
  max-width: 17px;
  background-color: ${({ color, hexColor }) =>
    color === 'hex' ? hexColor : color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;

  ${({ isDynamic }) =>
    isDynamic &&
    css`
      min-height: 16px;
      min-width: 16px;
      max-height: 16px;
      max-width: 16px;
    `}

  ${({ isDynamicSM }) =>
    isDynamicSM &&
    css`
      min-height: 27px;
      min-width: 27px;
      max-height: 27px;
      max-width: 27px;
    `}
`;

export const PlayerDescription = styled.div.attrs(
  (props: { isMD: boolean }) => ({
    ...props,
  }),
)`
  font-size: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  white-space: nowrap;
  padding: ${({ isMD }) => (!isMD ? '0px 11px' : '0px')};

  & > :last-child {
    font-size: 3px;

    span {
      color: ${Colors.grey[100]};
    }
  }
`;

export const SkipBtnsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0px 17px 0px 12px;
`;

export const PreferencesBtn = styled.div`
  padding-left: 14px;
`;

export const RangeInputWrap = styled.div.attrs(
  (props: { isMD: boolean; isDynamic: boolean; isDynamicSM: boolean }) => ({
    ...props,
  }),
)`
  display: flex;
  flex-direction: ${({ isDynamic, isDynamicSM }) =>
    isDynamic || isDynamicSM ? 'column' : 'column-reverse'};
  justify-content: space-between;
  gap: ${({ isMD }) => (!isMD ? '2px' : '3.5px')};
  width: 100%;

  ${({ isDynamic }) =>
    isDynamic &&
    css`
      gap: 0px;
    `}

  & > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${({ isDynamic }) => (isDynamic ? '3px' : '5px')};
    font-family: 'Inter';
    width: 100%;

    ${({ isDynamicSM }) =>
      isDynamicSM &&
      css`
        font-size: 7px;
      `}
  }
`;

export const DescriptionRangeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 6px;
  gap: 4px;
`;

export const OptionsWrapMD = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;

  & > :last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2px;
  }
`;

export const HideBtn = styled.div`
  position: absolute;
  right: 3.5px;
  top: 3.5px;
`;

export const MobilePreferencesBtn = styled.div`
  position: absolute;
  bottom: 11px;
  right: 8px;
`;

export const DynamicOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > :first-child {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & > :last-child {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 44px;
    gap: 11px;

    & > :first-child {
      padding-right: 6px;
    }

    & > :last-child {
      padding-left: 6px;
    }
  }
`;

export const Placeholder = styled.div.attrs(
  (props: { width: number; height: number }) => ({
    ...props,
  }),
)`
  background-color: transparent;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const DynamicDescription = styled.span`
  font-size: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 7px;

  & > :first-child {
    color: ${Colors.grey[100]};
  }
`;

export const DynamicSMOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;

  & > :first-child {
    padding-right: 5px;
  }

  & > :last-child {
    padding-left: 5px;
  }
`;

export const PauseWrap = styled.div.attrs(
  (props: {
    color: string;
    hexColor: string;
    isDynamic?: boolean;
    isDynamicSM?: boolean;
    isSM?: boolean;
  }) => ({
    ...props,
  }),
)`
  padding: 6px;
  background-color: ${({ color, hexColor }) =>
    color === 'hex'
      ? `${hexColor}${hexColor.length > 4 ? 30 : 3}`
      : playerActiveOptions[color]};

  margin-right: ${({ isSM }) => isSM && '6px'};
  border-radius: 50%;
  ${({ isDynamic }) =>
    isDynamic &&
    css`
      padding: 4px;
    `}

  ${({ isDynamicSM }) =>
    isDynamicSM &&
    css`
      padding: 9px;
    `}
`;
