import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  message: string;
  type: string;
}

const initialState: InitialState = {
  message: '',
  type: '',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<InitialState>) => ({
      ...state,
      message: payload.message,
      type: payload.type,
    }),
    removeNotification: (state) => ({
      ...state,
      message: '',
      type: '',
    }),
  },
});

export const { setNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
