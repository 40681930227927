import { configureStore } from "@reduxjs/toolkit";

import rootReducers, { apiMiddleware } from "./reducer";

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }).concat(apiMiddleware),
  ],
});

export type RootState = ReturnType<typeof store.getState>;
