import { Colors } from '@/environment';
import styled from 'styled-components';

export const PopoverContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(83, 154, 194, 0.1);
  border-radius: 16px;
  width: auto;
  max-width: 215px;
  box-sizing: border-box;
  padding-bottom: 10px;
`;

export const UserInfoWrapperStyled = styled.div`
  padding: 15px 20px 10px 20px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

export const ButtonWrapperStyled = styled.div`
  padding: 10px 20px 10px 20px;
  border-top: 1px solid rgba(83, 154, 194, 0.1);
  width: 100%;
  box-sizing: border-box;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 9px;
`;

export const Username = styled.div`
  color: ${Colors.grey[100]};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
