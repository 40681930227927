import { removeNotification } from '@/redux/slice/notifications/slice';
import { notification } from 'antd';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

export const useNotifications = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = (message: ReactNode, color: string) => {
    api.open({
      message: message,
      duration: 2,
      placement: 'topRight',
      btn: '',
      style: {
        width: 320,
        height: 36,
        background: color,
        padding: '12px 0px 12px 10px',
        display: 'flex',
        alignItems: 'center',
        margin: 0,
      },
      onClose: () => dispatch(removeNotification),
    });
  };

  return { contextHolder, openNotification };
};
