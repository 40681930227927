import { Dispatch, memo, SetStateAction, useState } from 'react';
import { Colors } from '@/environment';
import { Button } from '../Button';
import {
  ButtonsWrapper,
  Gradient,
  TextContent,
  TextWrapper,
  UpdatedInfo,
} from './TermsOfConditions.styled';
import { LoaderSpin } from '../LoaderSpin';

interface ITermsOfConditionsProps {
  setIsShowTerms: Dispatch<SetStateAction<boolean>>;
  setIsTermSelected: Dispatch<SetStateAction<boolean>>;
}

export const TermsOfConditions: React.FC<ITermsOfConditionsProps> = memo(
  ({ setIsShowTerms, setIsTermSelected }) => {
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const onAcceptTerms = () => {
      setIsShowTerms(false);
      setIsTermSelected(true);
    };

    const onDeclineTerms = () => {
      setIsShowTerms(false);
      setIsTermSelected(false);
    };

    return (
      <div>
        <TextWrapper>
          <TextContent>
            {!isFrameLoaded && (
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            )}
            <iframe
              className="terms-and-conditions"
              name="termly-embed"
              data-id="1e560f85-ddcf-45ba-a1e8-45801f4b58b7"
              data-type="iframe"
              src="https://app.termly.io/document/terms-of-service/1e560f85-ddcf-45ba-a1e8-45801f4b58b7"
              onLoad={() => setIsFrameLoaded(true)}
            ></iframe>

            <Gradient />
          </TextContent>
        </TextWrapper>
        <UpdatedInfo>Updated: February 2023</UpdatedInfo>

        <ButtonsWrapper>
          <Button
            text="Back"
            width="150px"
            height="40px"
            backgroundColor={Colors.white}
            textColor={Colors.darkBlue}
            borderRadius="4px"
            position="center"
            border={`2px solid ${Colors.darkBlue}`}
            onClick={onDeclineTerms}
          />
          <Button
            text="Accept"
            width="150px"
            height="40px"
            borderRadius="4px"
            position="center"
            backgroundColor={Colors.darkBlue}
            disabledColor={Colors.newBtnDisabled}
            onClick={onAcceptTerms}
          />
        </ButtonsWrapper>
      </div>
    );
  },
);

TermsOfConditions.displayName = 'TermsOfConditions';
