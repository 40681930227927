import { memo, useEffect, useState } from 'react';
import { IPlayerData } from '@/interfaces';
import { Wrapper } from './GenerateScriptStep.styled';
import { Button } from '@/components/common';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
// import {
//   DocumentationTab,
//   DocumentationTabs,
// } from '../ChooseOptionsStep/ChooseOptionsStep.styled';
// import { tabs } from '../ChooseOptionsStep';
import { DocumentationByUsage } from '../../DocumentationByUsage';

interface GenerateScriptStepProps {
  playerInfo?: IPlayerData;
}

// UNCOMENT CODE IN CASE IF WILL BE NEED DOCS FOR REACT, VUE etc.
export const GenerateScriptStep: React.FC<GenerateScriptStepProps> = memo(
  ({ playerInfo }) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    // const [activeTab, setActiveTab] = useState(1);
    // const [isContainerCopied, setIsContainerCopied] = useState<boolean>(false);
    const navigate = useNavigate();
    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();

    const domain = process.env.REACT_APP_API_HOST?.substr(
      0,
      process.env.REACT_APP_API_HOST.length - 4,
    );

    const scriptText = `<script type="text/javascript" src="${domain}widget/player.js"></script><script defer${
      playerInfo?.type === 'static' ? ` id="${playerInfo?.id}"` : ''
    }>TYLR_Widget.init("${
      playerInfo?.id
    }")</script>`;
    const containerText = `<div id="ty-project-player-${playerInfo?.id}"></div>`;
    // const scriptText2 = `<script type="text/javascript" src="${domain}widget/player.js"></script><link rel="stylesheet" href="${domain}widget/player.css" />`;

    function handleClickCopy() {
      // copyToClipboard(activeTab === 1 ? scriptText : scriptText2)();
      copyToClipboard(scriptText)();

      setIsCopied(true);

      const timeout = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(timeout);
      }, 5000);
    }

    // function handleClickCopyContainer() {
    //   copyToClipboard(containerText)();
    //   setIsContainerCopied(true);

    //   const timeout = setTimeout(() => {
    //     setIsContainerCopied(false);
    //     clearTimeout(timeout);
    //   }, 5000);
    // }

    // function changeTab(tabValue: number) {
    //   return () => {
    //     setActiveTab(tabValue);
    //   };
    // }

    useEffect(() => {
      const breadcrumbsData = [
        ...breadcrumbs,
        {
          label: 'Get code',
        },
      ];

      setBreadcrumbs(breadcrumbsData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleBack() {
      navigate(`/players/edit/${playerInfo?.id}`, { replace: true });
    }

    return (
      <Wrapper>
        {/* <DocumentationTabs>
          {tabs.map((tab) => (
            <DocumentationTab
              key={tab.value}
              isActive={tab.value === activeTab}
              onClick={changeTab(tab.value)}
            >
              {tab.title}
            </DocumentationTab>
          ))}
        </DocumentationTabs> */}
        {/* {activeTab === 1 && ( */}
        <DocumentationByUsage
          scriptText={`<script
type="text/javascript"
src="${domain}widget/player.js"
></script>
<script defer${playerInfo?.type === 'static' ? ` id="${playerInfo?.id}"` : ''}>
TYLR_Widget.init("${playerInfo?.id}")
</script>`}
          isCopied={isCopied}
          playerId={playerInfo?.id}
          containerText={containerText}
          copyText={handleClickCopy}
          isCms
          playerType={playerInfo?.type}
        />
        {/* )} */}
        {/* {activeTab === 2 && (
          <DocumentationByUsage
            scriptText={`<script
type="text/javascript"
src="${domain}widget/player.js"
></script>
`}
            isCopied={isCopied}
            playerId={playerInfo?.id}
            containerText={containerText}
            copyText={handleClickCopy}
            clickCopyContainer={handleClickCopyContainer}
            isContainerCopied={isContainerCopied}
            withBlock
            additionalInfo={`You can initialize and reinitialize the widget using "window.Widget.initInBlock(${playerInfo?.id})" at the moment when your page is being mounted. Be sure that in the case of using a dynamic widget, the insertion block does not limit anything in terms of positioning and it is located directly in the page wrapper block without attachment to the rest.`}
          />
        )} */}
        <Button
          text={'Back to edit'}
          borderRadius={'8px'}
          width={'125px'}
          height={'40px'}
          position={'center'}
          onClick={handleBack}
        />
      </Wrapper>
    );
  },
);

GenerateScriptStep.displayName = 'GenerateScriptStep';
