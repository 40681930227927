import React, { useState } from 'react';
import { BreadcrumbsContext, IBreadcrumb } from '@/hooks/useBreadcrumbs';

interface BreadcrumbsProviderProps {
  children: React.ReactNode;
}

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
