import { Colors } from '@/environment';
import { EInvitationStatus, IInvatationStatus } from '@/interfaces';
import { RootState } from '@/redux';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function compareDatesByDays(currentDate: string, lowerDate: string) {
  const d1 = new Date(currentDate);
  const d2 = new Date(lowerDate);
  const diffTime = Math.abs(d1.getTime() - d2.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

const isInviteWasSended = (currentDate: string, lowerDate: string) => {
  const countDays = compareDatesByDays(currentDate, lowerDate);
  return countDays < 30;
};

export const useCheckInvitation = (
  email: string,
): {
  invitationStatus: IInvatationStatus | null;
  setInvitationStatus: React.Dispatch<
    React.SetStateAction<IInvatationStatus | null>
  >;
} => {
  const [invitationStatus, setInvitationStatus] =
    useState<IInvatationStatus | null>(null);

  const { users } = useSelector((store: RootState) => ({
    users: store.users.data,
  }));

  const isSendedInvite = useCallback(
    (email: string) => {
      if (
        users.some(
          (user) =>
            user.email === email &&
            !user.confirmedInvitation &&
            isInviteWasSended(user.invitedAt, new Date().toISOString()),
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    [users],
  );

  const isUserConfirmed = useCallback(
    (email: string) => {
      return users.some(
        (user) => user.email === email && user.confirmedInvitation,
      );
    },
    [users],
  );

  const checkInvitation = useCallback(
    (email: string) => {
      if (isSendedInvite(email)) {
        const status = {
          status: EInvitationStatus.SentEarlier,
          message:
            'An invitation was sent to user in the last 30 days, another invitation will be sent with a new link.',
          color: Colors.blue,
        };

        setInvitationStatus(status);
      }

      if (isUserConfirmed(email)) {
        const status = {
          status: EInvitationStatus.UserAlready,
          message: `User with email ${email} is already part of your organization`,
          color: Colors.red,
        };
        setInvitationStatus(status);
      }

      if (!isSendedInvite(email) && !isUserConfirmed(email)) {
        setInvitationStatus(null);
      }
    },
    [isSendedInvite, isUserConfirmed, setInvitationStatus],
  );

  useEffect(() => {
    if (email && invitationStatus?.status !== EInvitationStatus.Submitted) {
      checkInvitation(email);
    }
  }, [checkInvitation, email, invitationStatus?.status]);

  return {
    invitationStatus,
    setInvitationStatus,
  };
};
