import styled from 'styled-components';
import { Select } from 'antd';
import { Colors } from '@/environment';
import { Icon } from '../../common/Icon';

export const SelectWithOptionsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const SelectedOptions = styled(SelectWithOptionsWrap)`
  gap: 8px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 16px;
  width: max-content;
  box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
  background-color: ${Colors.white};
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 40px;
  border: 2px solid ${Colors.transparentBorderBlue};
  box-sizing: border-box;

  .rc-virtual-list-holder-inner > :first-child {
    display: none !important;
  }

  & .select {
    width: 100%;
    top: 0px !important;
    border-radius: 16px;
    box-shadow: 0px 4px 15px rgba(100, 18, 177, 0.08);
  }

  & .ant-select-selection-item {
    padding: 0px !important;
    width: 100% !important;
    line-height: 0px;
    text-align: left;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    color: ${Colors.text.main};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    &::after {
      display: none !important;
    }
  }

  & .ant-select-selection-placeholder {
    padding: 0px !important;
    width: 100% !important;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    color: ${Colors.text.main};

    &::after {
      display: none !important;
    }
  }

  & .ant-select-arrow {
    position: static;
    margin: 0px;
  }

  & .ant-select-selector {
    padding: 0px !important;
    width: max-content !important;
    height: max-content !important;
    flex: 1;
  }

  & .ant-select-selector::placeholder {
    color: ${Colors.grey[100]};
  }
`;

export const SelectWrapper = styled.div`
  width: max-content;
  min-width: 145px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  & .ant-select-dropdown {
    border: 2px solid ${Colors.transparentBorderBlue};
    top: -2px !important;
    right: -2px !important;
    left: auto !important;
    width: 100% !important;
    box-shadow: 0px 4px 12px 2px rgba(10, 83, 182, 0.12);
    box-sizing: border-box;
  }

  & .option {
    & .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:hover {
      background: transparent;
    }
  }

  .ant-select-dropdown
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: transparent;
  }

  & .ant-select-item-option-selected {
    background-color: transparent !important;
    font-weight: 500 !important;
  }
`;

export const StyledIcon = styled(Icon)`
  padding-right: 12px;
`;

export const OptionLabel = styled.span.attrs((props: { active: boolean }) => ({
  ...props,
}))`
  color: ${({ active }) => (active ? Colors.blue : Colors.text.main)};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
`;

export const FilterOption = styled.div`
  padding: 8px 11.5px;
  border: 1px solid ${Colors.blue};
  border-radius: 12px;
  color: ${Colors.blue};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;

  &:hover {
    background: ${Colors.transparentBlue};
  }

  & > :last-child {
    padding: 4px;
    cursor: pointer;
  }
`;
