import * as Yup from 'yup';

interface ISignUpPubliserForm {
  firstName: string;
  lastName: string;
  publisherName: string;
  phone?: string;
  website?: string;
  email: string;
  reEnterEmail: string;
  password: string;
  reEnterPassword: string;
}

const signUpPublisherFormShema: Yup.SchemaOf<ISignUpPubliserForm> = Yup.object({
  firstName: Yup.string()
    .trim()
    .max(255)
    .required('First name is required field'),
  lastName: Yup.string()
    .trim()
    .max(255)
    .required('Last name is required field'),
  publisherName: Yup.string()
    .trim()
    .max(255)
    .required('Name of organization is required field'),
  phone: Yup.string().matches(
    /^([+]?[1]+)\s?\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
    'Phone number is not valid',
  ),
  // .required('Phone number is required field')
  website: Yup.string()
    .trim()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
      'Enter correct url!',
    ),
  // .required('Please enter website')
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .max(255)
    .oneOf([Yup.ref('reEnterEmail'), null], 'Email addresses doesn’t match')
    .required('Email is required field'),
  reEnterEmail: Yup.string()
    .trim()
    .email('Invalid re-enter email address')
    .max(255)
    .oneOf([Yup.ref('email'), null], 'Email addresses doesn’t match')
    .required('Re-enter email is required field'),
  password: Yup.string()
    .required('Password is required field')
    .min(
      8,
      'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password does not meet the password requirements. A password should contain a minimum of 8 characters, including Latin letters, digits, and at least one of the following symbols: @$!%*#?&.',
    )
    .oneOf([Yup.ref('reEnterPassword'), null], 'Passwords doesn’t match'),
  reEnterPassword: Yup.string()
    .required('Re-enter password is required field')
    .oneOf([Yup.ref('password'), null], 'Passwords doesn’t match'),
});

export default signUpPublisherFormShema;
