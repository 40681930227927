import { memo, useState } from 'react';

import usePubMonetization from '@/hooks/usePubMonetization';

import {
  HintTextStyled,
  MonetizationSettingsWrapperStyled
} from './MonetizationSettings.styled';
import { Button } from '@/components/common';
import { Colors } from '@/environment';
import { PublisherPercentInput } from './PercentInput'
interface UserSettingsProps {
  publisherId: string;
  closeSettings: () => void;
  isDisableConfirm: boolean
}

export const PublisherSettingsMonetization: React.FC<UserSettingsProps> = memo(
  ({ publisherId, closeSettings, isDisableConfirm }) => {

    const { updatePubMonetization, isLoading, publisherCommissionPercent } = usePubMonetization(publisherId)
    const [percentage, setPercentage] = useState<number>(publisherCommissionPercent || 50)

    const onSaveMonetization = async () => {
      if (isDisableConfirm) {
        await updatePubMonetization(false)
      } else {
        await updatePubMonetization(true, percentage)
      }
      closeSettings()
    };

    return (
      <MonetizationSettingsWrapperStyled>

        <HintTextStyled isDisableConfirm={isDisableConfirm}>
          {isDisableConfirm ? 'Are you sure of Turning OFF revenue for this publisher?' :
            'Setup a percentage amount for Adv Monetization Revenue for this Publisher.'}
        </HintTextStyled>

        {!isDisableConfirm && (
          <PublisherPercentInput percentage={percentage} setPercentage={setPercentage} />
        )}

        <Button
          text={isDisableConfirm ? 'Yes, turn off' : 'Confirm'}
          height="40px"
          backgroundColor={Colors.blue}
          textColor={Colors.white}
          borderRadius="12px"
          position="center"
          loading={isLoading}
          border={`2px solid ${Colors.blue}`}
          onClick={onSaveMonetization}
        />

        <Button
          text="Cancel"
          height="40px"
          backgroundColor="transparent"
          textColor={Colors.blue}
          borderRadius="12px"
          position="center"
          border={`2px solid ${Colors.blue}`}
          onClick={closeSettings}
        />

      </MonetizationSettingsWrapperStyled >
    );
  },
);

PublisherSettingsMonetization.displayName = 'PublisherSettingsMonetization';
