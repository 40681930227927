import { ReactNode, memo } from 'react';
import { NotificationTextWrap } from './NotificationText.styled';

interface INotificationTextProps {
  message: string;
  icon: ReactNode;
}

export const NotificationText: React.FC<INotificationTextProps> = memo(
  ({ message, icon }) => {
    return (
      <NotificationTextWrap>
        {icon}
        {message}
      </NotificationTextWrap>
    );
  },
);

NotificationText.displayName = 'NotificationText';
