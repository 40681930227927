import styled from 'styled-components';
import { Colors } from '@/environment';

export const MonetizationSettingsWrapperStyled = styled.div`
  padding: 24px 20px;
  display: grid;
  row-gap: 24px;
`;
export const HintTextStyled = styled.div<{ isDisableConfirm: boolean }>`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: ${(props) =>
    props.isDisableConfirm ? Colors.error : Colors.text.bold};
`;
