import { createSlice } from '@reduxjs/toolkit';
import { TAuthResponse } from '@/types/auth-types';
import { Nullable } from '@/types/generic';

type InitialState = {
  isAuthorized: boolean;
  data: Nullable<TAuthResponse>;
  viewAsPublisherId: string;
  canViewMobile: boolean;
};

const initialState: InitialState = {
  data: null,
  isAuthorized: false,
  viewAsPublisherId: '',
  canViewMobile: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => ({
      ...state,
      data: action.payload,
      isAuthorized: true,
    }),
    loggedOut: (state) => ({
      ...state,
      data: null,
      isAuthorized: false,
    }),
    setViewAsPublisherId: (state, action) => ({
      ...state,
      viewAsPublisherId: action.payload,
    }),
    setCanViewMobile: (state, action) => ({
      ...state,
      canViewMobile: action.payload,
    }),
  },
});

export const {
  updateUserInfo,
  loggedOut,
  setViewAsPublisherId,
  setCanViewMobile,
} = userSlice.actions;

export default userSlice.reducer;
