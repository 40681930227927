import { BaseFormSwitch, Button } from '@/components/common';
import { EUserRole, MAX_COUNT_INVITED_USERS } from '@/consts';
import { Colors } from '@/environment';
import { RootState } from '@/redux';
import {
  useDeleteUserMutation,
  useUpdateStatusMutation,
} from '@/redux/api/usersApi';
import { removeUser, updateUserStatus } from '@/redux/slice/users/slice';
import { Nullable } from '@/types/generic';
import { IUser } from '@/types/users-types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EUserTabs } from '@/pages/ManageUsers';
import {
  ButtonWrapperStyled,
  DeleteConfirmStyled,
  DeleteUserButton,
  StatusStyled,
  WrapperStyled,
} from './UserSettings.styled';

interface UserSettingsProps {
  userId: string;
  setSelectedUser: Dispatch<SetStateAction<Nullable<IUser>>>;
  setUserWasDeleted: Dispatch<SetStateAction<boolean>>;
  countActiveUsers: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<EUserTabs>>;
}

export const UserSettings: React.FC<UserSettingsProps> = (props) => {
  const { userId, setSelectedUser, setUserWasDeleted } = props;
  const { countActiveUsers, setCurrentTab } = props;
  const { users } = useSelector((store: RootState) => ({
    users: store.users.data,
  }));

  const findUser = users.find((user) => user.id === userId);
  const isMaxCountActiveUsers = countActiveUsers >= MAX_COUNT_INVITED_USERS;

  const [showConfirm, setShowConfirm] = useState(false);
  const [updateStatus] = useUpdateStatusMutation();
  const [deleteUser] = useDeleteUserMutation();

  const isShouldShowStatusSetting = findUser?.active || !isMaxCountActiveUsers;
  const isShouldShowDeleteDialog = findUser?.role !== EUserRole.Admin;

  const dispatch = useDispatch();

  const handleShowConfirm = () => {
    setShowConfirm((prev) => !prev);
  };

  const userStatus = useMemo(() => {
    if (userId && findUser) {
      return findUser.active;
    }
    return false;
  }, [findUser, userId]);

  const updatStatus = () => {
    updateStatus({
      data: { active: !findUser?.active },
      userId: findUser?.id,
    })
      .unwrap()
      .then((res) => {
        dispatch(updateUserStatus(res));
        setSelectedUser((user) => {
          if (user) {
            return {
              ...user,
              active: res.active,
              deactivatedAt: res.deactivatedAt,
            };
          }

          return null;
        });

        if (res.active) {
          setCurrentTab(EUserTabs.Active);
        } else {
          setCurrentTab(EUserTabs.Inactive);
        }
      });
  };

  useEffect(() => {
    setShowConfirm(false);
  }, [userId]);

  const deleteCurrentUser = () => {
    deleteUser({ userId: findUser?.id })
      .unwrap()
      .then((res) => {
        dispatch(removeUser({ id: res.id }));
        setUserWasDeleted(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <WrapperStyled>
      {showConfirm ||
        (isShouldShowStatusSetting && (
          <StatusStyled isShouldShowDeleteDialog={isShouldShowDeleteDialog}>
            <span className="status">
              Status {findUser?.active ? `(active)` : `(inactive)`}
            </span>
            <BaseFormSwitch
              value={userStatus}
              name="test"
              onChange={updatStatus}
            />
          </StatusStyled>
        ))}

      {isShouldShowDeleteDialog && (
        <ButtonWrapperStyled
          isShouldShowStatusSetting={isShouldShowStatusSetting}
        >
          {showConfirm ? (
            <DeleteConfirmStyled>
              <p>
                This action will delete this user permanently. Are you sure?
              </p>
              <Button
                type="primary"
                text="Delete"
                position="center"
                borderRadius="12px"
                backgroundColor={Colors.error}
                onClick={deleteCurrentUser}
              />
              <Button
                onClick={handleShowConfirm}
                text="Cancel"
                position="center"
                borderRadius="12px"
                backgroundColor="#f9f9fa"
                backgroundColorHover="#e0e9f6"
                textColor={Colors.blue}
                border={`2px solid ${Colors.blue}`}
              />
            </DeleteConfirmStyled>
          ) : (
            <DeleteUserButton type="link" onClick={handleShowConfirm}>
              Delete user
            </DeleteUserButton>
          )}
        </ButtonWrapperStyled>
      )}
    </WrapperStyled>
  );
};
