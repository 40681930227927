import { BaseLabel } from '@/components/common';
import { TOption } from '@/interfaces';
import { Radio, RadioProps } from 'antd';
import { memo } from 'react';
import { RadioGroup } from './RadioButtons.styled';

interface IRadioButtonsProps extends RadioProps {
  label?: string;
  options: TOption[];
  name: string;
}

export const RadioButtons: React.FC<IRadioButtonsProps> = memo((props) => {
  const { label, options, name, ...radioProps } = props;
  return (
    <>
      {label && <BaseLabel label={label} />}
      <RadioGroup
        defaultValue={options[0].value}
        buttonStyle="solid"
        name={name}
        {...radioProps}
      >
        {options.map((option) => (
          <Radio.Button key={option.value} value={option.value}>
            {option.title}
          </Radio.Button>
        ))}
      </RadioGroup>
    </>
  );
});

RadioButtons.displayName = 'RadioButtons';
