import styled, { css } from 'styled-components';
import { Select, SelectProps } from 'antd';
import { Colors } from '@/environment';

export const SelectWrapper = styled.div`
  position: relative;

  & .styled-dropdown {
    top: 0px !important;
    left: 0px !important;
    width: 100% !important;
  }
`;

export const StyledBaseFormSelect = styled(Select<SelectProps>).attrs(
  (props: { background: string }) => ({
    ...props,
  }))`
  &.styled-selector .ant-select-selector {
    background: ${({ background, disabled }) =>
      disabled ? Colors.white : background} !important;
    border-radius: 16px;
    border: none;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 17px;
  }

  .ant-select-arrow {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: initial;
        svg {
          fill: ${Colors.selectDisabledArrow};

          path {
            fill: ${Colors.selectDisabledArrow};
          }
        }
      `}
  }

  &.styled-selector .ant-select-selector-item {
    height: 36px;
  }

  &.styled-selector.select-invite-user .ant-space-item {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: ${Colors.dark};
  }

  &.ant-select-disabled {
    .ant-select-selector {
      cursor: initial !important;
    }
  }
`;