import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPublisher,
  IPublisherStatistic,
  TManagePubsPub,
  TManagePublisherResWithOffset,
} from '@/types/publisher-types';
import { Nullable } from '@/types/generic';

interface IManagePubsData {
  activeOffset: number;
  inactiveOffset: number;
  limit: number;
  items: TManagePubsPub[];
  activeTotal: number;
  inactiveTotal: number;
}

type InitialState = {
  data: Array<IPublisher>;
  managePubsData: IManagePubsData;
  stats: Nullable<IPublisherStatistic>;
  offset: number;
  limit: number;
  currentPublisher: Nullable<IPublisher>;
};

const initialState: InitialState = {
  data: [],
  managePubsData: {
    activeTotal: 0,
    inactiveTotal: 0,
    activeOffset: 0,
    inactiveOffset: 0,
    limit: 20,
    items: [],
  },
  stats: null,
  offset: 20,
  limit: 20,
  currentPublisher: null,
};

const publishersSlice = createSlice({
  name: 'publishers',
  initialState,
  reducers: {
    setPublishers: (state, { payload }: PayloadAction<IPublisher[]>) => ({
      ...state,
      data: payload,
    }),
    setManagePubsData: (
      state,
      {
        payload,
      }: PayloadAction<{
        items: TManagePubsPub[];
        activeTotal?: number;
        inactiveTotal?: number;
      }>,
    ) => ({
      ...state,
      managePubsData: {
        ...state.managePubsData,
        items: payload.items,
        activeTotal: payload.activeTotal
          ? payload.activeTotal
          : state.managePubsData.activeTotal,
        inactiveTotal: payload.inactiveTotal
          ? payload.inactiveTotal
          : state.managePubsData.inactiveTotal,
      },
    }),
    updateManagePubsData: (
      state,
      { payload }: PayloadAction<TManagePubsPub>,
    ) => {
      const pub = state.managePubsData.items.find(
        (pub) => pub.id === payload.id,
      );
      if (pub) {
        pub.isMonetizationAllowed = payload.isMonetizationAllowed;
        if (payload.publisherCommission) {
          pub.publisherCommission = payload.publisherCommission;
        }
      }
    },

    updateManagePubsActiveData: (
      state,
      { payload }: PayloadAction<TManagePubsPub>,
    ) => {
      const pub = state.managePubsData.items.find(
        (pub) => pub.id === payload.id,
      );

      if (pub) {
        pub.active = payload.active;
        if (payload.active) {
          ++state.managePubsData.activeTotal,
            --state.managePubsData.inactiveTotal;
        } else {
          ++state.managePubsData.inactiveTotal,
            --state.managePubsData.activeTotal;
        }
      }
    },
    setMorePublishers: (state, { payload }: PayloadAction<IPublisher[]>) => ({
      ...state,
      data: state.data.concat(payload),
    }),
    setMoreManagePubsData: (
      state,
      { payload }: PayloadAction<TManagePublisherResWithOffset>,
    ) => ({
      ...state,
      managePubsData: {
        ...state.managePubsData,
        items: state.managePubsData.items.concat(payload.items),
        activeOffset: payload.activeOffset
          ? payload.activeOffset
          : state.managePubsData.activeOffset,
        inactiveOffset: payload.inactiveOffset
          ? payload.inactiveOffset
          : state.managePubsData.inactiveOffset,
        limit: payload.limit,
        activeTotal: payload.activeTotal
          ? payload.activeTotal
          : state.managePubsData.activeTotal,
        inactiveTotal: payload.inactiveTotal
          ? payload.inactiveTotal
          : state.managePubsData.inactiveTotal,
      },
    }),
    refreshManagePubsPagination: (state) => ({
      ...state,
      managePubsData: {
        ...state.managePubsData,
        activeOffset: 0,
        inactiveOffset: 0,
        limit: 20,
        activeTotal: 0,
        inactiveTotal: 0,
      },
    }),
    setPublisherStats: (
      state,
      { payload }: PayloadAction<IPublisherStatistic>,
    ) => ({
      ...state,
      stats: payload,
    }),
    setCurrentPublisher: (state, { payload }: PayloadAction<IPublisher>) => ({
      ...state,
      currentPublisher: payload,
    }),
    updateCurrentPublisherBillingInfo: (
      state,
      { payload }: PayloadAction<IPublisher['publishersBillingInfo']>,
    ) => {
      console.log('updateCurrentPublisherBillingInfo');

      if (!state.currentPublisher) return;
      state.currentPublisher.publishersBillingInfo = payload;
    },
    clearPublishersInfo: () => initialState,
  },
});

export const {
  setPublishers,
  setMorePublishers,
  setPublisherStats,
  setCurrentPublisher,
  updateManagePubsData,
  updateManagePubsActiveData,
  refreshManagePubsPagination,
  setManagePubsData,
  setMoreManagePubsData,
  clearPublishersInfo,
  updateCurrentPublisherBillingInfo,
} = publishersSlice.actions;

export default publishersSlice.reducer;
