import { memo } from 'react';
import { Label } from './BaseLabel.styled';

type TBaseLabelProps = {
  label: string;
};

export const BaseLabel: React.FC<TBaseLabelProps> = memo(({ label }) => {
  return <Label className="base-label">{label}</Label>;
});

BaseLabel.displayName = 'BaseLabel';
