import { Icon, SphereBlob } from '@/components/common';
import { IconType } from '@/consts';
import { ReactNode } from 'react';
import {
  AuthContainerStyled,
  AuthPoweredByBlockStyled,
  AuthTitleStyled,
  AuthWrapperStyled,
  BodyWrap,
  FooterWrap,
  SiteLinks,
} from './AuthWrapper.styled';
import { useLocation } from 'react-router-dom';

interface IAuthWrapperProps {
  children: ReactNode;
  paddingContainer: string;
  paddingTitle?: string;
}

export const AuthWrapper: React.FC<IAuthWrapperProps> = (props) => {
  const location = useLocation();
  const { paddingContainer, paddingTitle = '35px', children } = props;

  const links = [
    {
      label: 'Terms of Service',
      link: 'http://tylr.com/terms-conditions',
    },
    {
      label: 'Privacy Policy',
      link: 'http://tylr.com/privacy-policy',
    },
    {
      label: 'Cookie Policy',
      link: 'http://tylr.com/cookie-policy',
    },
  ];

  function handleClick() {
    const link = document.createElement('a');
    link.href = 'http://tylr.com';
    link.rel = 'noopener noreferrer';
    link.target = '_blank';
    link.click();
  }

  return (
    <AuthWrapperStyled isSignUp={location.pathname.includes('signup')}>
      <BodyWrap isSignUp={location.pathname.includes('signup')}>
        <AuthTitleStyled paddingTitle={paddingTitle} onClick={handleClick}>
          TYLR
          <Icon name={IconType.TYMainLogoIcon} />
        </AuthTitleStyled>

        <AuthContainerStyled paddingContainer={paddingContainer}>
          {children}
          <AuthPoweredByBlockStyled>
            <span>Powered by</span>&nbsp;
            <a href="http://tylr.com" target="_blank" rel="noopener noreferrer">
              TYLR
            </a>
          </AuthPoweredByBlockStyled>
        </AuthContainerStyled>
      </BodyWrap>
      <FooterWrap isSignUp={location.pathname.includes('signup')}>
        <SiteLinks>
          {links.map((link) => (
            <a
              href={link.link}
              rel="noopener noreferrer"
              target="_blank"
              key={link.label}
            >
              {link.label}
            </a>
          ))}
        </SiteLinks>
        <div>© 2023 The TY Project</div>
      </FooterWrap>
      <SphereBlob />
    </AuthWrapperStyled>
  );
};
