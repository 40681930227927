import { DropdownIcon, StyledDropdown } from './DottedDropdown.styled';
import type { MenuProps } from 'antd';
import { Icon } from '../Icon';
import { IconType } from '@/consts';
import { Colors } from '@/environment';

type TDropdownProps = {
  items: MenuProps['items'];
  selected: string;
};

export const DottedDropdown: React.FC<TDropdownProps> = ({
  items,
  selected,
}) => {
  return (
    <StyledDropdown
      getPopupContainer={(trigger: HTMLElement) =>
        trigger.parentNode as HTMLElement
      }
      trigger={['click']}
      overlayClassName="dropdownContent"
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [selected],
      }}
    >
      <DropdownIcon>
        <Icon name={IconType.TYDropdownDots} color={Colors.blue} />
      </DropdownIcon>
    </StyledDropdown>
  );
};
