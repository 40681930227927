import { Colors } from '@/environment';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding-top: 80px;
  height: max-content;
  overflow-y: scroll;
  scrollbar-width: auto;
  scrollbar-color: ${Colors.scrollbar} transparent;

  ::-webkit-scrollbar {
    width: 4px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }
`;
