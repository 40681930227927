import { memo, useEffect, useMemo, useState } from 'react';
import {
  CardWrapper,
  CardTitle,
  PercentageDiff,
  CardValue,
  CardContainerBottom,
} from './Card.styled';
import { DottedDropdown } from '../DottedDropdown';
import { IconType } from '@/consts';
import { Icon } from '../Icon';
import { Colors } from '@/environment';
import { sliceTextLenght } from '@/helpers/sliceTextLenght';

interface ICard {
  title: string;
  value: string;
  key: string;
  compareValue: number;
}

interface ICardProps {
  id: number;
  selected: string;
  cards: ICard[];
  setSelectedCards: (value: string, index: number) => () => void;
  selectedCards: string[];
}

//TODO: REFACTOR !!!!
export const Card: React.FC<ICardProps> = memo(
  ({ id, selected, cards, setSelectedCards }) => {
    const [currentCard, setCurrentCard] = useState<ICard>();

    const dropdownItems = useMemo(() => {
      return cards.map((card) => ({
        key: card.key,
        label: (
          <span
            onClick={setSelectedCards(card.key, id)}
            className="dropdown-item-content"
          >
            {card.title}
            {card.key === selected && (
              <Icon
                name={IconType.TYTimeSortCheck}
                iconHeight={8}
                iconWidth={10}
                color={Colors.blue}
              />
            )}
          </span>
        ),
      }));
    }, [cards, id, selected, setSelectedCards]);

    const isCompareValue = useMemo(() => {
      if (!currentCard || (currentCard && currentCard.compareValue === 0)) {
        return false;
      }

      if (Number.isNaN(currentCard.compareValue)) {
        return false;
      }

      return true;
    }, [currentCard]);

    useEffect(() => {
      const selectedCard = cards.find(({ key }) => key === selected);
      setCurrentCard(selectedCard);
    }, [cards, selected]);

    return (
      <CardWrapper>
        <DottedDropdown items={dropdownItems} selected={selected} />
        <CardTitle>{currentCard?.title}</CardTitle>
        <CardContainerBottom>
          <CardValue>{currentCard?.value}</CardValue>
          {isCompareValue && currentCard && (
            <PercentageDiff upOrDown={currentCard.compareValue > 0}>
              <span>
                {`${currentCard.compareValue > 0 ? '+' : ''}${sliceTextLenght(
                  String(currentCard.compareValue),
                  25,
                )}`}
                %
              </span>
              {currentCard.compareValue > 0 ? (
                <Icon name={IconType.TYArrowUp} />
              ) : (
                <Icon name={IconType.TYArrowDown} />
              )}
            </PercentageDiff>
          )}
        </CardContainerBottom>
      </CardWrapper>
    );
  },
);

Card.displayName = 'Card';
