import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Table } from 'antd';
import {
  FiltersWrapper,
  TableWrapper,
  StatisticInfoTableWrapper,
} from './StatisticInfoTable.styled';
import { SearchInput } from '@/components/common/SearchInput';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useViewport } from '@/hooks/useViewport';
import { LoaderSpin } from '@/components/common/LoaderSpin';
import { Colors } from '@/environment';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@/components/common';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Nullable } from '@/types/generic';

interface TTableProps<T> {
  data: T[];
  loading: boolean;
  fetchMore: () => void;
  isFooterShow: boolean;
  sortTable: (orderBy?: Nullable<string>, orderAsc?: Nullable<string>) => void;
  columns: ColumnsType<T>;
  onSearch: (value: string) => void;
  tableHeaderTopRight?: ReactNode;
}

interface OptionValue {
  key: string;
}

export const StatisticInfoTable = <T extends OptionValue>({
  data,
  loading = false,
  fetchMore,
  isFooterShow,
  sortTable,
  columns,
  onSearch,
  tableHeaderTopRight
}: TTableProps<T>) => {
  const [tableHeight, setTableHeight] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableParams, setTableParams] = useState<SorterResult<T>>({});

  const { width } = useViewport();
  const { pathname } = useLocation();
  const { playerId } = useParams();

  const ref = useRef<Nullable<HTMLDivElement>>(null);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, Nullable<FilterValue>>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    if (!Array.isArray(sorter)) {
      setTableParams({
        ...sorter,
      });
    }
  };

  useEffect(() => {
    if ('order' in tableParams && 'field' in tableParams) {
      const { order, field } = tableParams;
      if (pathname.includes(`/players/${playerId}`) && field === 'name') {
        sortTable('headline', order);
      } else {
        sortTable(field as string, order);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const node = ref.current;
    const tableHeaderHeight = document.getElementsByClassName(
      'ant-table-thead',
    )[0] as HTMLElement;
    const tableBody = document.getElementsByClassName(
      'ant-table-body',
    )[0] as HTMLElement;

    if (node) {
      const { top } = node.getBoundingClientRect();

      const height = window.innerHeight - top - tableHeaderHeight.offsetHeight;
      setTableHeight(height);
      setTableWidth(tableBody.offsetWidth);
    }
  }, [ref, width, data, isFooterShow, loading]);

  return (
    <StatisticInfoTableWrapper>
      <FiltersWrapper>
        <SearchInput onChange={onSearch} />
        {tableHeaderTopRight}
      </FiltersWrapper>
      <TableWrapper
        minHeight={tableHeight > 0 ? `${tableHeight}px` : '100vh'}
        isFooterShow={isFooterShow}
        tableWidth={tableWidth}
      >
        <Table<T>
          ref={ref}
          showSorterTooltip={false}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: (
              <LoaderSpin size={50} color={Colors.blue} thickness={0.4} />
            ),
          }}
          dataSource={data}
          pagination={false}
          scroll={{
            y: tableHeight > 0 ? `${tableHeight}px` : '100%',
            x: width < 1200 ? '100vw' : 0,
          }}
          onChange={handleTableChange}
          summary={() => (
            <tr>
              <td>
                <Button
                  text="Load more"
                  borderRadius="12px"
                  onClick={fetchMore}
                  backgroundColor={Colors.white}
                  textColor={Colors.blue}
                  border={`2px solid ${Colors.blue}`}
                  position="center"
                  width={'205px'}
                  height={'36px'}
                  backgroundColorHover={Colors.authBlockBg}
                />
              </td>
            </tr>
          )}
        />
      </TableWrapper>
    </StatisticInfoTableWrapper>
  );
};
