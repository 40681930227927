import { Colors } from '@/environment';
import styled from 'styled-components';

export const ErrorsWrapper = styled.div.attrs(
  (props: { gapTop?: number; gapBottom?: number }) => ({
    ...props,
  }),
)`
  margin-top: ${({ gapTop }) => (gapTop ? `${gapTop}px` : '0px')};
  margin-bottom: ${(props) =>
    props.gapBottom ? `${props.gapBottom}px` : '0px'};
`;

export const ErrorStyled = styled.div.attrs((props: { fontSize?: number }) => ({
  ...props,
}))`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '10px')};
  line-height: 20px;
  text-align: center;
  color: ${Colors.error};
`;
