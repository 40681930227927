import { ChangeEvent, memo, useState } from 'react';
import { StyledSwitch } from './BaseFormSwitch.styled';

interface BaseFormSwitchProps {
  value: boolean;
  name: string;
  onChange: (name: string, value: boolean) => void;
  disabled?: boolean;
}

export const BaseFormSwitch: React.FC<BaseFormSwitchProps> = memo(
  ({ name, value, onChange, disabled }) => {
    const [isToggled, setIsToggled] = useState(value ?? false);

    const onToggle = (e: ChangeEvent<HTMLInputElement>) => {
      setIsToggled(!isToggled);
      onChange(name, e.target.checked);
    };

    return (
      <StyledSwitch disabled={disabled} checked={value}>
        <input
          type="checkbox"
          checked={value}
          onChange={onToggle}
          disabled={disabled}
        />
        <span className="switch" />
      </StyledSwitch>
    );
  },
);

BaseFormSwitch.displayName = 'BaseFormSwitch';
