import { GroupProps } from 'antd/es/input/Group';
import { AuthInputErrorStyled } from '@/components/common/AuthInput/AuthInput.styled';
import { AuthGroupInputStyled } from './AuthGroupInput.styled';

interface AuthGroupInputProps extends GroupProps {
  errors?: Array<string | undefined>;
}

export const AuthGroupInput: React.FC<AuthGroupInputProps> = (props) => {
  const { errors, children, ...inputProps } = props;

  return (
    <>
      <AuthGroupInputStyled {...inputProps}>{children}</AuthGroupInputStyled>

      {errors?.length &&
        errors.map((error, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <AuthInputErrorStyled key={index}>{error}</AuthInputErrorStyled>
          );
        })}
    </>
  );
};
