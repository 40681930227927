import { Button, Icon } from '@/components/common';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';
import getUserIconColor from '@/helpers/getUserIconColor';
import { loggedOut, setViewAsPublisherId } from '@/redux/slice/user/slice';
import { EAuthRoutes, ERoutes } from '@/routes';
import { TAuthResponse } from '@/types/auth-types';
import { Nullable } from '@/types/generic';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ButtonWrapperStyled,
  PopoverContentWrapperStyled,
  UserInfoWrapperStyled,
  Username,
  UserInfo,
} from './PopoverContent.styled';
import { clearPlayersInfo } from '@/redux/slice/players/slice';
import { clearPlayerSortParams } from '@/redux/slice/players/sortParams/slice';
import { clearPublishersInfo } from '@/redux/slice/publishers/slice';
import { clearUsersInfo } from '@/redux/slice/users/slice';

interface IPopoverContent {
  user?: Nullable<TAuthResponse>;
  asPub: string;
}

export const PopoverContent = (props: IPopoverContent) => {
  const { user, asPub } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // TODO: Refactor in future
  const logOut = () => {
    dispatch(loggedOut());
    dispatch(setViewAsPublisherId(''));
    dispatch(clearPlayersInfo());
    dispatch(clearPlayerSortParams());
    dispatch(clearPublishersInfo());
    dispatch(clearUsersInfo());

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate(`/${EAuthRoutes.Login}`);
  };

  const setView = useCallback(() => {
    dispatch(setViewAsPublisherId(''));
    navigate(`${ERoutes.Root}`);
  }, [dispatch, navigate]);

  return (
    <PopoverContentWrapperStyled>
      <UserInfoWrapperStyled>
        <UserInfo>
          {user && (
            <>
              <Icon
                name={IconType.TYUserIcon}
                color={getUserIconColor(user.role)}
              />
              <Username>
                {`${user.firstName} ${user.lastName}`}
                {checkLengthIsPositive(asPub) && ' (Publisher view)'}
              </Username>
            </>
          )}
        </UserInfo>
      </UserInfoWrapperStyled>
      {checkLengthIsPositive(asPub) && (
        <ButtonWrapperStyled>
          <Button
            text={'Back to owner view'}
            onClick={setView}
            position="center"
            backgroundColor={Colors.white}
            textColor={Colors.blue}
            height="20px"
          />
        </ButtonWrapperStyled>
      )}
      <ButtonWrapperStyled>
        <Button
          text={'Log out'}
          onClick={logOut}
          position="center"
          backgroundColor={Colors.white}
          textColor={Colors.blue}
          height="20px"
        />
      </ButtonWrapperStyled>
    </PopoverContentWrapperStyled>
  );
};
