import { Dispatch, memo, SetStateAction } from 'react';
import { Checkbox } from 'antd';
import {
  CheckboxContent,
  TermsOfConditionStyled,
} from './TermsOfConditionCheckbox.styled';

interface ITermsOfCondition {
  isTermSelected: boolean;
  setIsShowTerms: Dispatch<SetStateAction<boolean>>;
  setIsTermSelected: Dispatch<SetStateAction<boolean>>;
}

export const TermsOfConditionsCheckbox: React.FC<ITermsOfCondition> = memo(
  (props) => {
    const { isTermSelected, setIsShowTerms, setIsTermSelected } = props;

    const changeIsShowTerms = () => {
      if (isTermSelected) {
        setIsTermSelected(false);
      } else {
        setIsShowTerms(true);
      }
    };

    return (
      <TermsOfConditionStyled>
        <Checkbox
          className="styled-checkbox"
          onChange={changeIsShowTerms}
          checked={isTermSelected}
        >
          <CheckboxContent>
            I agree with a<p onClick={changeIsShowTerms}>Terms of Service</p>
          </CheckboxContent>
        </Checkbox>
      </TermsOfConditionStyled>
    );
  },
);

TermsOfConditionsCheckbox.displayName = 'TermsOfConditionsCheckbox';
