import { Colors } from '@/environment';
import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 139px;
  box-sizing: border-box;
  padding: 3.5px;
  border: 2px solid ${Colors.extraLightBlue};
  border-radius: 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

export const BtnsWrap = styled.div.attrs((props: { isDeleted: boolean }) => ({
  ...props,
}))`
  padding: ${({ isDeleted }) =>
    isDeleted ? '6.5px 16.5px' : '6.5px 16.5px 10px 16.5px'};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const EditBtn = styled.div`
  color: ${Colors.blue};
  cursor: pointer;
`;

export const ToggleAdsBtn = styled.div.attrs(
  (props: { isAddActive: boolean; disabled?: boolean }) => ({
    ...props,
  }),
)`
  cursor: pointer;
  color: ${({ isAddActive, disabled }) =>
    disabled
      ? Colors.buttons.disabled
      : isAddActive
      ? Colors.green
      : Colors.text.main};
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const StatusBtn = styled.div.attrs(
  (props: { status: boolean; disabled?: boolean }) => ({
    ...props,
  }),
)`
  cursor: pointer;
  color: ${({ status, disabled }) =>
    disabled
      ? Colors.buttons.disabled
      : status
      ? Colors.saturatedYellow
      : Colors.saturatedGreen};
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      svg {
        fill: ${Colors.buttons.disabled};
        stroke: ${Colors.buttons.disabled};

        rect {
          fill: ${Colors.buttons.disabled};
          stroke: ${Colors.buttons.disabled};
        }

        path {
          fill: ${Colors.buttons.disabled};
          stroke: ${Colors.buttons.disabled};
        }
      }
    `}
`;
