import { Colors } from '@/environment';
import styled, { keyframes, css } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = styled.div.attrs(
  (props: {
    size: number;
    color: string;
    thickness: number;
    position: string;
  }) => ({
    ...props,
  }),
)`
  border: ${(props) => `${props.thickness}em solid ${props.color}`};
  border-top: ${(props) => `${props.thickness}em solid ${Colors.white}`};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.size}px;
  max-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  animation: ${spin} 0.6s linear infinite;

  ${(props) =>
    props.position === 'absolute' &&
    css`
      position: absolute;
      top: 50%;
      left: calc(50% - ${props.size / 2}px);
      transform: translate(-50%, -50%);
    `}
`;
