import styled from 'styled-components';
import { Colors } from '@/environment';

export const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.sidebarBg};
  border-top-right-radius: 24px;
  padding: 20px 22px 30px 27px;
  font-size: 14px;
  box-sizing: border-box;
  min-width: 250px;
  max-width: 250px;
  box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12);

  @media (max-width: 1240px) {
    padding: 20px 12px 30px 12px;
    min-width: 220px;
    max-width: 220px;
  }
`;

export const SidebarTopBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
`;

export const SidebarFootBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const PoweredByBlock = styled.div`
  font-size: 10px;

  & > :first-child {
    color: ${Colors.text.main};
  }

  & > a {
    color: ${Colors.black};
    text-decoration: none;
  }
`;
