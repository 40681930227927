import styled from 'styled-components';

export const CustomCursor = styled.div`
  --left: 200;
  --top: 200;

  position: fixed;
  left: calc(var(--left, 0) * 1px);
  top: calc(var(--top, 0) * 1px);
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(122.2deg, #f1d685 9.6%, #fabbbc 109.21%);
  opacity: 0.5;
  filter: blur(3.75rem);
  transition: left ease-out 0.3s, top ease-out 0.3s;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
