import styled from 'styled-components';
import { Form } from 'antd';
import { Colors } from '@/environment';

export const InviteFormStyled = styled(Form)`
  padding: 40px 40px;
  gap: 0 0 24px 0;

  & .ant-form-item {
    margin-bottom: 20px;
  }

  & .ant-form-item .ant-form-item-label > label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.grey[100]};
    padding-left: 13px;
    font-family: 'Inter', sans-serif;
  }
`;

export const DelimiterStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.scrollbar};
  margin-bottom: 20px;
`;

export const StatusMessageStyled = styled.div.attrs(
  (props: { color: string; fontWeight?: string }) => ({
    ...props,
  }),
)`
  color: ${({ color }) => color};
  margin-top: 20px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-size: 12px;
  line-height: 20px;
  padding: 0 20px;
`;
