import { Colors } from '@/environment';
import styled from 'styled-components';

export const Label = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.blue};
  padding: 0px;
  padding-bottom: 12px;
`;
