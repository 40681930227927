import { memo } from 'react';
import { InputProps } from 'antd';
import { BaseLabel } from '../BaseLabel';
import {
  ErrorMessageFormInput,
  StyledBaseFormInput,
} from './BaseFormInput.styled';

interface BaseFormInputProps extends InputProps {
  errorMessage?: string;
  label?: string;
  color: string;
}

export const BaseFormInput: React.FC<BaseFormInputProps> = memo((props) => {
  const { errorMessage, label, color, ...inputProps } = props;

  return (
    <div>
      {label && <BaseLabel label={label} />}
      <StyledBaseFormInput
        {...inputProps}
        color={color}
        style={{ width: '100%' }}
        className="styled-input"
      />
      {errorMessage && (
        <ErrorMessageFormInput className="input-error">
          {errorMessage}
        </ErrorMessageFormInput>
      )}
    </div>
  );
});

BaseFormInput.displayName = 'BaseFormInput';
