import { Colors } from '@/environment';
import styled from 'styled-components';

export const PublisherSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
  box-sizing: border-box;
  background: white;
  border-radius: 16px;
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eef5f9;
  box-sizing: border-box;

  & .status {
    color: ${Colors.blue};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
`;
