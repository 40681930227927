import { Colors } from '@/environment';
import styled from 'styled-components';

export const Wrapper = styled.div.attrs((props: { step: number }) => ({
  ...props,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: 536px;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ step }) => (step === 2 ? '0px 0px 20px 20px' : '0')};
`;

export const Step = styled.div.attrs((props: { active: boolean }) => ({
  ...props,
}))`
  height: 6px;
  background-color: ${({ active }) => (active ? Colors.blue : Colors.grey[80])};
  width: 100%;
  flex: 1 1 0px;
`;
