import { Icon } from '@/components/common';
import { EUserRole, IconType } from '@/consts';
import { Colors } from '@/environment';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { RootState } from '@/redux';
import { Nullable } from '@/types/generic';
import { IUser } from '@/types/users-types';
import { Popover } from 'antd';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import getUserIconColor from '@/helpers/getUserIconColor';
import {
  InfoBlockIconStyled,
  InfoBlockStyled,
  InfoBlockTextStyled,
  LeftContainerStyled,
  NameInactiveUserStyled,
  NameStyled,
  RightContainerStyled,
  TabsHeader,
  TabsHeaderItem,
  UserListCardStyled,
  UserListStyled,
  WrapperStyled,
} from '@/components/ui/Layouts/ManageLayout/ManageLayout.styled';
import { UserInfo, UserSettings } from '@/components/ui';
import { useLazyGetPubUsersQuery } from '@/redux/api/usersApi';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';
import { useLazyGetUsersQuery } from '@/redux/api/usersApi';
import { setUsers } from '@/redux/slice/users/slice';

export enum EUserTabs {
  Active = 'active',
  Inactive = 'inactive',
}

const ManageUsers = () => {
  const [selectedUser, setSelectedUser] = useState<Nullable<IUser>>(null);
  const [userWasDeleted, setUserWasDeleted] = useState(false);
  const [currentTab, setCurrentTab] = useState<EUserTabs>(EUserTabs.Active);

  const dispatch = useDispatch();

  const { setBreadcrumbs } = useBreadcrumbs();

  const [getPubUsers, { data: pubUsersData }] = useLazyGetPubUsersQuery();

  const [getUsers] = useLazyGetUsersQuery();

  const { currentPublisher, users, user, viewAsPublisherId } = useSelector(
    (store: RootState) => ({
      user: store.user.data,
      currentPublisher: store.publishers.currentPublisher,
      users: store.users.data,
      viewAsPublisherId: store.user.viewAsPublisherId,
    }),
  );

  const activeUsers = useMemo(() => {
    if (checkLengthIsPositive(viewAsPublisherId) && pubUsersData) {
      return sortBy(
        pubUsersData.filter((user) => user.active),
        ['role'],
      );
    } else {
      return sortBy(
        users.filter((user) => user.active),
        ['role'],
      );
    }
  }, [pubUsersData, users, viewAsPublisherId]);

  const inActiveUsers = useMemo(() => {
    if (checkLengthIsPositive(viewAsPublisherId) && pubUsersData) {
      return pubUsersData.filter(
        (user) => !user.active && user.confirmedInvitation,
      );
    }
    return users.filter((user) => !user.active && user.confirmedInvitation);
  }, [pubUsersData, users, viewAsPublisherId]);

  const getDeactivatedData = (date: Nullable<string>) => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
    return 'Date not found';
  };

  function handleChangeTab(tab: EUserTabs) {
    return () => {
      setCurrentTab(tab);
    };
  }

  function handleSelectUser(userInfo: IUser) {
    return () => {
      setSelectedUser(userInfo);
      setUserWasDeleted(false);
    };
  }

  const isShowSettings =
    !userWasDeleted &&
    selectedUser?.id !== user?.id &&
    !checkLengthIsPositive(viewAsPublisherId);

  useEffect(() => {
    const breadcrumbsData = [
      {
        label: 'Manage Users',
      },
    ];

    if (selectedUser) {
      setBreadcrumbs([
        ...breadcrumbsData,
        { label: `${selectedUser.firstName} ${selectedUser.lastName}` },
      ]);
    } else {
      setBreadcrumbs(breadcrumbsData);
    }
  }, [selectedUser, setBreadcrumbs]);

  useEffect(() => {
    if (checkLengthIsPositive(viewAsPublisherId)) {
      getPubUsers({ publisherId: viewAsPublisherId });
    }
  }, [getPubUsers, viewAsPublisherId]);

  useEffect(() => {
    if (user?.role === EUserRole.Admin) {
      getUsers()
        .unwrap()
        .then((res) => dispatch(setUsers(res)))
        .catch((e) => console.log(e));
    }
  }, [dispatch, getUsers, user?.role]);

  return (
    <>
      <WrapperStyled>
        <LeftContainerStyled>
          <InfoBlockStyled>
            <InfoBlockTextStyled>
              <span>{currentPublisher?.name}</span>
              <span className="role">admin</span>
            </InfoBlockTextStyled>
          </InfoBlockStyled>

          <TabsHeader>
            <TabsHeaderItem
              onClick={handleChangeTab(EUserTabs.Active)}
              isActive={currentTab === EUserTabs.Active}
            >
              Users
            </TabsHeaderItem>
            <TabsHeaderItem
              onClick={handleChangeTab(EUserTabs.Inactive)}
              isActive={currentTab === EUserTabs.Inactive}
            >
              Inactive users
            </TabsHeaderItem>
          </TabsHeader>

          <UserListStyled>
            {currentTab === EUserTabs.Active
              ? activeUsers.map((user) => {
                  return (
                    <UserListCardStyled
                      key={user.id}
                      onClick={handleSelectUser(user)}
                      selected={user.id === selectedUser?.id}
                      className="active"
                    >
                      <Icon
                        name={IconType.TYUserListIcon}
                        color={getUserIconColor(user.role)}
                      />
                      <NameStyled
                        selected={user.id === selectedUser?.id}
                      >{`${user.firstName} ${user.lastName}`}</NameStyled>
                    </UserListCardStyled>
                  );
                })
              : sortBy(inActiveUsers, ['lastName']).map((user) => {
                  return (
                    <UserListCardStyled
                      key={user.id}
                      onClick={handleSelectUser(user)}
                      selected={user.id === selectedUser?.id}
                    >
                      <Icon
                        name={IconType.TYUserListIcon}
                        color={Colors.text.main}
                      />
                      <NameInactiveUserStyled
                        selected={user.id === selectedUser?.id}
                      >{`${user.firstName} ${user.lastName}`}</NameInactiveUserStyled>
                    </UserListCardStyled>
                  );
                })}
          </UserListStyled>
        </LeftContainerStyled>

        <RightContainerStyled>
          {selectedUser && (
            <>
              <InfoBlockStyled>
                <Icon
                  name={IconType.TYNewUserIcon}
                  color={Colors.grey['100']}
                  iconWidth={48}
                  iconHeight={48}
                />
                <InfoBlockTextStyled>
                  <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>

                  {userWasDeleted && (
                    <span className="user-status">User was deleted</span>
                  )}

                  {selectedUser.active && !userWasDeleted && (
                    <span className="role">{selectedUser.role}</span>
                  )}

                  {!selectedUser.active && !userWasDeleted && (
                    <div className="deactivated">
                      Inactivation date:
                      <p className="date">
                        {getDeactivatedData(selectedUser.deactivatedAt)}
                      </p>
                    </div>
                  )}
                </InfoBlockTextStyled>

                {isShowSettings && (
                  <Popover
                    placement="leftTop"
                    content={
                      <UserSettings
                        userId={selectedUser.id}
                        setSelectedUser={setSelectedUser}
                        setUserWasDeleted={setUserWasDeleted}
                        countActiveUsers={activeUsers.length}
                        setCurrentTab={setCurrentTab}
                      />
                    }
                    trigger="click"
                    overlayInnerStyle={{ padding: 0, borderRadius: 16 }}
                    showArrow={false}
                    align={{ offset: [50, -10] }}
                  >
                    <InfoBlockIconStyled>
                      <div>
                        <Icon
                          name={IconType.TYSettingsIcon}
                          color={Colors.grey['100']}
                        />
                      </div>
                    </InfoBlockIconStyled>
                  </Popover>
                )}
              </InfoBlockStyled>

              {!userWasDeleted && <UserInfo selectedUser={selectedUser} />}
            </>
          )}
        </RightContainerStyled>
      </WrapperStyled>
    </>
  );
};

export default ManageUsers;
