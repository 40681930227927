import { memo } from 'react';
import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LinkButtonStyled } from './LinkButton.styled';

interface ILinkButtonProps extends Omit<ButtonProps, 'type'> {
  text: string;
  to: string;
  gap?: string;
}

export const LinkButton: React.FC<ILinkButtonProps> = memo((props) => {
  const navigate = useNavigate();
  const { text, to, ...buttonProps } = props;

  const navigateToLink = () => {
    navigate(`/${to}`);
  };

  return (
    <LinkButtonStyled {...buttonProps} type="link" onClick={navigateToLink}>
      {text}
    </LinkButtonStyled>
  );
});

LinkButton.displayName = 'LinkButton';
