import { Icon } from '@/components/common';
import getUserIconColor from '@/helpers/getUserIconColor';
import { sliceTextLenght } from '@/helpers/sliceTextLenght';
import { UserInfo, Username } from './Profile.styled';
import { IconType } from '@/consts';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux';
import { LogoutMenu } from '../LogoutMenu';

export const Profile = () => {
  const { user, viewAsPublisherId } = useSelector((store: RootState) => ({
    user: store.user.data,
    viewAsPublisherId: store.user.viewAsPublisherId,
  }));

  if (!user) {
    return null;
  }

  return (
    <LogoutMenu user={user} asPub={viewAsPublisherId}>
      <UserInfo>
        {user && (
          <Icon
            name={IconType.TYUserIcon}
            color={getUserIconColor(user.role)}
          />
        )}

        <Username>
          {sliceTextLenght(`${user?.firstName} ${user?.lastName}`, 20)}
        </Username>
      </UserInfo>
    </LogoutMenu>
  );
};
