type TImages = Record<string, string>;

export const Images: TImages = {
  exampleLog: require('./images/example-site-logo.png'),
  playerDynamic: require('./images/player-dynamic.png'),
  playerStatic: require('./images/player-static.png'),
  demoVendorSite: require('./images/player-demo-vendor-site.png'),
  mobileDemoVendorSite: require('./images/player-demo-mobile-vendor-site.png'),
  noMobile: require('./images/no-mobile.png'),
  notFound: require('./images/not-found.png'),
};
