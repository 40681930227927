import { NumericFormat } from 'react-number-format';

import { BaseFormInput } from '@/components/common';

import {
  AmountWrapperStyled,
  AmountLabelTextStyled,
} from './PercentInput.styled';

interface IPublisherPercentInput {
  percentage: number;
  setPercentage?: React.Dispatch<React.SetStateAction<number>>;
  readOnly?: boolean;
}

export const PublisherPercentInput: React.FC<IPublisherPercentInput> = ({ percentage, setPercentage, readOnly = false }) => {

  const BaseFormInputProps = {
    id: "name",
    placeholder: "Enter amount here",
    autoComplete: "off",
    maxLength: 255,
    color: "rgba(0, 88, 208, 0.08)",
  };
  const handlePercentInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setPercentage) return
    if (e.target.value !== ' ') {
      setPercentage(parseInt(e.target.value));
    }
  };

  return (
    <AmountWrapperStyled style={{ pointerEvents: readOnly ? 'none' : 'initial' }}>
      <AmountLabelTextStyled>Amount</AmountLabelTextStyled>
      <NumericFormat
        suffix={'%'}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          if (formattedValue == '00%') return false
          return formattedValue === '' || !floatValue || floatValue <= 100;
        }}
        value={percentage}
        customInput={BaseFormInput}
        onChange={handlePercentInput}
        {...BaseFormInputProps}
      ></NumericFormat>
    </AmountWrapperStyled>
  );
}

PublisherPercentInput.displayName = 'PublisherPercentInput';
