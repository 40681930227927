import styled from 'styled-components';
import { Dropdown } from 'antd';
import { Colors } from '@/environment';

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: 1240px) {
    right: 20px;
    top: 20px;
  }
`;

export const DropdownIcon = styled.div`
  padding: 6px 10.5px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  border: 1px solid ${Colors.borders.dropdown};

  &:hover {
    background-color: ${Colors.authBlockBg};
  }
`;
