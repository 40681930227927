import { memo } from 'react';
import { DataStyled, HeaderStyled } from './InfoItem.styled';

interface IInfoItemProps {
  header: string;
  data?: string;
  className?: string;
}

export const InfoItem: React.FC<IInfoItemProps> = memo((props) => {
  const { header, data, className } = props;

  if (!data) {
    return null;
  }

  return (
    <div>
      <HeaderStyled>{header}</HeaderStyled>
      <DataStyled className={className}>{data}</DataStyled>
    </div>
  );
});

InfoItem.displayName = 'InfoItem';
