import { Nullable } from '@/types/generic';
import moment from 'moment';
import 'moment-timezone';

const TODAY = [
  moment().startOf('day').toISOString(),
  moment().endOf('day').toISOString(),
];

const DAY_END = moment().endOf('day').toISOString();

export const generateSortDates = (sort: string): Nullable<string>[] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let dates: Nullable<string>[] = [null, null];

  // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //.tz(tz)

  if (sort === 'all_time') {
    dates = [null, null];
  }

  if (sort === 'today') {
    dates = TODAY;
  }

  if (sort === 'yesterday') {
    dates = [
      moment().subtract(1, 'day').startOf('day').toISOString(),
      moment().subtract(1, 'day').endOf('day').toISOString(),
    ];
  }

  if (sort === 'this_week') {
    dates = [moment().startOf('week').toISOString(), DAY_END];
  }

  if (sort === 'last_week') {
    dates = [
      moment().subtract(1, 'weeks').startOf('week').toISOString(),
      moment().subtract(1, 'weeks').endOf('week').toISOString(),
    ];
  }

  if (sort === 'this_month') {
    dates = [moment().startOf('month').toISOString(), DAY_END];
  }

  if (sort === 'last_month') {
    dates = [
      moment().subtract(1, 'month').startOf('month').toISOString(),
      moment().subtract(1, 'month').endOf('month').toISOString(),
    ];
  }

  if (sort === 'this_year') {
    dates = [moment().startOf('year').toISOString(), DAY_END];
  }

  return dates;
};
