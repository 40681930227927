import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EPlayersType {
  PublisherDashboard = 'PublisherDashboard',
  OwnerDashboard = 'OwnerDashboard',
  ManagePlayers = 'ManagePlayers',
}

interface IPlayerSortParams {
  id: EPlayersType;
  offset: number;
  limit: number;
  orderBy?: string;
  orderAsc?: string;
}

type InitialState = {
  data: Array<IPlayerSortParams>;
};

const initialState: InitialState = {
  data: [],
};

const playerSortParamsSlice = createSlice({
  name: 'playerSortParams',
  initialState,
  reducers: {
    setPlayerSortParams: (
      state,
      { payload }: PayloadAction<IPlayerSortParams>,
    ) => {
      const findItem = state.data.find((item) => item.id === payload.id);

      if (findItem) {
        const updateArray = state.data.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          } else {
            return item;
          }
        });

        return {
          ...state,
          data: updateArray,
        };
      } else {
        return {
          ...state,
          data: [...state.data, payload],
        };
      }
    },
    clearPlayerSortParams: () => initialState,
  },
});

export const { setPlayerSortParams, clearPlayerSortParams } =
  playerSortParamsSlice.actions;

export default playerSortParamsSlice.reducer;
