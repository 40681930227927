import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  BaseFormInput,
  Button,
  FormErrors,
  BaseFormSelect,
} from '@/components/common';
import { DEFAULT_USER_NAME, EInviteUserRole } from '@/consts';
import { Colors } from '@/environment';
import checkFormFieldsFilled from '@/helpers/checkFormFieldsFilled';
import { omitObjectField } from '@/helpers/omitObjectField';
import useBackPage from '@/hooks/useBackPage';
import { useCheckInvitation } from '@/hooks/useCheckInvitation';
import {
  DelimiterStyled,
  StatusMessageStyled,
} from '@/components/ui/Layouts/InviteFormLayout/InviteFormLayout.styled';
import {
  useInviteUserMutation,
  useLazyGetUsersQuery,
} from '@/redux/api/usersApi';
import { setUsers } from '@/redux/slice/users/slice';
import { TIniviteUserData } from '@/types/users-types';
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import userFormSchema from './validation';
import { EInvitationStatus, IInvatationStatus } from '@/interfaces';
import { InviteFormLayout } from '@/components/ui/Layouts/InviteFormLayout';
import { Nullable } from '@/types/generic';

interface IInviteUserFormProps {
  setUserName: Dispatch<SetStateAction<string>>;
  setUserRole: Dispatch<SetStateAction<EInviteUserRole>>;
}

export const InviteUserForm: React.FC<IInviteUserFormProps> = (props) => {
  const [responseErrors, setResponseErrors] = useState<Nullable<string>>(null);

  const { setUserName, setUserRole } = props;

  const onBackPage = useBackPage();
  const dispatch = useDispatch();

  const [inviteUser] = useInviteUserMutation();
  const [getUsers] = useLazyGetUsersQuery();

  const formik = useFormik({
    initialValues: {
      role: EInviteUserRole.Editor,
      firstName: '',
      lastName: '',
      email: '',
      reEnterEmail: '',
    },
    validationSchema: userFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const data: TIniviteUserData = {
        role: values.role,
        email: values.email.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
      };

      inviteUser({ data })
        .unwrap()
        .then(() => {
          setResponseErrors('');
          setInvitationStatus({
            color: Colors.green,
            message: `Invitation was succesfully sent to ${values.email}`,
            status: EInvitationStatus.Submitted,
          });
          getUsers()
            .unwrap()
            .then((res) => dispatch(setUsers(res)))
            .catch((e) => console.log(e));
        })
        .catch((errors) => {
          setResponseErrors(errors.data.message);
        });
    },
  });

  const { values, setFieldValue, submitForm, errors: yupErrors } = formik;

  const handleSelectRoleChange = (value: EInviteUserRole) => {
    setFieldValue('role', value);
  };

  const fieldOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== ' ') {
      setFieldValue(e.target.id, e.target.value.replace(/\s+/g, ' '));
    }
  };

  useEffect(() => {
    const userName = `${values.firstName} ${values.lastName}`.trim();

    if (userName) {
      setUserName(`${values.firstName} ${values.lastName}`);
    } else {
      setUserName(DEFAULT_USER_NAME);
    }

    setUserRole(values.role);
  }, [
    values.firstName,
    values.lastName,
    values.role,
    setUserName,
    setUserRole,
  ]);

  const { invitationStatus, setInvitationStatus } = useCheckInvitation(
    values.email,
  );

  const isFilledRequiredFields = checkFormFieldsFilled(values);
  const displayedFormErrors = omitObjectField('reEnterEmail', yupErrors);

  const getSubmitButtonText = useCallback(
    (invitationStatus: IInvatationStatus | null, role: string) => {
      if (invitationStatus?.status === EInvitationStatus.Submitted) {
        return `Back to Manage ${role}`;
      }
      if (invitationStatus?.status === EInvitationStatus.SentEarlier) {
        return 'Send again';
      }

      return 'Send invitation';
    },
    [],
  );

  useEffect(() => {
    if (yupErrors) {
      setResponseErrors('');
    }
  }, [yupErrors]);

  return (
    <InviteFormLayout>
      <Form.Item>
        <BaseFormSelect
          id="role"
          label="Role"
          value={values.role}
          style={{ width: 120 }}
          onChange={handleSelectRoleChange}
          className="select-invite-user"
          options={[
            {
              value: `${EInviteUserRole.Admin}`,
              label: 'Admin',
            },
            {
              value: `${EInviteUserRole.Editor}`,
              label: 'Editor',
            },
            {
              value: `${EInviteUserRole.Viewer}`,
              label: 'Viewer',
            },
          ]}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="firstName"
          label="First Name"
          value={values.firstName}
          onChange={fieldOnChange}
          color={Colors.inputBlue}
          status={yupErrors.firstName && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="lastName"
          label="Last Name"
          value={values.lastName}
          onChange={fieldOnChange}
          color={Colors.inputBlue}
          status={yupErrors.lastName && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="email"
          label="Email address"
          value={values.email}
          onChange={fieldOnChange}
          color={Colors.inputBlue}
          status={yupErrors.email && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <Form.Item>
        <BaseFormInput
          id="reEnterEmail"
          label="Re-enter Email address"
          value={values.reEnterEmail}
          onChange={fieldOnChange}
          color={Colors.inputBlue}
          status={yupErrors.reEnterEmail && 'error'}
          maxLength={255}
        />
      </Form.Item>

      <DelimiterStyled />

      <Button
        text={getSubmitButtonText(invitationStatus, 'Users')}
        type="primary"
        height="40px"
        onClick={
          invitationStatus?.status === EInvitationStatus.Submitted
            ? onBackPage
            : submitForm
        }
        position="center"
        borderRadius="12px"
        disabled={
          !isFilledRequiredFields ||
          invitationStatus?.status === EInvitationStatus.UserAlready
        }
        disabledColor={Colors.grey[100]}
      />

      {invitationStatus && (
        <StatusMessageStyled color={invitationStatus.color}>
          {invitationStatus.message}
        </StatusMessageStyled>
      )}

      {responseErrors && (
        <StatusMessageStyled color={Colors.error} fontWeight="400">
          {responseErrors}
        </StatusMessageStyled>
      )}

      <FormErrors errors={displayedFormErrors} gapTop={20} fontSize={12} />
    </InviteFormLayout>
  );
};
