import { Colors, Images } from '@/environment';
import { TOption, TPlayerInfo } from '@/interfaces';

export enum IconType {
  TYArrowDown = 'TYArrowDown',
  TYArrowUp = 'TYArrowUp',
  TYClock = 'TYClock',
  TYDashboardIcon = 'TYDashboardIcon',
  TYDropdownArrow = 'TYDropdownArrow',
  TYDropdownDots = 'TYDropdownDots',
  TYFilterIcon = 'TYFilterIcon',
  TYMainLogoIcon = 'TYMainLogoIcon',
  TYManagePlayersIcon = 'TYManagePlayersIcon',
  TYManageUsersIcon = 'TYManageUsersIcon',
  TYPlusIcon = 'TYPlusIcon',
  TYSearchIcon = 'TYSearchIcon',
  TYSettingsIcon = 'TYSettingsIcon',
  TYUserIcon = 'TYUserIcon',
  TYSelectIcon = 'TYSelectIcon',
  TYPlayerBack = 'TYPlayerBack',
  TYPlayerLoading = 'TYPlayerLoading',
  TYPlayerPause = 'TYPlayerPause',
  TYPlayerForward = 'TYPlayerForward',
  TYPlayerVolume = 'TYPlayerVolume',
  TYNewUserIcon = 'TYNewUserIcon',
  TYPlayerPlayback = 'TYPlayerPlayback',
  TYPlayerPreferences = 'TYPlayerPreferences',
  TYCopyIcon = 'TYCopyIcon',
  TYCopiedIcon = 'TYCopiedIcon',
  TYUserListIcon = 'TYUserListIcon',
  TYEmailMathesIcon = 'TYEmailMathesIcon',
  TYShowPasswordIcon = 'TYShowPasswordIcon',
  TYTableDropdownIcon = 'TYTableDropdownIcon',
  TYTableDropdownPause = 'TYTableDropdownPause',
  TYTableDropdownPlay = 'TYTableDropdownPlay',
  TYPublisherArrowDown = 'TYPublisherArrowDown',
  TYPublisherArrowUp = 'TYPublisherArrowUp',
  TYTableStaticType = 'TYTableStaticType',
  TYTableDynamicType = 'TYTableDynamicType',
  TYManagePublishers = 'TYManagePublishers',
  TYTimeSortCheck = 'TYTimeSortCheck',
  TYCloseXMark = 'TYCloseXMark',
  TYCalendar = 'TYCalendar',
  TYPlayerActivateMask = 'TYPlayerActivateMask',
  TYPlayerDynamicClose = 'TYPlayerDynamicClose',
  TYPlayerDynamicPreferences = 'TYPlayerDynamicPreferences',
  TYNotificationCheck = 'TYNotificationCheck',
  TYPasswordSuffix = 'TYPasswordSuffix',
  TYQustion = 'TYQustion',
  TYQustionFilled = 'TYQustionFilled',
  TYTutorialsIcon = 'TYTutorialsIcon',
  TYRevenueIcon = 'TYRevenueIcon',
  TYDownloadIcon = 'TYDownloadIcon',
}

export interface DashboardInfoType {
  data: object;
  type_title: string;
  player_name: string;
  status: string;
  audio_plays: string;
  total_play_time: string;
  avg_play_time: string;
}

export interface PlayerInfoType {
  data: object;
  download: string;
  language: string;
  voice: string;
  speed: string;
  rating: string;
}

export const playersInfo: TPlayerInfo[] = [
  {
    title: 'Static player',
    description:
      'The Static Player will need to be embedded within a site page. You can specify the dimensions and then embed the code within your page templates. You will need to insert a few lines of code on the page header and within the body. The Static Player is responsive to desktop and mobile. ',
    buttonText: 'Choose static',
    image: Images.playerStatic,
    value: 'static',
  },
  {
    title: 'Dynamic player',
    description:
      'The Dynamic Player will display an audio play button on the bottom left of the page. Upon pressing the audio play button, a larger overlay will appear on the bottom portion of the page and carry an audio player. You will need to insert a few lines of code on the page header only. The Dynamic Player is responsive to desktop and mobile. ',
    buttonText: 'Choose dynamic',
    image: Images.playerDynamic,
    value: 'dynamic',
  },
];

export const playerPreviewOptions: TOption[] = [
  {
    value: 'desktop',
    title: 'Desktop',
  },
  {
    value: 'mobile',
    title: 'Mobile',
  },
];

export const playerTypeOptions: TOption[] = [
  {
    value: 'dynamic',
    title: 'Dynamic',
  },
  {
    value: 'static',
    title: 'Static',
  },
];

// export const playerSwitchOptions: TOption[] = [
//   {
//     value: 'download',
//     title: 'Download',
//     tooltipText:
//       'Users can download the audio file in MP4 format to use later.',
//   },
//   {
//     value: 'language',
//     title: 'Languages',
//     tooltipText:
//       'We currently support several languages such as Spanish and French and working on adding more.',
//   },
//   {
//     value: 'voice',
//     title: 'Voice change',
//     tooltipText: 'Users can select a Male or Female voice.',
//   },
//   {
//     value: 'speed',
//     title: 'Playback speed',
//     tooltipText:
//       'Users can select slower or faster playback speeds (such as 0.5x or 1.5x).',
//   },
//   {
//     value: 'rating',
//     title: 'Ratings',
//     tooltipText:
//       'Users can rate thumbs up or thumbs down on the quality of the audio.',
//   },
// ];

export enum EUserRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
  Owner = 'owner',
}

export enum EInviteUserRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
}

export enum EPlayerStatus {
  Inactive = 'inactive',
  Active = 'active',
  Archived = 'archived',
}

export const MAX_COUNT_INVITED_USERS = 5;

export const basePlayerValues = {
  name: '',
  type: 'static',
  color: Colors.player.colors.black,
  status: 'active',
  download: false,
  language: false,
  voice: false,
  speed: false,
  rating: false,
  width: 600,
  isAdAllowed: false,
};
export const basePublisherBillingInfo = {
  name: '',
  email: '',
  address: '',
  city: '',
  country: '',
  zip: '',
  state: '',
  taxId: '',
  phone: '',
};

export const createPlayerStepTitles = [
  'Select a player type',
  'Setup a player',
  'Get Player Embedded Code',
];

export const DEFAULT_PUBLISHER_NAME = 'New publisher';

export const DEFAULT_USER_NAME = 'New user';

export const PAGINATION_STEP = 20;

export const playerHovers: Record<string, string> = {
  ['#FF6600']: '#FFCC99', // ORANGE
  ['#FF3300']: '#FFCCCC', // RED
  ['#9900CC']: '#FFCCFF', // PURPLE
  ['#0BADAD']: '#99FFFF', // TEAL
  ['#06AA2F']: '#93E9B0', // GREEN
  ['#0058D0']: '#D2E4FD', // BLUE
  ['#000000']: '#DFDFE3', // BLACK
  ['#717171']: '#DFDFE3', // GREY
};

export const playerActiveOptions: Record<string, string> = {
  ['#FF6600']: '#FFB088', // ORANGE
  ['#FF3300']: '#FF9999', // RED
  ['#9900CC']: '#FBA6FD', // PURPLE
  ['#0BADAD']: '#46E4E4', // TEAL
  ['#06AA2F']: '#5ED87C', // GREEN
  ['#0058D0']: '#99CCFF', // BLUE
  ['#000000']: '#A1A1AA', // BLACK
  ['#717171']: '#DFDFE3', // GREY
};
