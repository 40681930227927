import styled from 'styled-components';

export const LayoutWrap = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  height: calc(100vh - 75px);
  max-width: 1380px;
  box-sizing: border-box;
  gap: 25px;
  width: 100%;
  padding-right: 40px;

  @media (max-width: 1240px) {
    gap: 20px;
    padding-right: 20px;
  }
`;
