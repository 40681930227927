import { EUserRole } from '@/consts';
import { Icon } from '../../common/Icon';
import { StyledLink, Wrapper } from './Navigation.styled';
import { TNavItem } from '@/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux';
import useNavigationData from '@/hooks/useNavigationData';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';

export const Navigation = () => {
  const { user, viewAsPublisherId } = useSelector((store: RootState) => ({
    user: store.user.data,
    viewAsPublisherId: store.user.viewAsPublisherId,
  }));

  const navData: TNavItem[] = useNavigationData(
    user?.role === EUserRole.Admin,
    Boolean(user?.isRoot && !checkLengthIsPositive(viewAsPublisherId)),
  );

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      {navData.map((nav) => (
        <StyledLink key={nav.path} to={nav.path}>
          <Icon name={nav.icon} />
          {nav.title}
        </StyledLink>
      ))}
    </Wrapper>
  );
};
