import { Button, Icon, InfoItem } from '@/components/common';
import {
  MainUserInfo,
  PublisherSettings,
  UserInfo,
  PubSelectItem,
} from '@/components/ui';
import { BilingFormBase } from '@/components/ui';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { RootState } from '@/redux';
import { useLazyGetOwnerPublishersQuery } from '@/redux/api/publishersApi';
import {
  setManagePubsData,
  setMoreManagePubsData,
} from '@/redux/slice/publishers/slice';
import {
  IPublisherBillingInfo, IPublisherCommission,
} from '@/types/publisher-types';

import { IUser } from '@/types/users-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InfoBlockStyled,
  InfoBlockTextStyled,
  LeftContainerStyled,
  RightContainerStyled,
  TabsHeader,
  TabsHeaderItem,
  UserListStyled,
  WrapperStyled,
  InfoBlockIconStyled,
  OwnerLogoPlaceholder,
} from '@/components/ui/Layouts/ManageLayout/ManageLayout.styled';
import { format } from 'date-fns';
import { Nullable } from '@/types/generic';
import { Popover } from 'antd';
import { SetupsHeader } from './ManagePublishers.styled';

export enum EPublisherTabs {
  Active = 'active',
  Inactive = 'inactive',
}

export enum ESinglePublisherTabs {
  Main = 'main',
  Billing = 'billing',
}

export interface IMainUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  date: string;
  website: string;
  role: string;
  pubName: string;
  active: boolean;
  publishersBillingInfo: Nullable<IPublisherBillingInfo>;
  publisherCommission: Nullable<IPublisherCommission>;
}

const ManagePublishers = () => {
  const [mainUser, setMainUser] = useState<Nullable<IMainUser>>(null);
  const [selectedUser, setSelectedUser] = useState<Nullable<IUser>>(null);
  const [currentTab, setCurrentTab] = useState<EPublisherTabs>(
    EPublisherTabs.Active,
  );

  const [currentSinglePublisherTab, setCurrentSinglePublisherTab] = useState<ESinglePublisherTabs>(
    ESinglePublisherTabs.Main,
  );

  const {
    user,
    publishers,
    inactiveOffset,
    activeOffset,
    limit,
    activeTotal,
    inactiveTotal,
  } = useSelector((store: RootState) => ({
    user: store.user.data,
    publishers: store.publishers.managePubsData.items,
    inactiveOffset: store.publishers.managePubsData.inactiveOffset,
    activeOffset: store.publishers.managePubsData.activeOffset,
    limit: store.publishers.managePubsData.limit,
    activeTotal: store.publishers.managePubsData.activeTotal,
    inactiveTotal: store.publishers.managePubsData.inactiveTotal,
  }));

  const { setBreadcrumbs } = useBreadcrumbs();
  const dispatch = useDispatch();

  const [getPublishers] = useLazyGetOwnerPublishersQuery();

  const formatDate = (date: Nullable<string>) => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
    return 'Date not found';
  };
  // TODO refactor for use current User and current publisher
  const getMainUser = useCallback(
    (publsiherId: string, userInfo: IUser) => {
      const publisher = publishers.find((pub) => pub.id === publsiherId);
      if (publisher) {
        setMainUser({
          id: publisher.id,
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          phone: userInfo?.phone,
          email: userInfo?.email,
          date: formatDate(publisher.createdAt),
          website: publisher.website,
          role: userInfo?.role,
          pubName: publisher.name,
          active: publisher.active,
          publishersBillingInfo: publisher.publishersBillingInfo ? publisher.publishersBillingInfo : null,
          publisherCommission: publisher.publisherCommission

        });
      }
    },
    [publishers],
  );
  const selectedPublisher = useMemo(() => {
    if (!mainUser) return null
    return publishers.find((pub) => pub.id === mainUser.id);

  }, [mainUser, publishers])

  const fetchMore = useCallback(
    (active: boolean) => {
      return () => {
        getPublishers({
          active,
          offset: active ? activeOffset + 20 : inactiveOffset + 20,
          limit: 20,
        })
          .unwrap()
          .then((res) => {
            dispatch(
              setMoreManagePubsData({
                items: res.items,
                activeOffset: active ? activeOffset + 20 : activeOffset,
                inactiveOffset: !active ? inactiveOffset + 20 : inactiveOffset,
                activeTotal: active ? res.total : activeTotal,
                inactiveTotal: !active ? res.total : inactiveTotal,
                limit,
              }),
            );
          });
      };
    },
    [
      getPublishers,
      activeOffset,
      inactiveOffset,
      dispatch,
      activeTotal,
      inactiveTotal,
      limit,
    ],
  );

  useEffect(() => {
    const breadcrumbsData = [
      {
        label: 'Manage Publishers',
      },
    ];

    if (mainUser) {
      setBreadcrumbs([...breadcrumbsData, { label: `${mainUser.pubName}` }]);
    } else if (selectedUser) {
      setBreadcrumbs([
        ...breadcrumbsData,
        { label: `${selectedUser?.firstName} ${selectedUser?.lastName}` },
      ]);
    } else {
      setBreadcrumbs(breadcrumbsData);
    }
  }, [mainUser, selectedUser, setBreadcrumbs]);

  useEffect(() => {
    const isActive = currentTab === EPublisherTabs.Active;
    if (isActive && publishers.length === 0) {
      getPublishers({
        active: isActive,
        offset: isActive ? activeOffset : inactiveOffset,
        limit,
      })
        .unwrap()
        .then((res) => {
          dispatch(
            setManagePubsData({ items: res.items, activeTotal: res.total }),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentSinglePublisherTab(ESinglePublisherTabs.Main)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainUser, selectedUser]);

  useEffect(() => {
    const inactive = publishers.filter((pub) => !pub.active);
    const isActive = currentTab === EPublisherTabs.Active;
    if (!isActive && inactive.length === 0) {
      getPublishers({
        active: isActive,
        offset: inactiveOffset,
        limit,
      })
        .unwrap()
        .then((res) => {
          dispatch(
            setMoreManagePubsData({
              items: res.items,
              inactiveOffset: inactiveOffset,
              inactiveTotal: res.total,
              limit: 20,
            }),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const filteredPublishers = useMemo(() => {
    return publishers
      .filter((pub) => {
        if (currentTab === EPublisherTabs.Active)
          return pub.active
        if (currentTab === EPublisherTabs.Inactive)
          return !pub.active
      })
      ?.map((publisher) => (
        <PubSelectItem
          key={publisher.id}
          id={publisher.id}
          name={publisher.name}
          pubActive={publisher.active}
          isMonetizationAllowed={publisher.isMonetizationAllowed}
          setSelectedUser={setSelectedUser}
          getMainUser={getMainUser}
          setMainUser={setMainUser}
          mainUser={mainUser}
          selectedUser={selectedUser}
        />
      ))
  }, [currentTab, getMainUser, mainUser, publishers, selectedUser])

  const isShowMoreBtn = useMemo(() => {
    if (currentTab === EPublisherTabs.Active)
      return publishers.filter((pub) => pub.active).length < activeTotal
    if (currentTab === EPublisherTabs.Inactive)
      return publishers.filter((pub) => !pub.active).length < inactiveTotal

    return false
  }, [activeTotal, currentTab, inactiveTotal, publishers])

  return (
    <>
      <WrapperStyled>
        <LeftContainerStyled>
          <InfoBlockStyled>
            <OwnerLogoPlaceholder>
              {user?.firstName.charAt(0)} {user?.lastName.charAt(0)}
            </OwnerLogoPlaceholder>
            <InfoBlockTextStyled>
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
              <span className="role">Owner</span>
            </InfoBlockTextStyled>
          </InfoBlockStyled>

          <TabsHeader>
            <TabsHeaderItem
              onClick={() => setCurrentTab(EPublisherTabs.Active)}
              isActive={currentTab === EPublisherTabs.Active}
            >
              Publishers
            </TabsHeaderItem>
            <TabsHeaderItem
              onClick={() => setCurrentTab(EPublisherTabs.Inactive)}
              isActive={currentTab === EPublisherTabs.Inactive}
            >
              Inactive publishers
            </TabsHeaderItem>
          </TabsHeader>

          <UserListStyled>
            {filteredPublishers}
            {isShowMoreBtn && (
              <Button
                text="Load more"
                borderRadius="12px"
                onClick={fetchMore(currentTab === EPublisherTabs.Active)}
                backgroundColor={Colors.white}
                textColor={Colors.blue}
                border={`2px solid ${Colors.blue}`}
                position="center"
                width={'205px'}
                height={'36px'}
                backgroundColorHover={Colors.authBlockBg}
              />
            )}
          </UserListStyled>

        </LeftContainerStyled>

        <RightContainerStyled>
          {mainUser && (
            <>
              <InfoBlockStyled>
                <InfoBlockTextStyled>
                  <span>{mainUser.pubName}</span>
                  <span className="role">Publisher</span>

                </InfoBlockTextStyled>
                {mainUser.id !== user?.publisherId && (
                  <Popover
                    placement="leftTop"
                    content={
                      <PublisherSettings
                        publisherId={mainUser.id}
                        setCurrentTab={setCurrentTab}
                      />
                    }
                    trigger="click"
                    overlayInnerStyle={{
                      padding: 0,
                      borderRadius: 16,
                    }}
                    showArrow={false}
                    align={{ offset: [50, -10] }}
                  >
                    <InfoBlockIconStyled>
                      <div>
                        <Icon
                          name={IconType.TYSettingsIcon}
                          color={Colors.grey[100]}
                        />
                      </div>
                    </InfoBlockIconStyled>
                  </Popover>
                )}
              </InfoBlockStyled>
              <TabsHeader isSinglePublisherTabs={true}>
                <TabsHeaderItem
                  onClick={() => setCurrentSinglePublisherTab(ESinglePublisherTabs.Main)}
                  isActive={currentSinglePublisherTab === ESinglePublisherTabs.Main}
                >
                  Main info
                </TabsHeaderItem>
                {mainUser.publishersBillingInfo && (
                  <TabsHeaderItem
                    onClick={() => setCurrentSinglePublisherTab(ESinglePublisherTabs.Billing)}
                    isActive={currentSinglePublisherTab === ESinglePublisherTabs.Billing}
                  >
                    Billing info
                  </TabsHeaderItem>
                )}

              </TabsHeader>

              {currentSinglePublisherTab === ESinglePublisherTabs.Billing && mainUser.publishersBillingInfo && (
                <BilingFormBase
                  footer={
                    selectedPublisher && selectedPublisher.isMonetizationAllowed && selectedPublisher.publisherCommission &&
                    <div>
                      <SetupsHeader>Setups</SetupsHeader>
                      {<InfoItem header="Adv. Revenue amount" data={`${selectedPublisher.publisherCommission.percent}%`} />}
                    </div>
                  }
                  values={mainUser.publishersBillingInfo}
                />
              )}
              {currentSinglePublisherTab === ESinglePublisherTabs.Main && <MainUserInfo selectedUser={mainUser} />}
            </>
          )}

          {selectedUser && (
            <>
              <InfoBlockStyled>
                <Icon
                  name={IconType.TYNewUserIcon}
                  color={Colors.grey[100]}
                  iconWidth={48}
                  iconHeight={48}
                />
                <InfoBlockTextStyled>
                  <span>{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</span>
                </InfoBlockTextStyled>
              </InfoBlockStyled>

              <UserInfo selectedUser={selectedUser} />
            </>
          )}
        </RightContainerStyled>
      </WrapperStyled>
    </>
  );
};

export default ManagePublishers;
