import { Colors } from '@/environment';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${Colors.contentBg};
  border-radius: 24px 24px 0px 0px;
  width: calc(100% - 250px - 40px);
  max-width: 1050px;
  box-sizing: border-box;
  padding: 40px 30px 0px 20px;
  box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12);
  position: relative;

  @media (max-width: 1240px) {
    width: calc(100% - 220px - 20px);
    padding: 30px 20px 0px 10px;
  }
`;
