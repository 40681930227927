import * as Yup from 'yup';
import { IPublisherBillingInfoForm } from '../BilingFormBase';

const BILLING_VALIDATION_SCHEMA: Yup.SchemaOf<IPublisherBillingInfoForm> =
  Yup.object({
    name: Yup.string()
      .trim()
      .max(255)
      .required('Billing Contact Name is required field'),
    email: Yup.string()
      .trim()
      .email('Invalid email address')
      .max(255)
      .required('Contact Email is required field'),
    address: Yup.string().trim().max(255).required('Address is required field'),
    city: Yup.string().trim().max(255).required('City is required field'),
    country: Yup.string().trim().max(255).required('Country is required field'),
    zip: Yup.string().trim().max(255).notRequired(),
    state: Yup.string().trim().max(255).notRequired(),
    taxId: Yup.string()
      .trim()
      .max(255)
      .required('Tax ID or Social Security is required field'),
    phone: Yup.string()
      .matches(
        /^([+]?[1]+)\s?\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
        'Phone number is not valid',
      )
      .required('Contact Phone is required field'),
  });

export default BILLING_VALIDATION_SCHEMA;
