import { Separator, StyledRangePicker } from './RangePicker.styled';
import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RangeValue } from '@/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { Icon } from '../Icon';
import { IconType } from '@/consts';
import locale from 'antd/es/date-picker/locale/en_US';
import { generateSortDates } from '@/helpers/generateSortDates';

interface IRangePickerProps {
  onChange: (value: RangeValue) => void;
  isOpen: boolean;
  dates: RangeValue;
  selectValue: string;
}

type TRange = 'start' | 'end';

interface IRangeInfo {
  range: TRange;
}

interface IRangeInfoCollected {
  start: boolean;
  end: boolean;
}

export const RangePicker: React.FC<IRangePickerProps> = ({
  onChange,
  isOpen,
  dates,
  selectValue,
}) => {
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  const [sndDates, setSndDates] = useState<RangeValue>(null);
  const [clickCount, setClickCount] = useState<number>(0);

  const rangeInfo = useRef<IRangeInfoCollected>({
    start: false,
    end: false,
  });

  const currDates = useMemo(() => {
    if (selectValue !== 'custom' && selectValue !== 'all_time') {
      // !NEED FOR CORRECT TYPES CHECK, BOTH VARIANT HAVE SAME RESULT
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      const range: RangeValue = generateSortDates(selectValue).map((isoDate) =>
        dayjs(isoDate),
      );
      if (selectValue === 'all_time') {
        return null;
      }
      return range;
    }
    return dates;
  }, [dates, selectValue]);

  const handleChange = (
    curDates: RangeValue,
    _dateStrings: string[],
    info: IRangeInfo,
  ) => {
    rangeInfo.current = { ...rangeInfo.current, [info.range]: true };

    const rangeInfoCheck =
      Object.values(rangeInfo.current).every((item) => item === true) &&
      curDates?.every((date) => date !== null);

    if (curDates && curDates.length === 2 && rangeInfoCheck) {
      onChange(curDates);
      setClickCount(0);
      rangeInfo.current = { ...rangeInfo.current, start: false, end: false };
    }
  };

  // TODO: FIND BETTER SOLUTION
  const handleCalendarChange = (curDates: RangeValue) => {
    if (clickCount === 0) {
      setClickCount(1);
    }
    if (clickCount === 1) {
      setClickCount(2);
    }
    setSndDates(curDates);
  };

  function handleOpenPicker() {
    setIsPickerOpen((prev) => !prev);
  }

  function generateDisabledDates(current: Dayjs) {
    const currDate = moment();
    return current && current > moment(currDate, 'YYYY-MM-DD');
  }

  function renderDate(current: Dayjs) {
    return <div className="ant-picker-cell-inner">{current.date()}</div>;
  }

  useEffect(() => {
    if (!isOpen) {
      setClickCount(0);
      rangeInfo.current = { ...rangeInfo.current, start: false, end: false };
      setIsPickerOpen(false);
    }
  }, [isOpen]);

  // TODO: FIND BETTER SOLUTION
  useEffect(() => {
    if (
      sndDates !== null &&
      sndDates[0] !== null &&
      sndDates[1] !== null &&
      clickCount === 2
    ) {
      onChange(sndDates);
      rangeInfo.current = { ...rangeInfo.current, start: false, end: false };
      setSndDates(null);
      setClickCount(0);
    }
  }, [clickCount, onChange, sndDates]);

  return (
    <StyledRangePicker
      onClick={handleOpenPicker}
      value={currDates}
      open={isPickerOpen}
      bordered={false}
      format={'DD/MM/YYYY'}
      suffixIcon={<Icon name={IconType.TYCalendar} />}
      placeholder={['DD / MM / YY', 'DD / MM / YY']}
      separator={<Separator>-</Separator>}
      onCalendarChange={handleChange}
      onChange={handleCalendarChange}
      disabledDate={generateDisabledDates}
      popupClassName="rangePicker"
      allowClear={false}
      dateRender={renderDate}
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          shortWeekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
      }}
    />
  );
};
