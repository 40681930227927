import { BaseFormSwitch } from '@/components/common';
import { RootState } from '@/redux';
import { useToggleActiveStateOwnerPubMutation } from '@/redux/api/publishersApi';
import { updateManagePubsActiveData } from '@/redux/slice/publishers/slice';
import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPublisherTabs } from '../../../../pages/ManagePublishers';
import { PublisherSettingsMonetization } from '../MonetizationSettings'
import {
  StatusContainer,
  PublisherSettingsWrapper,
} from './PublisherSettings.styled';
import usePubMonetization from '@/hooks/usePubMonetization';
import { PublisherPercentInput } from '../MonetizationSettings/PercentInput'

interface UserSettingsProps {
  publisherId: string;
  setCurrentTab: Dispatch<SetStateAction<EPublisherTabs>>;
}

export const PublisherSettings: React.FC<UserSettingsProps> = memo(
  ({ publisherId, setCurrentTab }) => {
    const [isDisableMonetizationConfirmOpen, setisDisableMonetizationConfirmOpen] = useState(false)

    const [isMonetizationSettingsOpen, setIsMonetizationSettingsOpen] = useState<boolean>(false)
    const { publishers } = useSelector((store: RootState) => ({
      publishers: store.publishers.managePubsData.items,
    }));

    const findPub = publishers.find((pub) => pub.id === publisherId);

    const [togglePubActive] = useToggleActiveStateOwnerPubMutation()
    const { isPubMonetizationAllowed, publisherCommissionPercent } = usePubMonetization(publisherId)

    const dispatch = useDispatch();

    const pubStatus = useMemo(() => {
      if (publisherId && findPub) {
        return findPub.active;
      }
      return false;
    }, [findPub, publisherId]);

    const changeStatus = () => {
      togglePubActive({
        data: { active: !pubStatus },
        publisherId: publisherId,
      })
        .unwrap()
        .then((res) => {
          dispatch(updateManagePubsActiveData(res));
          if (res.active) {
            setCurrentTab(EPublisherTabs.Active);
          } else {
            setCurrentTab(EPublisherTabs.Inactive);
          }
        });
    };

    const onToggleMonetization = async () => {
      setisDisableMonetizationConfirmOpen(false)
      if (isPubMonetizationAllowed) {
        setisDisableMonetizationConfirmOpen(true)
        setIsMonetizationSettingsOpen(true)
      } else {
        setIsMonetizationSettingsOpen(true)
      }

    }

    return (
      <PublisherSettingsWrapper>
        {isMonetizationSettingsOpen ?
          <PublisherSettingsMonetization
            closeSettings={() => setIsMonetizationSettingsOpen(false)}
            publisherId={publisherId}
            isDisableConfirm={isDisableMonetizationConfirmOpen}
          />
          : <>
            <StatusContainer>
              <span className="status">
                Status {findPub?.active ? `(active)` : `(inactive)`}
              </span>
              <BaseFormSwitch
                value={pubStatus}
                name="test"
                onChange={changeStatus}
              />
            </StatusContainer>
            <StatusContainer>
              <span className="status">
                Revenue
              </span>
              <BaseFormSwitch
                value={isPubMonetizationAllowed}
                name="test"
                onChange={onToggleMonetization}
              />
            </StatusContainer>
            {isPubMonetizationAllowed && publisherCommissionPercent && (
              <StatusContainer onClick={() => setIsMonetizationSettingsOpen(true)}>
                <PublisherPercentInput readOnly percentage={publisherCommissionPercent} />
              </StatusContainer>
            )}
          </>

        }

      </PublisherSettingsWrapper>
    );
  },
);

PublisherSettings.displayName = 'PublisherSettings';
