import styled from 'styled-components';

export const MainUserInfoWrapper = styled.div`
  padding: 40px 40px 0 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
