import { ErrorBoundary } from './ErrorBoundary';
import { Router } from './Router';
import { Provider } from 'react-redux';
import { store } from '@/redux';

export const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router />
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
