import { Colors } from '@/environment';
import styled from 'styled-components';

export const InviteLayoutWrapper = styled.div`
  background: rgba(252, 254, 255, 0.7);
  border-radius: 24px 24px 0px 0px;
  width: 420px;
  box-sizing: border-box;
  padding: 40px 0px 0px 0px;
  box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12);
`;

export const ScrollWrapper = styled.div`
  width: 99%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    margin-top: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
    border-top: 20px solid ${Colors.scrollbar};
    border-bottom: 20px solid ${Colors.scrollbar};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }
`;

export const Description = styled.div`
  width: 313px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.dark};
  padding-left: 40px;
  margin-bottom: 40px;
`;

export const Role = styled.p`
  margin: 0;

  &::first-letter {
    text-transform: capitalize;
  }
`;
