
import { BilingFormBaseLayout, DelimiterStyled, ReadableList, ReadableTitle, ReadableText } from './BilingFormBase.styled'

import {
  BaseFormInput,
} from '@/components/common';
import { InputProps } from 'antd';

import { Colors } from '@/environment';
import { Form } from 'antd';
import { FormikErrors } from 'formik';
import { PatternFormat } from 'react-number-format';
import { IPublisherBillingInfo } from '@/types/publisher-types';

export type IPublisherBillingInfoForm = Pick<IPublisherBillingInfo, 'name' | 'email' | 'phone' | 'address' | 'city' | 'state' | 'zip' | 'country' | 'taxId'>

type IBillingFormFields = (keyof IPublisherBillingInfoForm)[];
type IInputStatus = InputProps['status']

interface IBilingFormBase {
  values: IPublisherBillingInfoForm,
  setFieldValue?: (field: string, value: unknown, shouldValidate?: boolean | undefined) => Promise<FormikErrors<IPublisherBillingInfoForm>> | Promise<void>;
  formValidationErrors?: FormikErrors<IPublisherBillingInfoForm>;
  footer?: React.ReactNode;
}

const idToName: Record<keyof IPublisherBillingInfoForm, string> = {
  name: 'Billing Contact Name:',
  email: 'Contact Email:',
  phone: 'Contact Phone:',
  address: 'Mailing Address:',
  city: 'City:',
  state: 'State (Optional):',
  zip: 'Postal/ZIP (Optional):',
  country: 'Country:',
  taxId: 'Tax ID or Social Security:',
}

export const BilingFormBase: React.FC<IBilingFormBase> = (props) => {
  const { setFieldValue, values, formValidationErrors, footer } = props
  const fieldsList: IBillingFormFields = ['email', 'phone', 'address', 'city', 'state', 'zip', 'country', 'taxId']

  if (!setFieldValue || !formValidationErrors) {
    return <ReadableList>
      {
        fieldsList.map((filedName) => {
          return (<div key={filedName}>
            <ReadableTitle>{idToName[filedName]}</ReadableTitle>
            <ReadableText>{values[filedName]}</ReadableText>
          </div>
          )
        })
      }
      {footer}
    </ReadableList>
  }

  const fieldOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.id, e.target.value)
  };

  return <BilingFormBaseLayout>
    {
      fieldsList.map((filedName) => {
        const status: IInputStatus = formValidationErrors[filedName] && 'error'
        const generalProps = {
          id: filedName,
          value: values[filedName],
          onChange: fieldOnChange,
          label: idToName[filedName],
          color: Colors.inputBlue,
          status
        }
        return (
          <Form.Item key={filedName}>
            {filedName === 'phone' ? <PatternFormat
              customInput={BaseFormInput}
              format="+1 (###) ###-####"
              mask={'_'}
              autoComplete="nofill"
              {...generalProps}
            /> : <BaseFormInput
              maxLength={255}
              {...generalProps}
            />}

          </Form.Item>
        )
      })
    }
    <DelimiterStyled></DelimiterStyled>
    {footer}

  </BilingFormBaseLayout>
};
