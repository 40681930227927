import { ReactNode } from 'react';
import { Icon } from '@/components/common';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import {
  InfoBlockStyled,
  InfoBlockTextStyled,
} from '../ManageLayout/ManageLayout.styled';
import {
  Description,
  Role,
  ScrollWrapper,
  InviteLayoutWrapper,
} from './InviteLayout.styled';

interface IInviteLayout {
  name: string;
  role: string;
  children: ReactNode;
}

export const InviteLayout: React.FC<IInviteLayout> = (props) => {
  const { name, role, children } = props;

  return (
    <InviteLayoutWrapper>
      <ScrollWrapper>
        <Description>
          You are about to add another user to your team, please enter a valid
          email address. The user will get an invitation to create an account
          and once approved that account will be connected to your company
          account. You will be able to manage this user from this dashboard for
          future needs.
        </Description>

        <InfoBlockStyled>
          <Icon
            name={IconType.TYNewUserIcon}
            color={Colors.grey[100]}
            iconWidth={48}
            iconHeight={48}
          />

          <InfoBlockTextStyled>
            <span>{name}</span>
            <Role>{role}</Role>
          </InfoBlockTextStyled>
        </InfoBlockStyled>

        {children}
      </ScrollWrapper>
    </InviteLayoutWrapper>
  );
};
