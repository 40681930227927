import { ReactNode } from 'react';
import { InviteFormStyled } from './InviteFormLayout.styled';

interface IInviteFormLayoutProps {
  children: ReactNode;
}

export const InviteFormLayout: React.FC<IInviteFormLayoutProps> = (props) => {
  const { children } = props;
  return <InviteFormStyled layout="vertical">{children}</InviteFormStyled>;
};
