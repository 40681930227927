import { memo } from 'react';
import { Loader } from './LoaderSpin.styled';

interface ILoaderSpinProps {
  size?: number;
  color?: string;
  thickness?: number;
  position?: string;
}

export const LoaderSpin: React.FC<ILoaderSpinProps> = memo(
  ({
    size = 20,
    color = 'rgba(0, 0, 0, 0.1)',
    thickness = 0.2,
    position = 'static',
  }) => {
    return (
      <Loader
        size={size}
        color={color}
        thickness={thickness}
        position={position}
      />
    );
  },
);

LoaderSpin.displayName = 'LoaderSpin';
