import { Colors } from '@/environment';
import styled from 'styled-components';

export const Wrapper = styled.div.attrs((props: { showButton: boolean }) => ({
  ...props,
}))`
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  max-width: 320px;
  flex: 1 1 0;
  height: max-content;

  &:hover {
    background-color: ${Colors.lightblue};
  }

  button {
    font-size: 12px;
    visibility: ${({ showButton }) => (showButton ? 'visible' : 'hidden')};
  }

  img {
    max-width: 320px;
    max-height: 180px;
    width: 100%;
    height: auto;
  }
`;

export const CardInfo = styled.div`
  padding: 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: left;

  & > :first-child {
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.text.main};
  }

  & > :last-child {
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.dark};
  }
`;
