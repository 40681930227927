// TODO: FIX SAME COLORS, ADD OBJ FOR SAME COLOR PALITRA
export const Colors = {
  white: '#FFFFFF',
  dark: '#3B3D42',
  blue: '#0058D0',
  lightblue: '#DFE9F7',
  authBlockBg: '#F2F7FD',
  transparentBlue: '#EFF6FD',
  transparentBorderBlue: '#E0EBF9',
  extraLightBlue: '#EEF5F9',
  trasparentedBlue: '#CADDF6',
  darkTitle: '#230D40',
  green: '#61C454',
  red: '#ED6A5E',
  yellow: '#FCD34D',
  saturatedYellow: '#FFB800',
  saturatedGreen: '#61C454',
  borderLightGrey: '#F5F6F7',
  scrollbar: '#D9D9D9',
  sidebarBg: '#FAFCFE',
  contentBg: '#FCFEFF',
  lightGray: '#F5F5F5',
  // REMOVE OLD AFTER UPDATE ALL PROJECT
  newContentBg: '#F5F9FD',
  darkBlue: '#01233F',
  tableHoverRow: '#E8F6FE',
  black: '#000000',
  inputBlue: '#ECF1F8',
  switchUnchecked: '#D9E3F0',
  radioUnchecked: '#E9EFF6',
  switchDisabledUnchecked: '#E3EDFA',
  selectDisabledArrow: '#EAE9EC',
  grey: {
    100: '#A1A1AA',
    80: '#D9D9D9',
    50: '#EFF0F2',
    40: '#A1A1AA99',
    30: '#F6F6F7',
    20: '#F8F9FA',
  },
  borders: {
    navLink: '#F6F9FC',
    dropdown: '#E6EEFA',
    cards: 'rgba(246, 249, 252, 0.4)',
  },
  text: {
    main: '#7E97B8',
    bold: '#384A78',
  },
  player: {
    input: '#C9CAD9',
    bluegrey: '#8D99AE',
    duration: '#565264',
    dark: '#323232',
    colors: {
      black: '#000000',
      grey: '#717171',
      orange: '#FF6600',
      red: '#FF3300',
      purple: '#9900CC',
      teal: '#0BADAD',
      green: '#06AA2F',
      blue: '#0058D0',
    },
  },
  buttons: {
    disabled: '#B8C7DA',
  },
  icon: {
    yellow: '#FFC700',
  },
  error: '#EB0000',
  input: {
    disabled: '#E6E9EF',
  },
  hover: {
    tableItem: '#D1EEFE',
  },

  authInputBg: '#F6F6F6',
  authInputFocus: '#3E586D',
  authInputDisabled: '#E8E8E8',
  authInputDisabledBorder: '#DADADA',
  boldGreen: '#2B4501',
  logoBlack: '#231F20',
  newCheckboxColor: '#84BF01',
  lightBoldGreen: '#477302',
  newBtnDisabled: '#A1B3C2',
};
