import { TPlayerInfo } from '@/interfaces';
import React, { memo, useState } from 'react';
import { CardInfo, Wrapper } from './PlayerCard.styled';
import { Button } from '@/components/common';

type TPlayerCardProps = {
  changeStep: () => void;
  setPlayerType: () => void;
  playerInfo: TPlayerInfo;
};

export const PlayerCard: React.FC<TPlayerCardProps> = memo(
  ({ changeStep, playerInfo, setPlayerType }) => {
    const [showButton, setShowButton] = useState<boolean>(false);

    function handleShowButton(value: boolean) {
      return () => {
        setShowButton(value);
      };
    }

    function handleClick() {
      setPlayerType();
      changeStep();
    }

    return (
      <Wrapper
        onMouseEnter={handleShowButton(true)}
        onMouseLeave={handleShowButton(false)}
        showButton={showButton}
      >
        <img src={playerInfo.image} alt={playerInfo.title} />
        <CardInfo>
          <div>{playerInfo.title}</div>
          <div>{playerInfo.description}</div>
        </CardInfo>

        <Button
          text={playerInfo.buttonText}
          position={'center'}
          borderRadius={'8px'}
          onClick={handleClick}
        />
      </Wrapper>
    );
  },
);

PlayerCard.displayName = 'PlayerCard';
