import { Colors } from '@/environment';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.borders.cards};
  border-radius: 10px;
  flex: 1 1 0px;
  padding: 20px;
  position: relative;
  box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
  max-width: 320px;
  max-height: 140px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  min-height: 140px;

  // STYLES FOR DROPDOWN OPENED MODAL
  & .dropdownContent {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 100%;

    & .ant-dropdown-menu {
      box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12);
      border-radius: 16px;
      padding: 30px 30px 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 15px;
      font-family: 'Inter', sans-serif;
      font-style: normal;

      & .ant-dropdown-menu-item {
        background-color: white;
        color: ${Colors.text.main};
        font-weight: 500;
        font-size: 12px;
        padding: 0px;

        & .dropdown-item-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:hover {
          background-color: ${Colors.white};
        }
      }

      & .ant-dropdown-menu-item-selected {
        color: ${Colors.blue};
      }
    }
  }

  @media (max-width: 1240px) {
    padding: 20px;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${Colors.text.bold};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-right: 30px;
  white-space: wrap;
  font-family: 'Inter', sans-serif;
`;

export const CardContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const PercentageDiff = styled.div.attrs(
  (props: { upOrDown: boolean }) => ({
    ...props,
  }),
)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 6px;
  min-height: 15px;
  color: ${(props) => (props.upOrDown ? Colors.green : Colors.red)};
  font-family: 'Inter', sans-serif;
  width: 100%;
  font-size: 14px;
`;

export const CardValue = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: ${Colors.text.bold};

  span {
    font-size: 20px;
  }
`;
