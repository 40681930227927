import { memo, useState } from 'react';
import { Colors } from '@/environment';
import { InputProps } from 'antd';
import { PasswordProps } from 'antd/es/input';
import { AuthPasswordStyled } from './Password.styled';
import { Icon } from '../Icon';
import { IconType } from '@/consts';

type AuthPasswordProps = InputProps & PasswordProps & { isError?: string };

export const AuthPassword: React.FC<AuthPasswordProps> = memo((props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isError, ...inputProps } = props;

  function generateIcon(visible: boolean) {
    setIsVisible(visible);
    return (
      <Icon
        name={IconType.TYPasswordSuffix}
        color={visible ? Colors.darkBlue : Colors.grey[100]}
      />
    );
  }

  return (
    <AuthPasswordStyled
      {...inputProps}
      isError={isError === 'error'}
      className="auth-password"
      iconRender={generateIcon}
      isVisible={isVisible}
    />
  );
});

AuthPassword.displayName = 'AuthPassword';
