import { Colors } from '@/environment';
import styled from 'styled-components';

export const StyledSwitch = styled.label.attrs(
  (props: { disabled?: boolean; checked?: boolean }) => ({
    ...props,
  }),
)`
  position: relative;
  display: inline-block;
  width: 59px;
  max-height: 26px;
  min-height: 26px;
  box-sizing: border-box;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  & input[type='checkbox'] {
    display: none;
  }

  & .switch {
    position: absolute;
    cursor: pointer;
    background-color: ${({ disabled, checked }) =>
      disabled
        ? checked
          ? Colors.trasparentedBlue
          : Colors.switchDisabledUnchecked
        : Colors.switchUnchecked};
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    box-sizing: border-box;
    max-height: 26px;
    min-height: 26px;
    border: 2px solid
      ${({ disabled, checked }) =>
        disabled
          ? checked
            ? Colors.trasparentedBlue
            : Colors.switchDisabledUnchecked
          : Colors.blue};
  }

  & .switch::before {
    position: absolute;
    box-sizing: border-box;
    content: '';
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    border: 2px solid
      ${({ disabled, checked }) =>
        disabled
          ? checked
            ? Colors.trasparentedBlue
            : Colors.switchDisabledUnchecked
          : Colors.blue};
    width: 24px;
    height: 24px;
    background-color: ${Colors.white};
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  & input[type='checkbox']:checked + .switch::before {
    transform: translate(calc(100% + 10px), -50%);
    background-color: ${Colors.white};
  }

  & input[type='checkbox']:checked + .switch {
    background-color: ${({ disabled, checked }) =>
      disabled
        ? checked
          ? Colors.trasparentedBlue
          : Colors.switchDisabledUnchecked
        : Colors.blue};
  }
`;
