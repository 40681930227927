import { Icon } from '@/components/common';
import { IconType } from '@/consts';
import { TPlayerCreateFields } from '@/interfaces';
import { useEffect, useMemo, useState } from 'react';
import {
  DescriptionRangeWrap,
  DynamicDescription,
  DynamicOptions,
  DynamicSMOptions,
  HideBtn,
  MobilePreferencesBtn,
  OptionsWrapMD,
  PauseIconWrap,
  PauseWrap,
  Placeholder,
  PlayerDescription,
  PlayerInput,
  PreferencesBtn,
  RangeInputWrap,
  SkipBtnsGroup,
  Wrapper,
} from './PlayerPreview.styled';

interface IPlayerPreviewProps {
  playerInfo: TPlayerCreateFields;
  hexColor: string;
}

export enum PlayerSize {
  LG = 'large',
  MD = 'medium',
  SM = 'small',
  DYNAMIC = 'dynamic',
  DYNAMICSM = 'dynamic-small',
}

export const PlayerPreview: React.FC<IPlayerPreviewProps> = ({
  playerInfo,
  hexColor,
}) => {
  const [playerSize, setPlayerSize] = useState<string>('');

  const { color, width, preview, type } = playerInfo;
  const { speed, voice, language, download, rating } = playerInfo;

  const iconColor =
    color === 'hex'
      ? hexColor.length !== 7 && hexColor.length !== 4
        ? 'transparent'
        : hexColor
      : color;

  const isPreferencesShow = useMemo(() => {
    if (
      typeof width !== 'number' &&
      !voice &&
      !language &&
      !download &&
      !rating
    ) {
      return false;
    }

    if (
      typeof width === 'number' &&
      !speed &&
      !voice &&
      !language &&
      !download &&
      !rating
    ) {
      return false;
    }

    return true;
  }, [download, language, rating, speed, voice, width]);

  useEffect(() => {
    if (typeof width === 'number') {
      if (width >= 600) {
        setPlayerSize(preview !== 'mobile' ? 'large' : 'small');
      }
      if (width >= 400 && width < 600) {
        setPlayerSize(preview !== 'mobile' ? 'medium' : 'small');
      }
      if (preview === 'mobile') {
        setPlayerSize('small');
      }
    } else {
      if (preview === 'mobile') {
        setPlayerSize('dynamic-small');
      } else {
        setPlayerSize('dynamic');
      }
    }
  }, [preview, width]);

  return (
    <Wrapper
      color={color}
      size={playerSize}
      preview={preview}
      playerType={type}
    >
      {playerSize === PlayerSize.LG && (
        <>
          <PauseWrap color={color} hexColor={hexColor}>
            <PauseIconWrap color={color} hexColor={hexColor}>
              <Icon
                name={IconType.TYPlayerPause}
                iconHeight={7}
                iconWidth={6}
              />
            </PauseIconWrap>
          </PauseWrap>
          <PlayerDescription>
            <span>Play this article</span>
            <span>
              <span>Powered by: </span>
              TYLR
            </span>
          </PlayerDescription>
          <RangeInputWrap>
            <div>
              <span>00:00</span>
              <span>00:00</span>
            </div>
            <PlayerInput
              color={color}
              type="range"
              min="0"
              max="100"
              playerType={type}
              hexColor={hexColor}
            />
          </RangeInputWrap>
          <SkipBtnsGroup>
            <Icon
              name={IconType.TYPlayerBack}
              iconHeight={12}
              iconWidth={12}
              color={iconColor}
            />
            <Icon
              name={IconType.TYPlayerForward}
              iconHeight={12}
              iconWidth={12}
              color={iconColor}
            />
          </SkipBtnsGroup>
          <Icon
            name={IconType.TYPlayerVolume}
            color={iconColor}
            iconHeight={6}
            iconWidth={9}
          />
          {isPreferencesShow ? (
            <PreferencesBtn>
              <Icon
                name={IconType.TYPlayerPreferences}
                color={iconColor}
                iconHeight={13}
                iconWidth={13}
              />
            </PreferencesBtn>
          ) : (
            <Placeholder width={13} height={13} />
          )}
        </>
      )}

      {playerSize === PlayerSize.MD && (
        <>
          <PauseWrap color={color} hexColor={hexColor}>
            <PauseIconWrap color={color} hexColor={hexColor}>
              <Icon
                name={IconType.TYPlayerPause}
                iconHeight={7}
                iconWidth={6}
              />
            </PauseIconWrap>
          </PauseWrap>
          <DescriptionRangeWrap>
            <PlayerDescription isMD>
              <span>Play this article</span>
              <span>
                <span>Powered by: </span>
                TYLR
              </span>
            </PlayerDescription>
            <RangeInputWrap>
              <div>
                <span>00:00</span>
                <span>00:00</span>
              </div>
              <PlayerInput
                color={color}
                type="range"
                min="0"
                max="100"
                playerType={type}
                hexColor={hexColor}
              />
            </RangeInputWrap>
          </DescriptionRangeWrap>
          <OptionsWrapMD>
            {isPreferencesShow ? (
              <PreferencesBtn>
                <Icon
                  name={IconType.TYPlayerPreferences}
                  color={iconColor}
                  iconHeight={13}
                  iconWidth={13}
                />
              </PreferencesBtn>
            ) : (
              <Placeholder width={13} height={13} />
            )}
            <div>
              <SkipBtnsGroup>
                <Icon
                  name={IconType.TYPlayerBack}
                  iconHeight={12}
                  iconWidth={12}
                  color={iconColor}
                />
                <Icon
                  name={IconType.TYPlayerForward}
                  iconHeight={12}
                  iconWidth={12}
                  color={iconColor}
                />
              </SkipBtnsGroup>
              <Icon
                name={IconType.TYPlayerVolume}
                color={iconColor}
                iconHeight={6}
                iconWidth={9}
              />
            </div>
          </OptionsWrapMD>
          <HideBtn>
            <Icon
              name={IconType.TYPlayerActivateMask}
              color={iconColor}
              iconHeight={5}
              iconWidth={5}
            />
          </HideBtn>
        </>
      )}

      {playerSize === PlayerSize.SM && (
        <>
          <PauseWrap color={color} isSM hexColor={hexColor}>
            <PauseIconWrap color={color} hexColor={hexColor}>
              <Icon
                name={IconType.TYPlayerPause}
                iconHeight={7}
                iconWidth={6}
              />
            </PauseIconWrap>
          </PauseWrap>

          <RangeInputWrap>
            <div>
              <span>00:00</span>
              <span>00:00</span>
            </div>
            <PlayerInput
              color={color}
              type="range"
              min="0"
              max="100"
              playerType={type}
              hexColor={hexColor}
            />
          </RangeInputWrap>
          <SkipBtnsGroup>
            <Icon
              name={IconType.TYPlayerBack}
              iconHeight={12}
              iconWidth={12}
              color={iconColor}
            />
            <Icon
              name={IconType.TYPlayerForward}
              iconHeight={12}
              iconWidth={12}
              color={iconColor}
            />
          </SkipBtnsGroup>
          {isPreferencesShow && (
            <MobilePreferencesBtn>
              <Icon
                name={IconType.TYPlayerPreferences}
                color={iconColor}
                iconHeight={13}
                iconWidth={13}
              />
            </MobilePreferencesBtn>
          )}
          <HideBtn>
            <Icon
              name={IconType.TYPlayerActivateMask}
              color={iconColor}
              iconHeight={5}
              iconWidth={5}
            />
          </HideBtn>
        </>
      )}

      {playerSize === PlayerSize.DYNAMIC && (
        <>
          <DynamicOptions>
            <div>
              <Icon
                name={IconType.TYPlayerVolume}
                color={iconColor}
                iconHeight={4.5}
                iconWidth={6}
              />
              <PlayerInput
                isVolume
                color={color || 'grey'}
                type="range"
                min="0"
                max="100"
                playerType={type}
                hexColor={hexColor}
              />
            </div>
            <div>
              {speed ? (
                <Icon
                  name={IconType.TYPlayerPlayback}
                  iconHeight={5}
                  iconWidth={7}
                  color={iconColor}
                />
              ) : (
                <Placeholder width={7} height={5} />
              )}
              <Icon
                name={IconType.TYPlayerBack}
                iconHeight={12}
                iconWidth={12}
                color={iconColor}
              />
              <PauseWrap color={color} isDynamic hexColor={hexColor}>
                <PauseIconWrap color={color} hexColor={hexColor}>
                  <Icon
                    name={IconType.TYPlayerPause}
                    iconHeight={6}
                    iconWidth={5}
                  />
                </PauseIconWrap>
              </PauseWrap>
              <Icon
                name={IconType.TYPlayerForward}
                iconHeight={12}
                iconWidth={12}
                color={iconColor}
              />
              {isPreferencesShow ? (
                <Icon
                  name={IconType.TYPlayerDynamicPreferences}
                  color={iconColor}
                  iconHeight={7.5}
                  iconWidth={7.5}
                />
              ) : (
                <Placeholder width={7.5} height={7.5} />
              )}
            </div>
          </DynamicOptions>

          <RangeInputWrap isDynamic>
            <div>
              <span>00:00</span>
              <span>00:00</span>
            </div>
            <PlayerInput
              color={color}
              type="range"
              min="0"
              max="100"
              playerType={type}
              hexColor={hexColor}
            />
          </RangeInputWrap>
          <HideBtn>
            <Icon
              name={IconType.TYPlayerDynamicClose}
              color={iconColor}
              iconHeight={6}
              iconWidth={6}
            />
          </HideBtn>
          <DynamicDescription>
            <span>Powered by: </span>
            TYLR
          </DynamicDescription>
        </>
      )}

      {playerSize === PlayerSize.DYNAMICSM && (
        <>
          <DynamicSMOptions>
            {speed ? (
              <Icon
                name={IconType.TYPlayerPlayback}
                iconHeight={9}
                iconWidth={13}
                color={iconColor}
              />
            ) : (
              <Placeholder width={13} height={9} />
            )}
            <Icon
              name={IconType.TYPlayerBack}
              iconHeight={21}
              iconWidth={21}
              color={iconColor}
            />
            <PauseWrap color={color} isDynamicSM hexColor={hexColor}>
              <PauseIconWrap color={color} hexColor={hexColor}>
                <Icon
                  name={IconType.TYPlayerPause}
                  iconHeight={11}
                  iconWidth={10}
                />
              </PauseIconWrap>
            </PauseWrap>
            <Icon
              name={IconType.TYPlayerForward}
              iconHeight={21}
              iconWidth={21}
              color={iconColor}
            />
            {isPreferencesShow ? (
              <Icon
                name={IconType.TYPlayerDynamicPreferences}
                color={iconColor}
                iconHeight={14}
                iconWidth={14}
              />
            ) : (
              <Placeholder width={14} height={14} />
            )}
          </DynamicSMOptions>

          <RangeInputWrap isDynamicSM>
            <div>
              <span>00:00</span>
              <span>00:00</span>
            </div>
            <PlayerInput
              color={color}
              type="range"
              min="0"
              max="100"
              playerType={type}
              hexColor={hexColor}
            />
          </RangeInputWrap>
          <HideBtn>
            <Icon
              name={IconType.TYPlayerDynamicClose}
              color={iconColor}
              iconHeight={6}
              iconWidth={6}
            />
          </HideBtn>
        </>
      )}
    </Wrapper>
  );
};
