import React, { ReactNode, useState } from 'react';
import {
  SelectWrapper,
  StyledSelect,
  OptionLabel,
  SelectWithOptionsWrap,
  SelectedOptions,
  FilterOption,
} from './FilterSelect.styled';
import { Icon } from '../../common/Icon';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import { IFilterOption } from '@/interfaces';

const { Option } = StyledSelect;

const DEFAULT_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
    category: 'Status',
  },
  {
    label: 'Paused',
    value: 'inactive',
    category: 'Status',
  },
  {
    label: 'Dynamic',
    value: 'dynamic',
    category: 'Player type',
  },
  {
    label: 'Static',
    value: 'static',
    category: 'Player type',
  }

]

export interface IFilterSelectProps {
  filterSelectedOptions: IFilterOption[];
  setFilterOptions: (value: IFilterOption[]) => void
  options?: IFilterOption[];
  betweenSelectedAndFilterSlot?: ReactNode
}

export const FilterSelect: React.FC<IFilterSelectProps> = ({
  filterSelectedOptions,
  setFilterOptions,
  options,
  betweenSelectedAndFilterSlot
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const innerOptions = [
    {
      label: 'Filter by',
      value: 'placeholder',
      category: 'placeholder',
    },
    ...(options ? options : DEFAULT_OPTIONS)
  ];

  const handleChange = (value: string) => {
    if (filterSelectedOptions.some((option) => option.value === value)) {
      setFilterOptions(filterSelectedOptions.filter((option) => option.value !== value),
      );
    } else {
      const newOption = innerOptions.find((option) => option.value === value);
      if (newOption) {
        const isCategoryExist = filterSelectedOptions.find(
          (option) => option.category === newOption.category,
        );

        if (isCategoryExist) {
          const newOptions = filterSelectedOptions.filter(
            (option) => option.category !== newOption.category,
          );

          setFilterOptions([...newOptions, newOption]);
        } else {
          setFilterOptions([...filterSelectedOptions, newOption]);
        }
      }
    }
  };

  function handleRemoveOption(value: string) {
    return () => {
      setFilterOptions(filterSelectedOptions.filter((option) => option.value !== value));
    };
  }

  function handleSetOpen(open: boolean) {
    return () => {
      setIsOpen(open);
    };
  }

  function handleSelectChange(value: unknown) {
    handleChange(value as string);
  }

  return (
    <SelectWithOptionsWrap>
      <SelectedOptions>
        {filterSelectedOptions.map((option) => (
          <FilterOption key={option.value}>
            <span>
              {option.category}: <b>{option.label}</b>
            </span>
            <Icon
              name={IconType.TYCloseXMark}
              onClick={handleRemoveOption(option.value)}
            />
          </FilterOption>
        ))}
      </SelectedOptions>
      {betweenSelectedAndFilterSlot}

      <SelectWrapper>
        <StyledSelect
          open={isOpen}
          value={'placeholder'}
          onClick={handleSetOpen(true)}
          placeholder={
            <>
              <Icon name={IconType.TYFilterIcon} />
              Filter by
            </>
          }
          bordered={false}
          optionLabelProp="label"
          suffixIcon={
            <Icon name={IconType.TYDropdownArrow} color={Colors.text.main} />
          }
          onBlur={handleSetOpen(false)}
          dropdownStyle={{
            width: '100%',
          }}
          onChange={handleSelectChange}
          popupClassName={'select'}
          onDropdownVisibleChange={(visible) => setIsOpen(visible)}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {innerOptions.map((option) => {
            const isActive = filterSelectedOptions.some(
              (el) => el.value === option.value,
            );

            return (
              <Option
                key={option.value}
                value={option.value}
                className="option"
                label={
                  <>
                    <Icon name={IconType.TYFilterIcon} />
                    {option.label}
                  </>
                }
              >
                <OptionLabel active={isActive}>{option.label}</OptionLabel>
                {isActive && (
                  <Icon
                    name={IconType.TYTimeSortCheck}
                    iconHeight={8}
                    iconWidth={10}
                    color={Colors.blue}
                  />
                )}
              </Option>
            );
          })}
        </StyledSelect>
      </SelectWrapper>
    </SelectWithOptionsWrap>
  );
};
