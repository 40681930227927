import { Colors } from '@/environment';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const AuthWrapperStyled = styled.div.attrs(
  (props: { isSignUp: boolean }) => ({
    ...props,
  }),
)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${Colors.lightGray};
  padding: 0px 141px;
  box-sizing: border-box;
  gap: 74px;

  ${({ isSignUp }) =>
    isSignUp &&
    css`
      @media (max-height: 1029.99px) {
        gap: 40px;
      }

      @media (max-height: 934.99px) {
        gap: 20px;
      }

      @media (max-height: 843.99px) {
        height: 100%;
      }
    `}
`;

export const AuthContainerStyled = styled.div.attrs(
  (props: { paddingContainer: string }) => ({
    ...props,
  }),
)`
  padding: ${({ paddingContainer }) => paddingContainer};
  background-color: ${Colors.white};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

export const AuthTitleStyled = styled.div.attrs(
  (props: { paddingTitle: string }) => ({
    ...props,
  }),
)`
  display: flex;
  gap: 4.57px;
  color: ${Colors.logoBlack};
  font-size: 16px;
  height: 16px;
  font-weight: 700;
  align-items: center;
  margin-bottom: ${({ paddingTitle }) => paddingTitle};
  z-index: 2;
  font-family: 'Golos Text', 'Inter', sans-serif;
  cursor: pointer;
`;

export const AuthPoweredByBlockStyled = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  height: 20px;

  & > :first-child {
    color: ${Colors.grey[100]};
  }

  & > a {
    color: ${Colors.black};
    text-decoration: none;
  }
`;

export const AuthButtonStyled = styled(Button)`
  &.ant-btn {
    display: flex;
    outline: none;
    border: none;
    border-radius: 4px;
    background-color: ${Colors.darkBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${Colors.white};
    padding: 10px 0;
    height: 40px;
    font-size: 16px;
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      background-color: ${Colors.darkBlue};
    }

    &:disabled {
      cursor: default;
    }
  }

  &.ant-btn:disabled {
    background-color: ${Colors.newBtnDisabled} !important;
  }
`;

export const AuthHelperTextStyled = styled.div`
  color: ${Colors.blue};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
`;

export const BodyWrap = styled.div.attrs((props: { isSignUp: boolean }) => ({
  ...props,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ isSignUp }) => (isSignUp ? '100px' : '240px')};

  ${({ isSignUp }) =>
    isSignUp
      ? css`
          @media (max-height: 1029.99px) {
            padding-top: 40px;
          }

          @media (max-height: 934.99px) {
            padding-top: 20px;
          }
        `
      : css`
          @media (max-height: 1029.99px) {
            padding-top: 120px;
          }
        `}
`;

export const FooterWrap = styled.div.attrs((props: { isSignUp: boolean }) => ({
  ...props,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px 61px;
  border-top: 1px solid #ccdcde;
  width: 100%;
  z-index: 20;
  font-size: 14px;
  font-family: 'Noto Sans', 'Inter', sans-serif;
  font-weight: 500;
  line-height: 150%;
  color: ${Colors.darkBlue};

  ${({ isSignUp }) =>
    isSignUp &&
    css`
      @media (max-height: 894.99px) {
        padding: 20px 0px 30px;
      }
    `}
`;

export const SiteLinks = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  a {
    color: ${Colors.darkBlue} !important;
    text-decoration-line: underline;
  }
`;
