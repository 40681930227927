import * as Yup from 'yup';

interface IConfirmForm {
  email: string;
  passcode: number;
}

const confirmFormShema: Yup.SchemaOf<IConfirmForm> = Yup.object({
  email: Yup.string().required(),
  passcode: Yup.number()
    .test(
      'len',
      'Passcode is wrong',
      (value?: number) => value?.toString().length === 6,
    )
    .required('Passcode is wrong'),
});

export default confirmFormShema;
