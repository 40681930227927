import { Colors } from '@/environment';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 60px;
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;

  .copy-tooltip {
    top: -12px !important;
  }

  .ant-tooltip-arrow {
    bottom: 2px !important;

    &::before {
      background: ${Colors.blue};
    }
  }

  .ant-tooltip-inner {
    padding: 2px 8px 0px 8px;
    display: flex;
    align-items: center;
    width: 57px;
    min-height: 23px;
    max-height: 23px;
    box-sizing: border-box;
    background: ${Colors.blue};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    box-shadow: none;
    border-radius: 8px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.grey[100]};
  padding-bottom: 20px;
  max-width: 600px;
  width: 100%;
`;
