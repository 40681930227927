export enum ERoutes {
  Root = '/',
  ManagePlayers = '/players',
  ManageUsers = '/users',
  ManagePublishers = 'publishers',
  InvitePublisher = '/publishers/invite',
  InviteUser = '/users/invite',
  CreatePlayer = '/players/create',
  Tutorials = '/tutorials',
  PubliserMonetization = '/publisher-monetization',
  OwnerMonetization = '/owner-monetization',
  OwnerMonetizationPublisherDetails = '/owner-monetization/:id',
  PublisherBillingInfo = '/monetization/billing-info',
  EditPlayer = '/players/edit/:playerId',
  PlayerDetails = '/players/:playerId',
  Publisher = '/publishers/:publisherId',
  ViewSetupAsPub = '/players/:playerId/view-details',
}

export enum EAuthRoutes {
  Login = 'auth/login',
  UserSignUp = 'auth/signup/user',
  PublisherSignUp = 'auth/signup/publisher',
  Confirm = 'auth/confirm',
  Forgot = 'auth/forgot',
  Recover = '/auth/recover-password',
}

export enum EGeneralRoutes {
  NoMobile = '/mobile',
  NotFound = '/404',
}
