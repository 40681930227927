import styled from 'styled-components';
import { Divider, Select } from 'antd';
import { Colors } from '@/environment';

export const StyledSelect = styled(Select)`
  border-radius: 16px;
  width: max-content;
  background-color: ${Colors.white};
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.05);
  border: 2px solid ${Colors.transparentBorderBlue};
  height: 40px;

  & .ant-select-selector {
    flex: 1;
  }

  & .select {
    width: 100%;
    left: 0px !important;
    top: 0px !important;
    border-radius: 16px;
    box-shadow: 0px 4px 15px rgba(100, 18, 177, 0.08);
    border: 2px solid ${Colors.borderLightGrey};
  }

  & .ant-select-selection-item {
    padding: 0px !important;
    width: 100% !important;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    color: ${Colors.text.main};
    gap: 10px;

    &::after {
      display: none !important;
    }
  }

  & .ant-select-item-option {
    background-color: ${Colors.white} !important;
  }

  & .ant-select-item-option-content {
    color: ${Colors.text.main} !important;
    font-weight: 500 !important;
  }

  & .ant-select-arrow {
    position: static;
    margin: 0px;
  }

  & .ant-select-selector {
    padding: 0px !important;
    width: max-content !important;
    height: max-content !important;
  }
`;

export const Wrapper = styled.div`
  width: max-content;
  position: relative;
  box-sizing: border-box;
  display: flex;
  max-width: 255px;
  justify-content: flex-end;

  .rc-virtual-list-holder-inner > :last-child {
    display: none !important;
  }

  & .ant-select-dropdown {
    top: 0px !important;
    right: 0px !important;
    min-width: 255px !important;
    left: auto !important;
    width: 255px !important;
    box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12);
  }

  & .ant-select-item-option-selected {
    color: ${Colors.grey[100]} !important;
    background-color: transparent !important;
    font-weight: 500 !important;
  }

  & .option {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.grey[100]};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color: ${Colors.text.main} !important;
    background-color: transparent !important;
    background: transparent !important;
  }

  & .option-hidden {
    display: none;
  }
`;

export const RangePickerWrap = styled.div`
  border-radius: 16px;

  & .ant-picker.ant-picker-range {
    input {
      color: ${Colors.text.main};
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      padding-top: 2px;

      &::placeholder {
        color: ${Colors.text.main};
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
      }
    }

    .ant-picker-suffix {
      margin-inline-start: 14px;
    }
  }
`;

export const SelectFooter = styled.div`
  padding: 10px 20px 15px 20px;
  position: relative;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ChoosedOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${Colors.text.main};
`;
