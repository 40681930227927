import { Colors } from '@/environment';
import { Button } from 'antd';
import styled from 'styled-components';

interface ButtonWrapperStyledProps {
  isShouldShowStatusSetting: boolean;
}

interface StatusStyledProps {
  isShouldShowDeleteDialog: boolean;
}

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
  box-sizing: border-box;
`;

export const StatusStyled = styled.div<StatusStyledProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isShouldShowDeleteDialog ? '20px 20px 10px 20px' : '20px'};
  border-bottom: 1px solid #eef5f9;
  box-sizing: border-box;

  & .status {
    color: ${Colors.blue};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ButtonWrapperStyled = styled.div<ButtonWrapperStyledProps>`
  display: flex;
  padding: ${(props) =>
    props.isShouldShowStatusSetting ? '10px 20px 20px 20px' : '20px'};
`;

export const DeleteUserButton = styled(Button)`
  padding: 0;
  height: auto;
  color: ${Colors.error};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  &.ant-btn:hover {
    color: ${Colors.error};
  }
`;

export const DeleteConfirmStyled = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.red};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  gap: 10px;
`;
