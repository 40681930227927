import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { AuthInput, FormErrors } from '@/components/common';
import useQueryParams from '@/hooks/useQueryParams';
import {
  usePublisherConfirmMutation,
  useResendCodeMutation,
} from '@/redux/api/authApi';
import { EAuthRoutes } from '@/routes';
import { Nullable } from '@/types/generic';
import { TPublisherConfirmRequest } from '@/types/publisher-types';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AuthButtonStyled } from '../../AuthWrapper/AuthWrapper.styled';
import { StatusMessageStyled, ConfirmFormWrapper } from './ConfirmForm.styled';
import confirmFormShema from './validation';
import { Colors } from '@/environment';

export const ConfirmForm = () => {
  const [textError, setTextError] = useState<Nullable<string>>(null);

  const [publisherConfirm] = usePublisherConfirmMutation();
  const [resendCode] = useResendCodeMutation();

  const params = useQueryParams();
  const navigate = useNavigate();

  const email = params.get('email');

  const formik = useFormik({
    initialValues: {
      email: email ?? '',
      passcode: 0,
    },
    validationSchema: confirmFormShema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const data: TPublisherConfirmRequest = {
        email: values.email,
        code: values.passcode,
      };
      publisherConfirm({ data })
        .unwrap()
        .then(() => {
          setTextError('');
          navigate(`/${EAuthRoutes.Login}`);
        })
        .catch((errors) => {
          setTextError(errors.data.message);
        });
    },
  });

  const { values, submitForm, setFieldValue, dirty, errors } = formik;

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 6) {
      setFieldValue('passcode', event.target.value);
    }
  };

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submitForm();
  }

  function handleResendCode() {
    resendCode({ email: values.email });
  }

  useEffect(() => {
    if (errors) {
      setTextError('');
    }
  }, [errors]);

  return (
    <ConfirmFormWrapper autoComplete="nofill" onSubmit={handleSubmit}>
      <AuthInput
        value={values.passcode === 0 ? undefined : values.passcode}
        onChange={changeValue}
        type="number"
        id="passcode"
        placeholder="Passcode"
        status={errors.passcode && 'error'}
      />

      <AuthButtonStyled
        type="primary"
        size="large"
        block
        disabled={!dirty}
        htmlType="submit"
        style={{ width: 320 }}
      >
        Confirm
      </AuthButtonStyled>

      <StatusMessageStyled
        color={Colors.lightBoldGreen}
        onClick={handleResendCode}
        cursor="pointer"
      >
        Resend code
      </StatusMessageStyled>

      {textError && (
        <StatusMessageStyled color="red">{textError}</StatusMessageStyled>
      )}

      <FormErrors errors={errors} />
    </ConfirmFormWrapper>
  );
};
