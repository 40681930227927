import { Colors } from '@/environment';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ForgotPasswordFormWrapper = styled.form.attrs(
  (props: { successMessage: boolean }) => ({
    ...props,
  }),
)`
  width: 320px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  button {
    font-family: 'Noto Sans', 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .message {
    padding-top: 10px;
  }

  .info-status {
    padding-bottom: 25px;
    padding-top: 0;
    font-family: 'Golos Text', 'Inter', sans-serif;
    font-weight: 500;
  }

  ${({ successMessage }) =>
    successMessage &&
    css`
      padding-bottom: 16px;

      #email-info {
        padding-bottom: 0px;
      }

      #icon-wrapper {
        margin-bottom: 6px;
      }
    `}
`;

export const SuccessBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  font-family: 'Golos Text', 'Inter', sans-serif;
  font-style: normal;
  color: ${Colors.newCheckboxColor};
  font-weight: 500;
  font-size: 14px;
`;

export const EmailInfo = styled.span`
  color: ${Colors.dark};
  padding: 6px 0px 20px;
  font-family: 'Golos Text';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const BackToSignInLink = styled(Link)`
  color: ${Colors.lightBoldGreen};
  text-decoration: underline;
  font-family: 'Golos Text', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;
`;

export const IconWrapper = styled.div`
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1ffd3;
  border-radius: 104px;
`;
