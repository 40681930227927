import styled from 'styled-components';

export const Message = styled.div.attrs(
  (props: { color: string; size: number }) => ({
    ...props,
  }),
)`
  font-family: 'Inter', sans-serif;
  color: ${({ color }) => color};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size }) => `${size}px`};
  line-height: 20px;
  text-align: center;
`;
