import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  BaseFormInput,
  BaseFormSelect,
  BaseFormSwitch,
} from '@/components/common';
import {
  PlayerInfoType,
  playerPreviewOptions,
  playerTypeOptions,
} from '@/consts';
import { Colors } from '@/environment';
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter';
import { TOption, TPlayerCreateFields } from '@/interfaces';
import { FormikProps } from 'formik';
import { RadioButtons } from '../RadioButtons';
import {
  ColorOption,
  StyledDivider,
  StyledForm,
  SwitchesWrap,
  SwitchRow,
  WidthInputWrap,
  PlayerFormWrapper,
  RecommendationBlock,
  HexInputWrap,
  ColorsControlWrap,
  ColorSelectPlaceholder,
} from './CreateOrEditPlayerForm.styled';
import { QuestionTooltip } from '@/components/common/QuestionTooltip';
import client from '@/lib/client';

interface ICreatePlayerFormProps {
  formik: FormikProps<TPlayerCreateFields>;
  isEdit: boolean;
  error?: string;
  readOnly: boolean;
  setHexColor: Dispatch<SetStateAction<string>>;
  hexColor: string;
  isHexError: boolean;
  setIsHexError: Dispatch<SetStateAction<boolean>>;
}

export const CreateOrEditPlayerForm: React.FC<ICreatePlayerFormProps> = ({
  formik,
  isEdit,
  error,
  readOnly,
  setHexColor,
  hexColor,
  isHexError,
  setIsHexError,
}) => {
  const { values, handleChange, setFieldValue, errors } = formik;
  const playerColors: Record<string, string> = Colors.player.colors;
  const [playerInfo, setPlayerInfo] = useState<PlayerInfoType | null>(null);

  const preparedColors = useMemo(() => {
    return Object.keys(playerColors).map((key) => ({
      label: capitalizeFirstLetter(key),
      color: playerColors[key],
    }));
  }, [playerColors]);

  function handleSetField(name: string, value: boolean | string | number) {
    setFieldValue(name, value);
  }

  function handleSetWidth(e: ChangeEvent<HTMLInputElement>) {
    const { value: inputValue } = e.target;
    const regExp = /^-?\d*(\.\d*)?$/;
    if (regExp.test(inputValue) || inputValue === '' || inputValue === '-') {
      const value = inputValue.length < 1 ? 0 : inputValue;
      setFieldValue('width', Number(value));
    }
  }

  function setHex(e: ChangeEvent<HTMLInputElement>) {
    const { value: inputValue } = e.target;

    const str = inputValue.trim();
    setHexColor(str.replace(/[^#A-Fa-f0-9]/g, ''));
    setIsHexError(false);
  }

  function handleSetColor(value: string) {
    if (value !== 'hex') {
      setHexColor('');
      setIsHexError(false);
    }
    setFieldValue('color', value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "player_advanced"] | order(publishedDate desc) [0]`;
        const data = await client.fetch(query);
        setPlayerInfo(data);
      } catch (error) {
        console.error('Error fetching data from Sanity:', error);
      }
    };

    fetchData();
  }, []);

  const playerSwitchOptions: TOption[] = [
    {
      value: 'download',
      title: 'Download',
      tooltipText: playerInfo?.download,
    },
    {
      value: 'language',
      title: 'Languages',
      tooltipText: playerInfo?.language,
    },
    {
      value: 'voice',
      title: 'Voice change',
      tooltipText: playerInfo?.voice,
    },
    {
      value: 'speed',
      title: 'Playback speed',
      tooltipText: playerInfo?.speed,
    },
    {
      value: 'rating',
      title: 'Ratings',
      tooltipText: playerInfo?.rating,
    },
  ];

  return (
    <PlayerFormWrapper>
      <StyledForm>
        <div>
          <RadioButtons
            options={playerTypeOptions}
            label="Player type"
            onChange={handleChange}
            disabled={isEdit}
            name={'type'}
            value={values['type']}
          />
          <StyledDivider type="horizontal" mr={'30px 0px'} />
          <RadioButtons
            options={playerPreviewOptions}
            label="Preview"
            onChange={handleChange}
            name={'preview'}
          />
        </div>
        <BaseFormInput
          id="name"
          label="Player Name"
          placeholder="Enter name here"
          value={values.name}
          errorMessage={errors['name'] || error}
          autoComplete="off"
          maxLength={255}
          onChange={handleChange}
          color={Colors.white}
          disabled={readOnly}
        />
        <ColorsControlWrap>
          <BaseFormSelect
            id="color"
            value={
              Object.values(playerColors).includes(values.color)
                ? values.color
                : 'hex'
            }
            style={{ width: '100%' }}
            onChange={handleSetColor}
            backgroundColor={
              values.color === 'hex' ? '#0058D00D' : `${values.color}0D`
            }
            withPrefix
            label={'Player color'}
            className={values.color === 'hex' ? 'hex-select' : undefined}
            options={[
              ...preparedColors.map(({ label, color }) => ({
                value: color,
                label,
                prefix: <ColorOption color={color} />,
              })),
              {
                value: 'hex',
                label: (
                  <span style={{ fontStyle: 'italic' }}>
                    Insert your own custom HEX code
                  </span>
                ),
                prefix: <ColorSelectPlaceholder />,
              },
            ]}
            disabled={readOnly}
          />

          {values.color === 'hex' && (
            <HexInputWrap>
              <BaseFormInput
                label="Paste HEX color code"
                placeholder=""
                value={hexColor}
                errorMessage={
                  hexColor.startsWith('#')
                    ? isHexError
                      ? hexColor.length === 0
                        ? 'Required field'
                        : 'Hex code must be 4 or 7 characters long'
                      : undefined
                    : 'Hex code must start with #'
                }
                autoComplete="off"
                maxLength={7}
                onChange={setHex}
                color={Colors.white}
                disabled={readOnly}
              />
              <ColorOption color={hexColor} />
            </HexInputWrap>
          )}
        </ColorsControlWrap>

        <SwitchesWrap>
          {playerSwitchOptions.map((option) => (
            <SwitchRow
              key={option.value}
              checked={
                values[
                  option.value as keyof Pick<
                    TPlayerCreateFields,
                    'download' | 'language' | 'voice' | 'speed' | 'rating'
                  >
                ]
              }
            >
              <span className="switch-title">
                {option.title}
                <QuestionTooltip
                  title={
                    <span className="tooltip-text">{option?.tooltipText}</span>
                  }
                />
              </span>
              <BaseFormSwitch
                disabled={readOnly}
                onChange={handleSetField}
                name={option.value}
                value={
                  values[
                    option.value as keyof Pick<
                      TPlayerCreateFields,
                      'download' | 'language' | 'voice' | 'speed' | 'rating'
                    >
                  ]
                }
              />
            </SwitchRow>
          ))}
        </SwitchesWrap>
        {values.type === 'static' && values.preview !== 'mobile' && (
          <div>
            <StyledDivider type="horizontal" mr={'0px 0px 20px 0px'} />
            <WidthInputWrap>
              {readOnly && <span>Width:</span>}
              {readOnly ? (
                <span className="width-title">{values.width}</span>
              ) : (
                <BaseFormInput
                  id="width"
                  label="Enter width (PX)"
                  placeholder="600"
                  min={'400'}
                  defaultValue={'600'}
                  value={Number(values.width)}
                  errorMessage={errors['width']}
                  onChange={handleSetWidth}
                  color={Colors.white}
                  disabled={readOnly}
                />
              )}
              {readOnly && <span>PX</span>}
            </WidthInputWrap>
            <RecommendationBlock>
              <span>*Recommended size - 600px</span>
              <span>*Minimal size - 400px</span>
              <span>*Maximum size - 1000px</span>
            </RecommendationBlock>
          </div>
        )}
      </StyledForm>
    </PlayerFormWrapper>
  );
};
