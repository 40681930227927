import { IInvitePublisherForm } from '@/components/ui/InvitePublisher/InvitePublisherForm/validation';
import {
  TIniviteUserData,
  TUpdateUserStatus,
  IUser,
} from '@/types/users-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './query';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUsers: builder.query<{ data: Array<IUser> }, void>({
      query: () => ({
        url: 'users',
        method: 'GET',
      }),
    }),
    getPubUsers: builder.query<Array<IUser>, Partial<{ publisherId: string }>>({
      query: ({ publisherId }) => ({
        url: `admin/publishers/${publisherId}/users`,
        method: 'GET',
      }),
    }),
    inviteUser: builder.mutation<
      { data: IUser },
      Partial<{ data: TIniviteUserData }>
    >({
      query: ({ data }) => ({
        url: `users/invite`,
        method: 'POST',
        body: data,
      }),
    }),
    invitePublisher: builder.mutation<{ data: IUser }, IInvitePublisherForm>({
      query: (data) => ({
        url: `admin/invite`,
        method: 'POST',
        body: data,
      }),
    }),
    updateStatus: builder.mutation<
      IUser,
      Partial<{ userId: string; data: TUpdateUserStatus }>
    >({
      query: ({ userId, data }) => ({
        url: `users/${userId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteUser: builder.mutation<{ id: string }, Partial<{ userId: string }>>({
      query: ({ userId }) => ({
        url: `users/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  // *GET ALL USERS QUERIES
  useLazyGetUsersQuery,
  useLazyGetPubUsersQuery,

  // *INVITE MUTATIONS
  useInviteUserMutation,
  useInvitePublisherMutation,

  useUpdateStatusMutation,
  useDeleteUserMutation,
} = usersApi;
