import React, { memo, useMemo } from 'react';
import { Step, Wrapper } from './ProgressTabs.styled';

type TChoosePlayerStepProps = {
  step: number;
};

export const ProgressTabs: React.FC<TChoosePlayerStepProps> = memo(
  ({ step }) => {
    const steps = useMemo(() => {
      return [
        {
          key: 'First step',
          active: step >= 1,
        },
        {
          key: 'Second step',
          active: step >= 2,
        },
        {
          key: 'Third step',
          active: step === 3,
        },
      ];
    }, [step]);

    return (
      <Wrapper step={step}>
        {steps.map((step) => (
          <Step key={step.key} active={step.active} />
        ))}
      </Wrapper>
    );
  },
);

ProgressTabs.displayName = 'ProgressTabs';
