import { Colors } from '@/environment';
import styled, { css } from 'styled-components';

export const ChooseOptionsStepWrapper = styled.div.attrs(
  (props: { isEdit: boolean }) => ({
    ...props,
  }),
)`
  padding: 20px 0px 20px 20px;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  .copy-tooltip {
    top: -12px !important;
  }

  .ant-tooltip-arrow {
    bottom: 2px !important;

    &::before {
      background: ${Colors.blue};
    }
  }

  .ant-tooltip-inner {
    padding: 2px 8px 0px 8px;
    display: flex;
    align-items: center;
    width: 57px;
    min-height: 23px;
    max-height: 23px;
    box-sizing: border-box;
    background: ${Colors.blue};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    box-shadow: none;
    border-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.scrollbar};
    opacity: 0.2;
    border-radius: 24px;
    margin-left: 10px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.scrollbar};
  }

  @media (max-height: 900px) {
    overflow-y: scroll;
  }
`;

export const VendorSiteDemoImg = styled.img.attrs(
  (props: { isReady: boolean }) => ({
    ...props,
  }),
)`
  width: 100%;
  height: auto;
  max-height: 360px;
  box-shadow: 0px 4px 24px 10px rgba(39, 67, 116, 0.15);
  border-radius: 8px;
  opacity: 0;
  opacity: ${({ isReady }) => isReady && 1};
`;

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const PreviewContainer = styled.div.attrs(
  (props: { isShowCode: boolean; isVisible: boolean }) => ({
    ...props,
  }),
)`
  position: relative;
  width: 100%;
  height: ${({ isShowCode }) => (isShowCode ? 0 : 'calc(100% - 70px)')};
  max-height: ${({ isShowCode }) => (isShowCode ? 0 : 'calc(100% - 70px)')};
  overflow: ${({ isVisible }) => (!isVisible ? 'hidden' : 'visible')};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const ScriptsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
`;

export const LoaderWrap = styled.div.attrs((props: { isReady: boolean }) => ({
  ...props,
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 360px;
  background: ${Colors.white};

  opacity: 3;
  opacity: ${({ isReady }) => isReady && 0};
  ${({ isReady }) =>
    isReady
      ? css`
          display: none;
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
`;

export const DocumentationTabs = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentationTab = styled.div.attrs(
  (props: { isActive: boolean }) => ({
    ...props,
  }),
)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${Colors.blue}` : '2px solid transparent'};
  color: ${({ isActive }) => isActive && Colors.blue};
`;
