import { IconType } from '@/consts';
import {
  ContainerBlock,
  CopyIcon,
  Description,
  InstructionsText,
  ScriptBlock,
} from './DocumentationByUsage.styled';
import { Tooltip } from 'antd';
import { Icon } from '@/components/common';

interface IDocumentationByUsageProps {
  scriptText: string;
  isCopied: boolean;
  copyText: () => void;
  playerId?: string;
  containerText: string;
  withBlock?: boolean;
  additionalInfo?: string;
  isCms?: boolean;
  clickCopyContainer?: () => void;
  isContainerCopied?: boolean;
  playerType?: string;
}

// UNCOMENT CODE IN CASE IF WILL BE NEED DOCS FOR REACT, VUE etc.
export const DocumentationByUsage = ({
  scriptText,
  isCopied,
  playerId,
  // containerText,
  copyText,
  withBlock,
  additionalInfo,
  // isCms,
  clickCopyContainer,
  isContainerCopied,
  playerType,
}: IDocumentationByUsageProps) => {
  return (
    <>
     
        {playerType === 'static' ? (
          <InstructionsText>
            <span>
              The TYLR player should only be inserted on article or content
              pages. It will not function well on home pages, category pages, or
              any pages that do not contain the full article, text or content.
            </span>

            <span>
              <span className="bold">For the Static player</span>, you will need
              to insert the TYLR code within the specific area of the page or
              page template’s HTML
              {'<body>'} where you would like the TYLR player to appear.
            </span>
          </InstructionsText>
        ) : (
          <InstructionsText>
            <span>
              The TYLR player should only be inserted on article or content
              pages. It will not function well on home pages, category pages, or
              any pages that do not contain the full article, text or content.
            </span>
            <span>
              <span className="bold">For the Dynamic player</span>, you will
              need to insert the code <span className='italic'>anywhere</span> within the {'<body>'} of the HTML
              code.
            </span>
          </InstructionsText>
        )}
      <ScriptBlock>
        <span>{scriptText}</span>
        <Tooltip
          placement="top"
          title="Copied"
          trigger={['hover', 'click']}
          open={isCopied}
          overlayClassName={'copy-tooltip'}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        >
          <CopyIcon onClick={copyText} className={isCopied ? 'copied' : ''}>
            <Icon
              name={!isCopied ? IconType.TYCopyIcon : IconType.TYCopiedIcon}
            />
          </CopyIcon>
        </Tooltip>
      </ScriptBlock>
      {withBlock && (
        <>
          <ContainerBlock>
            {`<div id="ty-project-player-${playerId}"></div>`}
            <Tooltip
              placement="top"
              title="Copied"
              trigger={['hover', 'click']}
              open={isContainerCopied}
              overlayClassName={'copy-tooltip'}
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            >
              <CopyIcon
                onClick={clickCopyContainer}
                className={isContainerCopied ? 'copied' : ''}
              >
                <Icon
                  name={
                    !isContainerCopied
                      ? IconType.TYCopyIcon
                      : IconType.TYCopiedIcon
                  }
                />
              </CopyIcon>
            </Tooltip>
          </ContainerBlock>
        </>
      )}
      {additionalInfo && <Description>{additionalInfo}</Description>}
    </>
  );
};
