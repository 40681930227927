import { BaseLabel, Icon } from '@/components/common';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import { SelectProps, Space } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';
import { StyledBaseFormSelect, SelectWrapper } from './BaseFormSelect.styled';

const { Option } = StyledBaseFormSelect;

interface BaseFormSelectProps extends SelectProps {
  withPrefix?: boolean;
  backgroundColor?: string;
  label: string;
}

export const BaseFormSelect: React.FC<BaseFormSelectProps> = memo(
  ({ ...props }) => {
    const {
      options,
      withPrefix,
      label,
      className,
      backgroundColor = Colors.inputBlue,
      ...selectProps
    } = props;

    return (
      <div>
        <BaseLabel label={label} />
        <SelectWrapper>
          <StyledBaseFormSelect
            {...selectProps}
            suffixIcon={
              <Icon name={IconType.TYSelectIcon} color={Colors.text.main} />
            }
            style={{ width: '100%' }}
            background={backgroundColor}
            className={classNames('styled-selector', className)}
            popupClassName="styled-dropdown"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {options?.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                label={option.label}
              >
                <Space className={`${option.value}`}>
                  {withPrefix && option.prefix}
                  {option.label}
                </Space>
              </Option>
            ))}
          </StyledBaseFormSelect>
        </SelectWrapper>
      </div>
    );
  },
);

BaseFormSelect.displayName = 'BaseFormSelect';
