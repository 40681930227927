import { InfoItem } from '@/components/common';
import { IMainUser } from '@/pages/ManagePublishers';
import { MainUserInfoWrapper } from './MainUserInfo.styled';

export const MainUserInfo: React.FC<{ selectedUser: IMainUser }> = ({
  selectedUser,
}) => {
  const { name, phone, email, date, website } = selectedUser;

  return (
    <MainUserInfoWrapper>
      <InfoItem header="Contact name" data={name} />
      <InfoItem header="Contact phone number" data={phone} />
      <InfoItem header="Contact email" data={email} />
      <InfoItem header="Sign up date" data={date ? date : ''} />
      <InfoItem header="Company URL" data={website} />
    </MainUserInfoWrapper>
  );
};
