import { useUpdateOwnerPubMutation } from '@/redux/api/publishersApi';
import { updateManagePubsData } from '@/redux/slice/publishers/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux';
import { useMemo, useCallback } from 'react';

const usePubMonetization = (publisherId: string) => {
  const [updatePub, { isLoading }] = useUpdateOwnerPubMutation();
  const dispatch = useDispatch();
  const { publishers } = useSelector((store: RootState) => ({
    publishers: store.publishers.managePubsData.items,
  }));

  const findPub = useMemo(
    () => publishers.find((pub) => pub.id === publisherId),
    [publishers, publisherId],
  );

  const isPubMonetizationAllowed = useMemo(() => {
    if (publisherId && findPub) {
      return findPub.isMonetizationAllowed;
    }
    return false;
  }, [findPub, publisherId]);

  const publisherCommissionPercent = useMemo(() => {
    if (publisherId && findPub && findPub.publisherCommission) {
      return findPub.publisherCommission.percent;
    }
    return null;
  }, [findPub, publisherId]);

  const updatePubMonetization = useCallback(
    async (isMonetizationAllowed: boolean, percentage?: number) => {
      const res = await updatePub({
        data: {
          isMonetizationAllowed,
          ...(isMonetizationAllowed
            ? { commissionPercentage: percentage }
            : {}),
        },
        publisherId: publisherId,
      }).unwrap();
      dispatch(updateManagePubsData(res));
    },
    [updatePub, dispatch, publisherId],
  );

  return {
    updatePubMonetization,
    isLoading,
    isPubMonetizationAllowed,
    publisherCommissionPercent,
  };
};

export default usePubMonetization;
