import { TAuthResponse } from '@/types/auth-types';
import { Nullable } from '@/types/generic';
import { Popover } from 'antd';
import { ReactNode } from 'react';
import { PopoverContent } from './PopoverContent';

interface ILogoutMenuProps {
  user: Nullable<TAuthResponse>;
  children: ReactNode;
  asPub: string;
}

export const LogoutMenu: React.FC<ILogoutMenuProps> = (props) => {
  const { user, children, asPub } = props;

  return (
    <Popover
      content={<PopoverContent user={user} asPub={asPub} />}
      trigger="click"
      overlayInnerStyle={{
        padding: 0,
        borderRadius: 16,
        border: 'none',
        boxShadow: '0px 8px 24px 3px rgba(6, 71, 159, 0.05)',
      }}
      showArrow={false}
      align={{ offset: [-1, 70] }}
    >
      {children}
    </Popover>
  );
};
